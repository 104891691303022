import {
  withCompPreviewProps,
  createComponentPreviewMapperModel,
} from '@wix/editor-elements-integrations';
import { CompInfoDependency } from '@wix/editor-elements-types';
import {
  CarouselDefinition,
  CarouselPropsCarmiData,
  TPA3DCarouselPropsPlatformProps,
} from '../TPA3DCarousel.types';
import { props as viewerPropsMapper } from './TPA3DCarousel.mapper';

const viewerDeps = Object.keys(
  viewerPropsMapper.deps,
) as Array<CompInfoDependency>;

const props = withCompPreviewProps<
  TPA3DCarouselPropsPlatformProps,
  CarouselDefinition,
  CarouselPropsCarmiData
>()(['isPlayingAllowed', ...viewerDeps], (deps, carmiData) => {
  const viewerProps = viewerPropsMapper.resolver(deps, carmiData);
  return {
    ...viewerProps,
    isPlayingAllowed: deps.isPlayingAllowed,
  };
});

export default createComponentPreviewMapperModel({
  props: props as any,
});
