import * as React from 'react';
import { ISliderImperativeActions, ISliderProps } from '../Slider.types';
import { CONTINUOUS_STEP, TestIds, Sizes } from '../constants';
import { isBrowser } from '../../../core/commons/utils';
import { st, classes } from './Slider.st.css';
import Ticks from './Ticks/Ticks';

const Slider: React.ForwardRefRenderFunction<
  ISliderImperativeActions,
  ISliderProps
> = (props, ref) => {
  const {
    id,
    isDisabled,
    dir,
    width,
    height,
    min,
    max,
    step,
    value,
    thumbShape,
    tickMarksShape,
    tickMarksPosition,
    tooltipVisibility,
    tooltipPosition,
    tooltipPrefix,
    tooltipSuffix,
    stepType,
    readOnly,
    label,
    labelMargin,
    onChange,
    onMouseEnter,
    onMouseLeave,
    onFocus,
    onBlur,
    onClick,
    onDblClick,
  } = props;

  const root = React.useRef<HTMLDivElement>(null);
  const track = React.useRef<HTMLDivElement>(null);

  React.useImperativeHandle(ref, () => ({
    focus() {
      root.current?.focus();
    },
    blur() {
      root.current?.blur();
    },
  }));

  const [dragging, setDragging] = React.useState(false);
  const [mouseDown, setMouseDown] = React.useState(false);
  const [thumbHover, setThumbHover] = React.useState(false);
  const [inKeyPress, setInKeyPress] = React.useState(false);
  const [labelHeight, setLabelHeight] = React.useState(0);

  const labelRef: React.RefObject<HTMLLabelElement> = React.createRef();

  React.useEffect(() => {
    let newLabelHeight = 0;

    if (label && labelRef.current) {
      const labelTextHeight = labelRef.current.getBoundingClientRect().height;
      newLabelHeight = labelMargin + labelTextHeight;
    }
    setLabelHeight(newLabelHeight);
  }, [label, labelRef, labelMargin]);

  const thumbOffset = React.useMemo(() => {
    if (!step || tickMarksShape === 'none' || tickMarksPosition === 'middle') {
      return 0;
    }
    const tickHeight =
      tickMarksShape === 'line' ? Sizes.tickLineHeight : Sizes.tickDotSize;
    return tickHeight + Sizes.tickMarksGap;
  }, [step, tickMarksShape, tickMarksPosition]);

  const thumbSize = React.useMemo(() => {
    const shapeWidthMult = { circle: 1, square: 1, rectangle: 1.5, bar: 0.5 };

    const baseThumbHeight = Math.min(width, height);
    const baseThumbWidth = shapeWidthMult[thumbShape] * baseThumbHeight;

    return {
      thumbHeight: baseThumbHeight - thumbOffset - labelHeight,
      thumbWidth: baseThumbWidth - thumbOffset - labelHeight,
    };
  }, [thumbOffset, width, height, thumbShape, labelHeight]);

  const rootStyles = React.useMemo(
    () => ({
      '--thumbWidth': `${thumbSize.thumbWidth}px`,
      '--thumbHeight': `${thumbSize.thumbHeight}px`,
      ...(step &&
        tickMarksShape !== 'none' &&
        tickMarksPosition !== 'middle' && {
          ...(tickMarksPosition !== 'normal' && {
            '--innerTop': `${thumbOffset}px`,
          }),
          '--innerOffset': `${thumbOffset}px`,
        }),
    }),
    [thumbSize, tickMarksShape, step, thumbOffset, tickMarksPosition],
  );

  const stepValue = React.useMemo(() => {
    if (!step || step < 0) {
      return CONTINUOUS_STEP;
    }
    if (stepType === 'count') {
      return (max - min) / step;
    }
    return step;
  }, [min, max, step, stepType]);

  const totalSteps = React.useMemo(() => {
    return Math.ceil((max - min) / stepValue);
  }, [min, max, stepValue]);

  const clampedPercent = React.useMemo(() => {
    const pct = (value - min) / (max - min);
    return Math.min(Math.max(pct, 0), 1);
  }, [value, min, max]);

  const shouldShowTooltip = React.useMemo(() => {
    if (tooltipVisibility !== 'hover') {
      return tooltipVisibility === 'always';
    }
    return dragging || thumbHover || inKeyPress;
  }, [tooltipVisibility, dragging, thumbHover, inKeyPress]);

  const clampedValue = React.useMemo(() => {
    return Math.floor(10 * value) / 10;
  }, [value]);

  const maxTickDensity = 1 / (tickMarksShape === 'line' ? 8 : 16);

  const handleBlur = (event: React.FocusEvent) => {
    setInKeyPress(false);
    onBlur?.(event);
  };

  const handleThumbEnter = () => setThumbHover(true);

  const handleThumbLeave = () => setThumbHover(false);

  const handleChange = React.useCallback(
    (newValue: number) => {
      const changedValue =
        Math.floor(100 * Math.min(Math.max(newValue, min), max)) / 100;
      if (changedValue !== value) {
        onChange(changedValue);
      }
    },
    [max, min, onChange, value],
  );

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (isDisabled || readOnly) {
      return;
    }

    const changeByKey = (newValue: number) => {
      handleChange(newValue);
      setInKeyPress(true);
      event.preventDefault();
    };

    switch (event.key) {
      case 'ArrowUp':
        return changeByKey(value + stepValue);
      case 'ArrowDown':
        return changeByKey(value - stepValue);
      case 'ArrowLeft':
        return changeByKey(value + (dir === 'rtl' ? stepValue : -stepValue));
      case 'ArrowRight':
        return changeByKey(value + (dir === 'rtl' ? -stepValue : stepValue));
      case 'PageUp':
        return changeByKey(value + 0.1 * (max - min));
      case 'PageDown':
        return changeByKey(value - 0.1 * (max - min));
      case 'Home':
        return changeByKey(min);
      case 'End':
        return changeByKey(max);
      default:
    }
  };

  const moveThumbByMouse = (clientX: number) => {
    if (isDisabled || readOnly) {
      return;
    }
    const rect = track.current!.getBoundingClientRect();
    let sliderPos;

    if (dir === 'rtl') {
      sliderPos = rect.left + rect.width - thumbSize.thumbWidth / 2 - clientX;
    } else {
      sliderPos = clientX - (rect.left + thumbSize.thumbWidth / 2);
    }

    const pxStep = (rect.width - thumbSize.thumbWidth) / totalSteps;
    const newValue = min + stepValue * Math.round(sliderPos / pxStep);
    handleChange(newValue);
  };

  const handleMouseDown = () => {
    setMouseDown(true);
  };

  const handleMouseUp = () => {
    setMouseDown(false);
    setDragging(false);
  };

  const handleMouseMove = (event: MouseEvent) => {
    if (mouseDown && !dragging) {
      setDragging(true);
    }
    if (!dragging) {
      return;
    }
    moveThumbByMouse(event.clientX);
  };

  const handleTouchMove = (event: TouchEvent) => {
    if (mouseDown && !dragging) {
      setDragging(true);
    }
    if (!dragging) {
      return;
    }
    event.preventDefault();
    moveThumbByMouse(event.touches[0].clientX);
  };

  const onTickClick = React.useCallback(moveThumbByMouse, [
    dir,
    isDisabled,
    handleChange,
    min,
    readOnly,
    stepValue,
    thumbSize.thumbWidth,
    totalSteps,
  ]);

  React.useEffect(() => {
    if (!isBrowser()) {
      return;
    }

    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('touchend', handleMouseUp);
    window.addEventListener('touchmove', handleTouchMove, { passive: false });

    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('touchend', handleMouseUp);
      window.removeEventListener('touchmove', handleTouchMove);
    };
  });

  const labelOnClick: React.MouseEventHandler<HTMLLabelElement> = () => {
    root.current?.focus();
  };

  return (
    <div id={id} className={classes.rootWrapper}>
      {label && (
        <label
          htmlFor={`slider_${id}`}
          className={classes.label}
          ref={labelRef}
          onClick={labelOnClick}
        >
          {label}
        </label>
      )}
      <div
        id={`slider_${id}`}
        ref={root}
        className={st(classes.root, {
          disabled: isDisabled,
          dir,
        })}
        onClick={onClick}
        onDoubleClick={onDblClick}
        onFocus={onFocus}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        onMouseDown={handleMouseDown}
        onTouchStart={handleMouseDown}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        tabIndex={0}
        role="slider"
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={value}
        aria-label="range"
        style={rootStyles as React.CSSProperties}
        data-testid={TestIds.root}
      >
        <div className={classes.inner}>
          <div
            data-testid={TestIds.track}
            ref={track}
            className={classes.track}
            onClick={event => moveThumbByMouse(event.clientX)}
          >
            <div
              className={classes.trackFill}
              style={
                {
                  '--trackFillPercent': clampedPercent,
                } as React.CSSProperties
              }
            />
          </div>
          <div
            data-testid={TestIds.thumb}
            className={classes.thumb}
            onMouseEnter={handleThumbEnter}
            onMouseLeave={handleThumbLeave}
            style={
              {
                '--thumbPercent': clampedPercent,
              } as React.CSSProperties
            }
          >
            <div className={classes.thumbShape} />
            {shouldShowTooltip && (
              <div
                data-testid={TestIds.tooltip}
                className={st(classes.tooltip, { position: tooltipPosition })}
              >
                {tooltipPrefix}
                {clampedValue}
                {tooltipSuffix}
              </div>
            )}
          </div>
        </div>
        {tickMarksShape !== 'none' && !!step && (
          <Ticks
            step={stepValue}
            min={min}
            max={max}
            maxTickDensity={maxTickDensity}
            trackSize={width - thumbSize.thumbHeight}
            onTickClick={onTickClick}
          />
        )}
      </div>
    </div>
  );
};

export default React.forwardRef(Slider);
