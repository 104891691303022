import * as React from 'react';
import { VerticalLineProps } from '../VerticalLine';
import { IVerticalLineProps } from '../../../VerticalLine.types';
import VerticalLine from '../../VerticalLine';
import skinsStyle from './styles/VerticalFadeNotchRightLine.scss';
import { BaseVerticalLine } from './BaseVerticalLine';

const VerticalFadeNotchRightLineSkin: React.FC<VerticalLineProps> = props => (
  <BaseVerticalLine {...props} skinsStyle={skinsStyle} />
);

const VerticalFadeNotchRightLine: React.FC<Omit<IVerticalLineProps, 'skin'>> =
  props => {
    return <VerticalLine {...props} skin={VerticalFadeNotchRightLineSkin} />;
  };

export default VerticalFadeNotchRightLine;
