import React from 'react';
import { SkinFiveGridLineProps } from '../SkinFiveGridLine';
import { FiveGridLineWrapper } from '../../FiveGridLineWrapper';
import skinsStyle from './ArrowLine.scss';

const ArrowLine: React.FC<SkinFiveGridLineProps> = wrapperProps => {
  return (
    <FiveGridLineWrapper {...wrapperProps} className={skinsStyle.root}>
      <div className={skinsStyle.line} />
    </FiveGridLineWrapper>
  );
};

export default ArrowLine;
