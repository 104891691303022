import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import { PreviewWrapperProps } from '@wix/editor-elements-types';
import {
  AddressInputPlatformProps,
  AddressInputDefinition,
} from '../AddressInput.types';
import {
  props as viewerPropsMapper,
  css as viewerCssMapper,
} from './AddressInput.mapper';

const props = composeMapper<
  PreviewWrapperProps<AddressInputPlatformProps>,
  AddressInputDefinition
>()(
  viewerPropsMapper,
  { add: ['compPreviewState', 'shouldResetComponent', 'componentViewMode'] },
  (resolver, dependencies, carmiData) => {
    const viewerProps = resolver(dependencies, carmiData);
    const { shouldResetComponent, compPreviewState, componentViewMode } =
      dependencies;

    return {
      ...viewerProps,
      previewWrapperProps: {
        shouldResetComponent,
        componentViewMode,
        compPreviewState,
      },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
  css: viewerCssMapper,
});
