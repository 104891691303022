
  var rt = require("@stylable/runtime/cjs/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "TabsList2027980309",
      {"classes":{"root":"TabsList2027980309__root","tabsList":"TabsList2027980309__tabsList","tabsScrollBtn":"TabsList2027980309__tabsScrollBtn","tab":"TabsList2027980309__tab"},"vars":{},"stVars":{"activeIndicatorColor":"color-8","tabsListWrap":"var(--tabs-list-wrap, nowrap)","tabsListGap":"var(--tabs-list-items-gap, 0)","tabsListContainerGap":"var(--tabs-list-container-gap, 0)","tabsListAlignItems":"var(--tabs-list-items-alignment, flex-start)","tabsListAlignContent":"var(--tabs-list-content-alignment, flex-start)","tabsListHorizontalPadding":"var(--tabs-list-horizontal-padding, 20px)","tabsListVerticalPadding":"var(--tabs-list-vertical-padding, 20px)","tabsListWrapRowGap":"var(--tabs-list-wrap-row-gap, 0)","tabsListFlexGrow":"var(--tabs-list-item-flex-grow, 0)"},"keyframes":{}},
      "",
      -1,
      module.id
  );
  