import type {
  SendBIFunction,
  BiEventDefinition,
} from '@wix/editor-elements-types';

export const createBIEvent = (biEventDefinition: BiEventDefinition) =>
  biEventDefinition;

export const createBIEventFactory =
  (src: number) => (evid: number, fields?: BiEventDefinition['fields']) =>
    createBIEvent({ evid, src, fields });

// TODO: get type from here: https://github.com/wix-private/responsive-editor/blob/master/src/client/bi.ts#L155
type ResponsiveEditorBI = any;

type ResponsiveEditorWindow = typeof window & {
  responsiveEditorBI: ResponsiveEditorBI;
};

export type CreateComponentBIFunction = (
  biFunction?: SendBIFunction,
  params?: Record<string, string | undefined>,
) => SendBIFunction;

const hasResponsiveBI = (
  editorWindow: any,
): editorWindow is ResponsiveEditorWindow =>
  editorWindow.hasOwnProperty('responsiveEditorBI');

export const createComponentBI: CreateComponentBIFunction =
  (biLogger, extraParams = {}) =>
  (event, eventParams) => {
    const params = {
      ...extraParams,
      ...eventParams,
    };

    if (hasResponsiveBI(window)) {
      const logger = window.responsiveEditorBI.biLoggerFactory.logger();
      return logger.report({
        endpoint: 'editor-x',
        ...event,
        params: {
          ...params,
          componentType: extraParams.component_type,
        },
      });
    }

    return biLogger?.(event, params);
  };
