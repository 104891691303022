import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';

export const props = withCompInfo()(
  ['compStylableClass'],
  ({ compStylableClass }) => {
    return {
      label: 'Label from mapper',
      className: compStylableClass,
    };
  },
);

export default createComponentMapperModel({
  props,
});
