import * as React from 'react';
import {
  ISelectionTagsListProps,
  SelectionTagsListAlignment,
} from '../SelectionTagsList.types';
import SelectionTag from '../../SelectionTag/viewer/SelectionTag';
import { st, classes } from './style/SelectionTagsList.st.css';
import { TestIds } from './constants';

const getDataAttributes = (props: ISelectionTagsListProps) => {
  const keys = Object.keys(props) as Array<keyof ISelectionTagsListProps>;

  return keys.reduce(
    (acc, key) =>
      key.startsWith('data-') ? { ...acc, [key]: props[key] } : acc,
    {} as Record<string, unknown>,
  );
};

const Label = ({
  id,
  label,
  alignment,
  required,
}: {
  id: string;
  label: string;
  required: boolean;
  alignment: SelectionTagsListAlignment;
}) => {
  const Asterisk = () => (
    <span aria-hidden="true" className={classes.labelAsterisk}>
      {alignment === 'right' ? '* ' : ' *'}
    </span>
  );

  return (
    <div id={id} className={classes.label}>
      {required && alignment === 'right' && <Asterisk />}
      {label}
      {required && alignment !== 'right' && <Asterisk />}
    </div>
  );
};

export const SelectionTagsList: React.FC<ISelectionTagsListProps> = props => {
  const {
    id,
    onChange,
    legend,
    value: values,
    label: inputLabel,
    alignment,
    options,
    onMouseEnter,
    onMouseLeave,
    onClick,
    onDblClick,
    isDisabled,
    shouldShowValidityIndication,
    isValid,
    validateValueAndShowIndication,
    required,
  } = props;

  const styleState = {
    disabled: isDisabled,
    error: Boolean(shouldShowValidityIndication && !isValid),
  };

  const a11yProps = {
    ...(legend && { 'aria-label': legend }),
    ...(inputLabel && { 'aria-labelledby': `input_${id}` }),
  };

  const dataAttr = getDataAttributes(props);

  const handleChange = (ev: React.FormEvent<HTMLInputElement>) => {
    const { value: targetValue, checked } = ev.target as HTMLInputElement;

    const nextValue = checked
      ? [...values, targetValue]
      : values?.filter(value => value !== targetValue);

    onChange(nextValue);
    validateValueAndShowIndication?.({ value: nextValue });
  };

  return (
    <div
      id={id}
      {...dataAttr}
      className={st(classes.root, { disabled: isDisabled })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      onDoubleClick={onDblClick}
    >
      <div
        className={classes.tagList}
        data-testid={TestIds.tagsList}
        {...a11yProps}
        role="group"
      >
        {inputLabel && (
          <Label
            id={`input_${id}`}
            label={inputLabel}
            required={required}
            alignment={alignment}
          />
        )}
        {options?.map(({ value, label, link, rel }, index) => {
          const isChecked = values?.includes(value);
          const tagId = `${value || label}-${id}-${index}`;
          const tagClassName = st(classes.tag, {
            ...styleState,
            selected: isChecked,
          });

          return (
            <SelectionTag
              key={tagId}
              id={tagId}
              onChange={handleChange}
              label={label}
              value={value}
              checked={isChecked}
              className={tagClassName}
              disabled={isDisabled}
              link={link}
              rel={rel}
              required={required}
            >
              {label}
            </SelectionTag>
          );
        })}
      </div>
    </div>
  );
};

export default SelectionTagsList;
