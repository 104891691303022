import * as React from 'react';
import {
  IContainerProps,
  IContainerImperativeActions,
} from '../../../Container.types';
import { ContainerLogic } from '../../shared/ContainerLogic';
import styles from './SloopyArea.scss';

const SloopyArea: React.ForwardRefRenderFunction<
  IContainerImperativeActions,
  IContainerProps
> = (props, ref) => {
  return (
    <ContainerLogic
      {...props}
      ref={ref}
      className={styles.root}
      renderSlot={({ containerChildren }) => (
        <>
          <div className={styles.bg} />
          <div className={styles.bg2} />
          {containerChildren}
        </>
      )}
    />
  );
};

export default React.forwardRef(SloopyArea);
