import * as React from 'react';
import type { IMockLibCompProps } from '../MockLibComp.props';

const MockLibProp: React.FC<IMockLibCompProps> = props => {
  const { id, myProp } = props;

  return (
    <div id={id}>
      <span>{`hello ${myProp}`}</span>
    </div>
  );
};

export default MockLibProp;
