import * as React from 'react';
import classNames from 'classnames';
import Image from '../../../Image/viewer/Image';
import { MediaZoomProps } from '../TouchMediaZoom.types';
import Link from '../../../Link/viewer/Link';
import { TestIds } from '../constants';
import { useGesture } from '../../../../providers/useGesture/useGesture';

import styles from './styles/TouchMediaZoom.scss';

type TouchOrMouseEvent = React.MouseEvent | TouchEvent;

const TouchMediaZoomBase: React.FC<MediaZoomProps> = ({
  id,
  onClose,
  images,
  selectedImageIndex,
  onImageChangeCallback,
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const imageInfoBoxRef = React.useRef<HTMLDivElement>(null);
  const [isImageReady, setIsImageReady] = React.useState(false);

  const getNextItem = () =>
    selectedImageIndex + 1 < images.length ? selectedImageIndex + 1 : 0;
  const getPrevItem = () =>
    selectedImageIndex ? selectedImageIndex - 1 : images.length - 1;

  const onNextClickHandler = (event: TouchOrMouseEvent) => {
    onItemChangeHandler(getNextItem(), event);
  };
  const onPrevClickHandler = (event: TouchOrMouseEvent) => {
    onItemChangeHandler(getPrevItem(), event);
  };

  useGesture('onSwipeRight', onPrevClickHandler, containerRef);
  useGesture('onSwipeLeft', onNextClickHandler, containerRef);

  const onItemChangeHandler = (
    newSelectedImageIndex: number,
    event?: TouchOrMouseEvent,
  ) => {
    setIsImageReady(false);

    setTimeout(() => {
      const newDataItemId = images[newSelectedImageIndex].id;
      if (event) {
        event.stopPropagation?.();
        event.preventDefault?.();
      }
      onImageChangeCallback(newDataItemId);
    }, 250);
  };

  const disableOnClose: React.MouseEventHandler = event => {
    if (!(event.target instanceof HTMLAnchorElement)) {
      event.stopPropagation();
    }
  };

  const onCloseHandler = () => {
    onClose(false);
  };

  const containerHandlers =
    images.length === 1 ? { onClick: onCloseHandler } : {};

  const linkOnClickHandler =
    images.length > 1 && !images[selectedImageIndex]?.link?.linkPopupId
      ? onCloseHandler
      : undefined;

  const { link, title, description, width, height } =
    images[selectedImageIndex];

  const renderImageInfo = () => {
    const viewPortDimensions = containerRef.current?.getBoundingClientRect();
    const isLandScape =
      viewPortDimensions &&
      viewPortDimensions.width > viewPortDimensions.height;

    return (
      (title || description || link) &&
      isImageReady && (
        <div
          className={classNames(
            {
              [styles.hidden]: isLandScape,
            },
            styles.info,
          )}
          onClick={disableOnClose}
          ref={imageInfoBoxRef}
        >
          {title && (
            <p itemProp="name" className={styles.infoField}>
              {title}
            </p>
          )}
          {description && (
            <p itemProp="description" className={styles.infoField}>
              {description}
            </p>
          )}
          {link && (
            <Link
              {...link}
              dataTestId={TestIds.link}
              className={styles.link}
              onClick={linkOnClickHandler}
            >
              Go to link
            </Link>
          )}
        </div>
      )
    );
  };

  return (
    <div
      className={classNames(styles.root, styles.mobile)}
      {...containerHandlers}
      data-testid={TestIds.container}
      ref={containerRef}
      id={id}
    >
      <div
        className={classNames(styles.centeredDiv, {
          [styles.hidden]: !isImageReady,
        })}
        style={{ maxHeight: height, maxWidth: width }}
        data-testselectedimageindex={selectedImageIndex}
      >
        <Image
          {...images[selectedImageIndex]}
          onLoad={() => {
            setTimeout(() => setIsImageReady(true), 250);
          }}
          id={`img_${id}`}
          displayMode="fit"
        />
      </div>
      {renderImageInfo()}
      <div
        className={styles.xButton}
        data-testid={TestIds.close}
        onClick={images.length > 1 ? onCloseHandler : undefined}
      >
        <svg
          viewBox="0 0 180 180"
          className={styles.svgButtonClose}
          tabIndex={0}
          role="button"
          aria-label="close"
        >
          <path d="M5 5 L175 175 M175 5 L5 175"></path>
        </svg>
      </div>
      {images.length > 1 && (
        <>
          <div
            className={styles.nextButton}
            onClick={onNextClickHandler}
            data-testid={TestIds.next}
          >
            <svg
              viewBox="0 0 180 310"
              className={styles.svgNavButton}
              tabIndex={0}
              role="button"
              aria-label="next"
            >
              <path d="M10 10 L170 161 M170 150 L10 300"></path>
            </svg>
          </div>
          <div
            className={styles.prevButton}
            onClick={onPrevClickHandler}
            data-testid={TestIds.prev}
          >
            <svg
              viewBox="0 0 180 310"
              className={styles.svgNavButton}
              tabIndex={0}
              role="button"
              aria-label="previous"
            >
              <path d="M170 10 L10 161 M10 150 L170 300"></path>
            </svg>
          </div>
        </>
      )}
    </div>
  );
};

export default TouchMediaZoomBase;
