import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  LanguageSelectorDefinition,
  LanguageSelectorCSSVars,
} from '@wix/thunderbolt-components';
import { LanguageSelectorWithPreviewProps } from '../LanguageSelector.types';

const props = withCompInfo<
  LanguageSelectorWithPreviewProps,
  LanguageSelectorDefinition,
  LanguageSelectorWithPreviewProps
>()([], (_deps, carmiData) => carmiData);

const css = withCompInfo<
  LanguageSelectorCSSVars,
  LanguageSelectorDefinition,
  LanguageSelectorCSSVars
>()([], (_, carmiCss) => carmiCss);

export default createComponentMapperModel({ props, css });
