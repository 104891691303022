import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  DropDownMenuDefinition,
  DropDownMenuPlatformProps,
  DropDownMenuCarmiData,
} from '../DropDownMenu.types';

export const props = withCompInfo<
  DropDownMenuPlatformProps,
  DropDownMenuDefinition,
  DropDownMenuCarmiData
>()(
  ['isQaMode', 'fullNameCompType'],
  ({ isQaMode, fullNameCompType }, carmiData) => {
    return {
      ...carmiData,
      isQaMode,
      fullNameCompType,
    };
  },
);

export default createComponentMapperModel({ props });
