import * as React from 'react';
import {
  DEFAULT_TRANSLATIONS,
  TranslationKeys,
} from '../../../DatePicker/constants';
import { ICalendarErrorProps } from '../../DatePickerCalendar.types';
import { ReactComponent as InfoIcon } from '../assets/infoIcon.svg';
import style from '../styles/CalendarError.scss';

const CalendarError: React.FunctionComponent<ICalendarErrorProps> = ({
  calendarError,
  translate,
}) => {
  return (
    <div>
      {calendarError && (
        <div className={style.calendarError}>
          <InfoIcon className={style.calendarErrorIcon} />
          <div className={style.calendarErrorText}>
            {translate?.(
              TranslationKeys.namespace,
              TranslationKeys.datePicker_calendar_error,
              DEFAULT_TRANSLATIONS.datePicker_calendar_error,
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CalendarError;
