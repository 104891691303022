import { PaginationProps } from './Pagination.types';

export const isNextEnabled = ({
  forceNextButtonEnabled,
  isDisabled,
  currentPage,
  totalPages,
}: Pick<
  PaginationProps,
  'forceNextButtonEnabled' | 'isDisabled' | 'currentPage' | 'totalPages'
>) =>
  typeof forceNextButtonEnabled !== 'undefined'
    ? forceNextButtonEnabled
    : !(isDisabled || currentPage >= totalPages);

export const isPreviousEnabled = ({
  forcePreviousButtonEnabled,
  isDisabled,
  currentPage,
}: Pick<
  PaginationProps,
  'forcePreviousButtonEnabled' | 'isDisabled' | 'currentPage'
>) =>
  typeof forcePreviousButtonEnabled !== 'undefined'
    ? forcePreviousButtonEnabled
    : !(isDisabled || currentPage <= 1);
