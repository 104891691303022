import * as React from 'react';
import SiteButtonContent from '../../SiteButtonContent';
import { SkinButtonProps } from '../SkinButton.types';
import SiteButton from '../../SiteButton';
import {
  ISiteButtonImperativeActions,
  ISiteButtonProps,
} from '../../../SiteButton.types';
import skinsStyle from './styles/skins.scss';

const RibbonButtonSkin = React.forwardRef<
  ISiteButtonImperativeActions,
  SkinButtonProps
>(
  (
    { wrapperProps, linkProps, a11yProps, elementType, label, onFocus, onBlur },
    ref,
  ) => {
    return (
      <div {...wrapperProps} className={skinsStyle.RibbonButton}>
        <SiteButtonContent
          linkProps={linkProps}
          a11yProps={a11yProps}
          elementType={elementType}
          className={skinsStyle.link}
          onFocus={onFocus}
          onBlur={onBlur}
          ref={ref}
        >
          <span className={skinsStyle.label}>{label}</span>
        </SiteButtonContent>
        <div className={skinsStyle.tail}>
          <div className={skinsStyle.tri} />
          <div className={skinsStyle.tri} />
          <div className={skinsStyle.tri} />
          <div className={skinsStyle.tri} />
          <div className={skinsStyle.tri} />
          <div className={skinsStyle.tri} />
          <div className={skinsStyle.tri} />
          <div className={skinsStyle.tri} />
          <div className={skinsStyle.tri} />
          <div className={skinsStyle.tri} />
          <div className={skinsStyle.tri} />
          <div className={skinsStyle.tri} />
          <div className={skinsStyle.tri} />
          <div className={skinsStyle.tri} />
          <div className={skinsStyle.tri} />
          <div className={skinsStyle.tri} />
          <div className={skinsStyle.tri} />
          <div className={skinsStyle.tri} />
        </div>
      </div>
    );
  },
);

const RibbonButton: React.ForwardRefRenderFunction<
  ISiteButtonImperativeActions,
  Omit<ISiteButtonProps, 'skin'>
> = (props, ref) => <SiteButton {...props} skin={RibbonButtonSkin} ref={ref} />;

export default React.forwardRef(RibbonButton);
