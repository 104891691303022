import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import {
  FreemiumBannerMobileDefinition,
  FreemiumBannerMobilePlatformProps,
  FreemiumBannerMobileCarmiData,
} from '../FreemiumBannerMobile.types';
import { props as viewerPropsMapper } from './FreemiumBannerMobile.mapper';

export const props = composeMapper<
  FreemiumBannerMobilePlatformProps,
  FreemiumBannerMobileDefinition,
  FreemiumBannerMobileCarmiData
>()(viewerPropsMapper, {}, (resolver, deps, carmiData) => {
  const viewerProps = resolver(deps, carmiData);
  return {
    ...viewerProps,
    shouldReportBi: false,
  };
});

export default createComponentPreviewMapperModel({
  props,
});
