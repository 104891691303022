import {
  withCompPreviewProps,
  createComponentPreviewMapperModel,
} from '@wix/editor-elements-integrations';
import { VideoBoxDefinition } from '@wix/thunderbolt-components';
import { VideoBoxProps } from '../VideoBox.types';
import { imageOverlayPreviewMap } from './constants';

const getFillLayersPreviewProps = (
  carmiData: VideoBoxProps,
  {
    isPlayingAllowed,
    componentViewMode,
  }: { isPlayingAllowed: boolean; componentViewMode: string },
) => {
  let { fillLayers } = carmiData;
  const isEditorMode = componentViewMode === 'editor';

  if (fillLayers.video) {
    fillLayers = JSON.parse(JSON.stringify(fillLayers));
    fillLayers.video!.videoInfo.autoPlay =
      fillLayers.video!.videoInfo.autoPlay && isPlayingAllowed;

    if (
      isEditorMode &&
      fillLayers &&
      fillLayers.backgroundOverlay &&
      fillLayers.backgroundOverlay.imageOverlay
    ) {
      const overlayValue =
        imageOverlayPreviewMap[fillLayers.backgroundOverlay.imageOverlay.uri];

      if (overlayValue) {
        fillLayers.backgroundOverlay.imageOverlay.uri = overlayValue;
      }
    }

    return {
      ...carmiData,
      fillLayers,
      previewWrapperProps: {
        autoPlayVideo: fillLayers.video?.videoInfo.autoPlay,
        isPlayingAllowed,
        showAudio: isEditorMode,
        shouldShowTransparentVideoPosterOnStop: isEditorMode,
      },
    };
  }
  return carmiData;
};

const props = withCompPreviewProps<
  VideoBoxProps,
  VideoBoxDefinition,
  VideoBoxProps
>()(
  ['isPlayingAllowed', 'componentViewMode'],
  ({ isPlayingAllowed, componentViewMode }, carmiData: VideoBoxProps) => {
    return getFillLayersPreviewProps(carmiData, {
      isPlayingAllowed,
      componentViewMode,
    });
  },
);

export default createComponentPreviewMapperModel({ props });
