import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import {
  usePreviewEffect,
  usePreviewState,
  useResetComponent,
} from '@wix/editor-elements-preview-utils';
import {
  IComponentPreviewWrapper,
  PreviewWrapperProps,
} from '@wix/editor-elements-types';
import React from 'react';
import {
  ICheckboxGroupProps,
  CheckboxGroupPreviewWrapperProps,
  ICheckboxGroupImperativeActions,
} from '../CheckboxGroup.types';
import styles from '../../Checkbox/viewer/style/CheckboxBasicSkin.scss';

const noop = () => {};

function withComponentPreview(
  WrappedComponent: React.ComponentType<ICheckboxGroupProps>,
): IComponentPreviewWrapper<
  ICheckboxGroupProps,
  CheckboxGroupPreviewWrapperProps
> {
  return React.forwardRef<ICheckboxGroupImperativeActions, ICheckboxGroupProps>(
    (
      {
        previewWrapperProps = {},
        setValidityIndication = noop,
        validateValue = noop,
        ...viewerProps
      }: PreviewWrapperProps<
        ICheckboxGroupProps,
        CheckboxGroupPreviewWrapperProps
      >,
      ref,
    ) => {
      const { shouldResetComponent, componentViewMode, compPreviewState } =
        previewWrapperProps;

      usePreviewEffect({
        componentViewMode: componentViewMode || '',
        onPreviewViewMode: validateValue,
      });

      const key = useResetComponent({
        shouldResetComponent,
        id: viewerProps.id,
        onResetComponent: () => {
          setValidityIndication(false);
          validateValue();
        },
      });

      usePreviewState(viewerProps.id, compPreviewState, {
        focus: {
          selectors: `.${styles.input}`,
          type: 'plural',
        },
        error: {
          selectors: `.${styles.input}`,
          type: 'plural',
          state: 'invalid',
        },
        hover: {
          selectors: `.${styles.CheckboxBasicSkin}`,
          type: 'plural',
        },
        disabled: {
          selectors: `.${styles.CheckboxBasicSkin}`,
          type: 'plural',
        },
      });

      usePreviewState(viewerProps.id, compPreviewState);

      const shouldShowValidityIndication =
        compPreviewState === 'error' ||
        viewerProps.shouldShowValidityIndication;

      return (
        <WrappedComponent
          {...viewerProps}
          key={key}
          ref={ref}
          shouldShowValidityIndication={shouldShowValidityIndication}
        />
      );
    },
  );
}

export default (ViewerComponent: React.ComponentType<ICheckboxGroupProps>) =>
  createComponentPreviewEntry(withComponentPreview(ViewerComponent));
