import * as React from 'react';
import WPhoto from '../../WPhotoBase';
import { SkinWPhotoProps } from '../SkinWPhoto';
import { BaseWPhotoSkinProps } from '../../../WPhoto.types';
import skinsStyles from './styles/LiftedShadowPhoto.scss';
import BasicWPhotoSkin from './BasicWPhotoSkin';

const LiftedShadowPhotoSkin: React.FC<Omit<BaseWPhotoSkinProps, 'skinsStyle'>> =
  props => <BasicWPhotoSkin {...props} skinsStyle={skinsStyles} />;

const LiftedShadowPhoto: React.FC<SkinWPhotoProps> = props => (
  <WPhoto {...props} skin={LiftedShadowPhotoSkin} />
);

export default LiftedShadowPhoto;
