import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import { PreviewWrapperProps } from '@wix/editor-elements-types';
import {
  VerticalAnchorsMenuDefinition,
  VerticalAnchorsMenuProps as VerticalAnchorsMenuPlatformProps,
  VerticalAnchorsMenuPropsCarmiData,
} from '@wix/thunderbolt-components-native';
import { props as viewerMapperProps } from './VerticalAnchorsMenu.mapper';

type VerticalAnchorsMenuPreviewProps = Omit<
  VerticalAnchorsMenuPlatformProps,
  'anchors'
>;
const props = composeMapper<
  PreviewWrapperProps<VerticalAnchorsMenuPreviewProps>,
  VerticalAnchorsMenuDefinition,
  VerticalAnchorsMenuPropsCarmiData
>()(
  viewerMapperProps,
  { add: ['compPreviewState'] },
  (resolver, dependencies, carmiData) => {
    const { compPreviewState } = dependencies;
    const viewerProps = resolver(dependencies, carmiData);

    return {
      ...viewerProps,
      previewWrapperProps: { compPreviewState },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
