export enum ProGalleryStyleableElements {
  Root = '.root',
  Item = '.root::item',
  LoadMore = '.root::loadmore',
  Container = '.root::container',
  Info = '.root::info',
  X = '.root::x',
  Fullscreen = '.root::fullscreen',
  FullscreenContainer = '.root::fullscreen-container',
  FullscreenTitle = '.root::fullscreen-title',
  FullscreenDescription = '.root::fullscreen-description',
  FullscreenIcons = '.root::fullscreen-icons',
  FullscreenArrows = '.root::fullscreen-arrows',
  FullscreenArrowsContainer = '.root::fullscreen-arrows-container',
  FullscreenIconsContainer = '.root::fullscreen-icons-container',
}
