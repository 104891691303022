import React from 'react';
import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import {
  IComponentPreviewWrapper,
  PreviewWrapperProps,
} from '@wix/editor-elements-types';
import {
  usePreviewState,
  usePrevious,
  useResetComponent,
} from '@wix/editor-elements-preview-utils';
import {
  ISlideShowContainerImperativeActions,
  SlideShowContainerPreviewWrapperProps,
  SlideShowContainerProps,
} from '../SlideShowContainer.types';

function withComponentPreview(
  WrappedComponent: React.ComponentType<SlideShowContainerProps>,
): IComponentPreviewWrapper<
  SlideShowContainerProps,
  SlideShowContainerPreviewWrapperProps
> {
  return React.forwardRef<
    ISlideShowContainerImperativeActions,
    SlideShowContainerProps
  >(
    (
      {
        previewWrapperProps = {},
        ...viewerProps
      }: PreviewWrapperProps<
        SlideShowContainerProps,
        SlideShowContainerPreviewWrapperProps
      >,
      ref,
    ) => {
      const { componentViewMode, compPreviewState } = previewWrapperProps;

      const previousViewMode = usePrevious(componentViewMode);
      const key = useResetComponent({
        shouldResetComponent: previousViewMode !== componentViewMode,
        id: viewerProps.id,
      });
      usePreviewState(viewerProps.id, compPreviewState);

      return (
        <WrappedComponent
          {...viewerProps}
          key={key}
          ref={ref}
          isPlaying={
            componentViewMode === 'editor' ? false : viewerProps.isPlaying
          }
        />
      );
    },
  );
}

export default (
  ViewerComponent: React.ComponentType<SlideShowContainerProps>,
) => createComponentPreviewEntry(withComponentPreview(ViewerComponent));
