import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import { usePreviewState } from '@wix/editor-elements-preview-utils';
import React, { useState, useEffect } from 'react';
import { PreviewWrapperProps } from '@wix/editor-elements-types';
import {
  SlideShowGallerySkinProps,
  ISlideShowGalleryPreviewWrapperProps,
  ISlideShowGalleryImperativeActions,
} from '../SlideShowGallery.types';
import SlideShowIcon from './preview/SlideShowIcon';

const FORCE_SHOW_BUTTON_STATE = 'forceShowButtons';

export function withComponentPreview(
  WrappedComponent: React.ComponentType<SlideShowGallerySkinProps>,
) {
  return React.forwardRef<
    ISlideShowGalleryImperativeActions,
    SlideShowGallerySkinProps
  >(
    (
      {
        previewWrapperProps = {},
        setCurrentIndex: _setCurrentIndex,
        currentIndex: currentIndexProp,
        ...viewerProps
      }: PreviewWrapperProps<
        SlideShowGallerySkinProps,
        ISlideShowGalleryPreviewWrapperProps
      >,
      ref,
    ) => {
      const {
        showSlideShowIcon,
        showButtons,
        shouldResetGalleryToOriginalState,
      } = previewWrapperProps;

      usePreviewState(
        viewerProps.id,
        showButtons ? FORCE_SHOW_BUTTON_STATE : null,
      );

      // Maintain internal state until a reset is required
      const [currentIndex, setCurrentIndex] = useState(currentIndexProp);
      const [shouldReset, setShouldReset] = useState(
        shouldResetGalleryToOriginalState,
      );

      const handleIndexChange: SlideShowGallerySkinProps['setCurrentIndex'] =
        index => {
          setCurrentIndex(index);
          _setCurrentIndex(index);
        };

      useEffect(() => {
        // When returing from preview to editor -> reset current index
        if (shouldReset !== shouldResetGalleryToOriginalState) {
          if (shouldReset && viewerProps.imageMode === 'flexibleHeight') {
            setCurrentIndex(currentIndexProp);
          }
          setShouldReset(shouldResetGalleryToOriginalState);
        }
      }, [
        currentIndexProp,
        viewerProps.imageMode,
        setCurrentIndex,
        shouldResetGalleryToOriginalState,
        shouldReset,
        setShouldReset,
      ]);

      return (
        <>
          <WrappedComponent
            {...viewerProps}
            ref={ref}
            setCurrentIndex={handleIndexChange}
            currentIndex={currentIndex}
          />
          {showSlideShowIcon && <SlideShowIcon targetId={viewerProps.id} />}
        </>
      );
    },
  );
}

export default (
  ViewerComponent: React.ComponentType<SlideShowGallerySkinProps>,
) => createComponentPreviewEntry(withComponentPreview(ViewerComponent));
