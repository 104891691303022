import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  QuickActionBarPlatformProps,
  QuickActionBarDefinition,
  QuickActionBarCSSVars,
} from '../QuickActionBar.types';

export const props = withCompInfo<
  QuickActionBarPlatformProps,
  QuickActionBarDefinition,
  QuickActionBarPlatformProps
>()([], (_, carmiData) => {
  return carmiData;
});

export const css = withCompInfo<
  QuickActionBarCSSVars,
  QuickActionBarDefinition,
  QuickActionBarCSSVars
>()([], (_, carmiCSSVars) => {
  return carmiCSSVars;
});

export default createComponentMapperModel({
  props,
  css,
});
