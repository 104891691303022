import {
  createComponentMapperModel,
  withCompInfo,
} from '@wix/editor-elements-integrations';
import {
  DisqusCommentsDefinition,
  DisqusCommentsProps,
} from '../DisqusComments.types';

export const props = withCompInfo<
  DisqusCommentsProps,
  DisqusCommentsDefinition
>()(['compData'], ({ compData }) => {
  return { disqusId: compData.disqusId };
});

export default createComponentMapperModel({ props });
