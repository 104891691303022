import {
  createComponentMapperModel,
  withCompInfo,
} from '@wix/editor-elements-integrations';
import {
  CustomElementComponentDefinition,
  CustomElementComponentProps,
  CustomElementComponentCSSVars,
} from '../CustomElementComponent.types';

export const props = withCompInfo<
  CustomElementComponentProps,
  CustomElementComponentDefinition
>()(['compData'], ({ compData: { tagName, initialAttributes } }) => {
  return { tagName, initialAttributes };
});

export const css = withCompInfo<
  Partial<CustomElementComponentCSSVars>,
  CustomElementComponentDefinition
>()(
  ['compLayout', 'hasResponsiveLayout'],
  ({ compLayout: { width, height }, hasResponsiveLayout }) => {
    return hasResponsiveLayout
      ? {
          display: 'var(--display)',
          '--display': 'flex',
          '--customElementWidth': `100%`,
          '--customElementHeight': `100%`,
        }
      : {
          width: 'auto',
          height: 'auto',
          '--customElementWidth': `${width}px`,
          '--customElementHeight': `${height}px`,
        };
  },
);

export default createComponentMapperModel({ props, css });
