import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { ImageXDefinition, ImageXProps } from '@wix/thunderbolt-components';

export const props = withCompInfo<ImageXProps, ImageXDefinition, ImageXProps>()(
  [],
  (_, carmiProps) => {
    const { link: _link } = carmiProps;
    const link = _link && Object.keys(_link).length ? _link : undefined;
    return { ...carmiProps, link };
  },
);

export default createComponentMapperModel({
  props,
});
