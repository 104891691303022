import React from 'react';
import {
  IPreviewStateMap,
  clearPreviewStates,
  updatePreviewState,
} from '../usePreviewState';
import { usePrevious } from '../usePrevious';

/*
 * usePreviewState for modal case (element might not exist)
 */
export function useModalPreviewState(
  modal: HTMLElement | null,
  compPreviewState: string | null | undefined,
  stateMap?: IPreviewStateMap,
) {
  const previewState = usePrevious(compPreviewState);

  React.useLayoutEffect(() => {
    if (modal) {
      if (previewState !== compPreviewState) {
        clearPreviewStates(modal, stateMap?.[previewState || '']);
      }

      updatePreviewState(
        compPreviewState,
        modal,
        stateMap?.[compPreviewState || ''],
      );
    }
  }, [compPreviewState, stateMap, previewState, modal]);
}
