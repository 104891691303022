import scopedObjBuilder from '../../scopedObjBuilder';
import {
  ICollapsibleTextCompProps,
  ICollapsibleTextData,
} from './CollapsibleText.types';

export const HelpIds = {
  layoutPanel: 'cd1964dd-5a38-4414-9ab9-53ff6b3daed0',
  editText: '90bb2075-7174-4404-8d80-288c74ee3994',
  settingsPanel: 'e7b6d5d9-6dd6-4e24-b63a-27d30e9efd92',
  gfpp: 'e4059978-3a0a-43f8-ac9c-8b304c536a09',
  mobileGfpp: 'b6d9a270-f9c5-4837-995b-c3818b13a643',
  dataBinding: '313ea5f6-d081-4722-9ef9-62a4423785c1',
  responsive: '6d05761f-10bd-4909-a18f-9967871933db',
};

const collapsibleTextScopedObj = scopedObjBuilder('ellipsis_text');
const stylableScopedObj = scopedObjBuilder('StylablePanel');
const dataBindingScopedObj = scopedObjBuilder('Connect_Panel');

export const TestIds = {
  ...collapsibleTextScopedObj('layout', [
    'root',
    'lines_display_thumbnails',
    'lines_show_ellipsis_switch',
    'max_lines_label',
    'max_lines_input',
    'button_spacing_slider',
    'expand_mode_thumbnails',
    'align_thumbnails',
  ]),
  ...collapsibleTextScopedObj('settings', [
    'edit_text_input',
    'overflow_radio_buttons',
    'link_content_radio_button',
    'expand_radio_button',
    'link_placeholder_text',
    'read_more_button_toggle',
    'button_placeholder_text',
    'collapsed_text_label',
    'expanded_text_label',
    'link_read_more_label',
  ]),
  ...collapsibleTextScopedObj('viewer', [
    'root',
    'text_as_link',
    'expand_control',
  ]),
} as const;

export const TranslationKeys = {
  ...collapsibleTextScopedObj('settings', [
    'title',
    'edit_text_label',
    'section_label',
    'overflow_label',
    'overflow_tooltip',
    'link_content_radio_button',
    'expand_radio_button',
    'link_label',
    'link_placeholder_text',
    'read_more_button_toggle',
    'button_label',
    'button_placeholder_text',
    'default_text',
    'collapsed_text_label',
    'collapsed_text_placeholder',
    'expand_text_placeholder',
    'expand_text_label_expanded',
    'disabled_notification_link',
    'inputfield_empty_error',
  ]),
  ...collapsibleTextScopedObj('layout', [
    'title',
    'display_label',
    'display_multiple_rows',
    'display_single_row',
    'display_max_rows',
    'show_ellipsis_toggle',
    'spacing_slider',
    'show_more_text_title',
    'thumbnail_expand_label',
    'thumbnail_click_label',
    'thumbnail_show_all_label',
    'show_button_toggle',
    'align_button_label',
    'single_line_text_toggle',
  ]),
  ...collapsibleTextScopedObj('db', [
    'comp_name',
    'manage_content',
    'edit_text_label',
  ]),
  ...stylableScopedObj('Design', [
    'Dropdown_Ellipsis_Text_text_label',
    'Dropdown_Ellipsis_Text_button_label',
    'Header_Ellipsis_Text',
  ]),
  ...dataBindingScopedObj('Title', ['CollapsibleText']),
  ...dataBindingScopedObj('Connect', ['CollapsibleText_Label']),
} as const;

export const defaultTestCompProps: ICollapsibleTextCompProps = {
  text: 'Test',
  readMoreText: 'Read More Text',
  readLessText: 'Read Less Text',
  linkText: 'Read More',
  showExpandControl: false,
  isExpanded: false,
  ellipsis: true,
  maxLines: 3,
  wrappingStyle: 'multi-line',
  expandMode: 'link',
  align: 'left',
  alignButton: 'left',
  id: 'mock-collapsible-text-id',
  expandControlSpacing: 20,
  direction: 'ltr',
  type: 'mock-type',
  tag: 'p',
  setIsExpanded: () => {},
  lastUserDefinedWidth: 300,
};

export const defaultCompData: ICollapsibleTextData = {
  id: '',
  type: 'CollapsibleText',
  text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tempor iaculis enim, venenatis imperdiet justo sollicitudin eget. Aliquam molestie ligula vitae maximus euismod. Quisque et erat et neque eleifend posuere eget et dolor. Sed dolor leo, varius in cursus eu, vestibulum vitae felis. Morbi pellentesque ex sed mi luctus vestibulum.',
  readMoreText: 'Read more',
  readLessText: 'Read less',
  linkText: 'Read more',
  tag: 'p',
};

export const defaultTestPanelProps = {
  compData: defaultCompData,
  compProperties: defaultTestCompProps,
};

export const ActionIds = {
  ...collapsibleTextScopedObj('dataBinding', ['gfpp_edit_text']),
};
// TODO: Replace with a translation key
export const actionTypeError = `The requested operation is invalid. Try changing the "readMoreActionType" property first.`;

export const ViewerType = 'wixui.CollapsibleText' as const;
export const responsiveAddPanelAutomationId =
  'CollapsibleText-add-panel-editor-x';

export const collapsibleTextExperiment =
  'specs.responsive-editor.CollapsibleText';
