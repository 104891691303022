import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  WFacebookCommentPlatformProps,
  WFacebookCommentDefinition,
} from '../WFacebookComment.types';

export const props = withCompInfo<
  WFacebookCommentPlatformProps,
  WFacebookCommentDefinition,
  WFacebookCommentPlatformProps
>()([], (_, carmiData) => {
  return carmiData;
});

export default createComponentMapperModel({ props });
