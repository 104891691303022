import * as React from 'react';

import MultiStateBox from '@wix/thunderbolt-elements/dist/components/MultiStateBox/viewer/MultiStateBox';
import { ITabsProps } from '../Tabs.types';
import TabsList from './TabsList/TabsList';
import { st, classes } from './style/Tabs.st.css';

const Tabs: React.FC<ITabsProps> = props => {
  const {
    id,
    defaultTabId,
    currentTabId,
    tabItems,
    itemsDirection,
    className,
    children,
    onTabClick,
    menuMode,
  } = props;

  return (
    <div className={st(classes.root, className)} id={id}>
      <TabsList
        currentTabId={currentTabId}
        tabItems={tabItems}
        itemsDirection={itemsDirection}
        onTabClick={onTabClick}
        menuMode={menuMode}
      />
      <MultiStateBox
        id={`msb-${id}`}
        defaultStateId={defaultTabId}
        selectedStateId={currentTabId}
        children={children}
        containerRootClassName={classes.multiStateBoxWrapper}
      />
    </div>
  );
};

export default Tabs;
