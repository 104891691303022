import React from 'react';
import { IHeaderContainerProps } from '../../../HeaderContainer.types';
import LineBottomScreen from '../../../../ScreenWidthContainer/viewer/skinComps/BaseScreen/LineBottomScreen';
import HeaderContainer from '../../HeaderContainer';

const LineBottomScreenHeader: React.FC<Omit<IHeaderContainerProps, 'skin'>> =
  props => (
    <HeaderContainer {...props} skin={LineBottomScreen}></HeaderContainer>
  );

export default LineBottomScreenHeader;
