import { IComponentController } from '@wix/editor-elements-types';
import { IBreadcrumbsControllerActions } from '../Breadcrumbs.types';

const mapActionsToProps: IComponentController = ({
  updateProps,
}): IBreadcrumbsControllerActions => ({
  onEllipsisVisibilityChange: isEllipsisVisible => {
    updateProps({
      shouldWrap: !isEllipsisVisible,
    });
  },
});

export default mapActionsToProps;
