import { IComponentController } from '@wix/editor-elements-types';
import { CheckboxGroupOption } from '../CheckboxGroup.types';

const mapActionsToProps: IComponentController = ({ updateProps }) => ({
  onOptionsChange: (options: Array<CheckboxGroupOption>) => {
    updateProps({
      options,
    });
  },
  setValidityIndication: (isValid: boolean) => {
    updateProps({
      shouldShowValidityIndication: isValid,
    });
  },
});

export default mapActionsToProps;
