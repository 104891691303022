import React from 'react';
import { usePrevious } from '@wix/editor-elements-preview-utils';
import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import type { PreviewWrapperProps } from '@wix/editor-elements-types';
import type {
  IDropDownMenuImperativeActions,
  IDropDownMenuProps,
} from '../DropDownMenu.types';

function withComponentPreview(
  ViewerComponent: React.ComponentType<IDropDownMenuProps>,
) {
  return React.forwardRef<
    IDropDownMenuImperativeActions,
    PreviewWrapperProps<IDropDownMenuProps>
  >(({ previewWrapperProps, ...viewerProps }, ref) => {
    const { compPreviewState } = previewWrapperProps || {};
    const { id, items } = viewerProps;
    const previousItems = usePrevious(items);
    const shouldResetComponent = previousItems !== items;
    const keyIterator = React.useRef(0);
    if (shouldResetComponent) {
      keyIterator.current += 1;
    }
    const key = `${id}-${keyIterator.current}`;

    // Since the internal mechanism of DropDownMenu already attaches a `data-state` attribute,
    // we decided to rely on it instead of attaching another `data-preview` which eventually will increase both the output CSS file and DOM
    React.useLayoutEffect(() => {
      // Finds the rendered element on editor
      const element = document.getElementById(id);

      // Checks which preview state was changed through the edit panel
      const isHovered = compPreviewState?.includes('hover');
      const isActive = compPreviewState?.includes('active');

      // Finds each menu button to fix it depending on the preview state
      element?.querySelectorAll('[data-state]').forEach(menuButton => {
        const { dataset } = menuButton as HTMLElement;

        // Cleans up the previously attached preview states (if any)
        dataset.state = dataset.state?.replace(
          /( preview over)|( preview selected)$/,
          '',
        );

        if (isHovered && !dataset.state?.includes('selected')) {
          dataset.state += ' preview over';
        } else if (isActive) {
          dataset.state += ' preview selected';
        }
      });
    }, [compPreviewState, id]);

    return <ViewerComponent {...viewerProps} ref={ref} key={key} />;
  });
}

export default (ViewerComponent: React.ComponentType<IDropDownMenuProps>) =>
  createComponentPreviewEntry(withComponentPreview(ViewerComponent));
