import React from 'react';
import { ITinyMenuProps } from '../../../TinyMenu.types';
import TinyMenu from '../../TinyMenu';
import TinyMenuButton from '../../TinyMenuButton';
import TinyMenuContainer from '../../TinyMenuContainer';
import TinyMenuRoot from '../../TinyMenuRoot';
import { SkinTinyMenuProps } from '../SkinTinyMenu';
import TinyMenuSeoFriendlyList from '../../TinyMenuSeoFriendlyList';
import skinsStyle from './styles/skins.scss';

const OpenIcon = () => (
  <svg
    className={skinsStyle.icon}
    preserveAspectRatio="none"
    viewBox="0 0 17 17"
  >
    <line x2="17" y1="0.5" y2="0.5" />
    <line x2="17" y1="8.5" y2="8.5" />
    <line x2="17" y1="16.5" y2="16.5" />
  </svg>
);

const CloseIcon = () => (
  <svg className={skinsStyle.icon} viewBox="14.25 14.25 21.5 21.5">
    <line x1="35.75" y1="14.25" x2="14.25" y2="35.75" />
    <line x1="14.25" y1="14.25" x2="35.75" y2="35.75" />
  </svg>
);

const Skin: React.FC<SkinTinyMenuProps> = ({
  wrapperProps: {
    id,
    items,
    isOpen,
    setIsOpen,
    textAlignment,
    currentPrimaryPageHref,
    activeAnchor,
    ariaLabel,
    members,
    isHidden,
    onLogin,
    onLogout,
    isLoggedIn,
    userName,
    avatarUri,
    onMouseEnter,
    onMouseLeave,
    languages,
    onLanguageChange,
    onMenuClose,
    onMenuOpen,
  },
}) => (
  <TinyMenuRoot
    id={id}
    items={items}
    isOpen={isOpen}
    setIsOpen={setIsOpen}
    textAlignment={textAlignment}
    currentPrimaryPageHref={currentPrimaryPageHref}
    activeAnchor={activeAnchor}
    ariaLabel={ariaLabel}
    isHidden={isHidden}
    className={{
      root: skinsStyle.root,
      menuOpen: skinsStyle.menuOpen,
      menuClosed: skinsStyle.menuClosed,
      hidden: skinsStyle.hidden,
      left: skinsStyle.left,
      center: skinsStyle.center,
      right: skinsStyle.right,
    }}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    onMenuClose={onMenuClose}
    onMenuOpen={onMenuOpen}
  >
    {({ itemsWithSelection, containerRef, buttonRef }) => (
      <>
        <TinyMenuButton
          ref={buttonRef}
          className={skinsStyle.menuButton}
          isExpanded={!!isOpen}
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? <CloseIcon /> : <OpenIcon />}
        </TinyMenuButton>
        {isOpen && (
          <TinyMenuContainer
            id={id}
            ref={containerRef}
            items={itemsWithSelection}
            textAlignment={textAlignment}
            members={members}
            hideSubmenuHeader={true}
            skinsStyle={skinsStyle}
            separateIconsSection={false}
            onItemClick={() => setIsOpen(!isOpen)}
            onLogin={onLogin}
            onLogout={onLogout}
            isLoggedIn={isLoggedIn}
            userName={userName}
            avatarUri={avatarUri}
            currentHref={currentPrimaryPageHref}
            currentAnchor={activeAnchor?.dataId}
            languages={languages}
            onLanguageChange={onLanguageChange}
          />
        )}
        <TinyMenuSeoFriendlyList items={itemsWithSelection} />
      </>
    )}
  </TinyMenuRoot>
);

const TinyMenuSkin: React.FC<Omit<ITinyMenuProps, 'skin'>> = props => (
  <TinyMenu {...props} skin={Skin} />
);

export default TinyMenuSkin;
