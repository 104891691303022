import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { ISingleTabDefinition } from '../documentManagement/SingleTab.definition';
import { ISingleTabMapperProps } from '../SingleTab.types';

export const props = withCompInfo<
  ISingleTabMapperProps,
  ISingleTabDefinition
>()(['compData', 'compMeshProps'], ({ compData, compMeshProps }) => {
  return {
    ...compData,
    meshProps: compMeshProps,
  };
});

const css = withCompInfo()(['compLayout'], ({ compLayout }) => ({
  width: '100%',
  height: compLayout.height,
}));

export default createComponentMapperModel({
  props,
  css,
});
