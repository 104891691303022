
  var rt = require("@stylable/runtime/cjs/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "ProGallery669818973",
      {"classes":{"root":"ProGallery669818973__root","container":"ProGallery669818973__container","pro-gallery-container":"ProGallery669818973__pro-gallery-container","item":"ProGallery669818973__item","info":"ProGallery669818973__info","item-external-info-title":"ProGallery669818973__item-external-info-title","item-external-info-description":"ProGallery669818973__item-external-info-description","item-hover-info-title":"ProGallery669818973__item-hover-info-title","item-hover-info-description":"ProGallery669818973__item-hover-info-description","fullscreen":"ProGallery669818973__fullscreen","loadmore":"ProGallery669818973__loadmore","x":"ProGallery669818973__x","fullscreen-container":"ProGallery669818973__fullscreen-container","fullscreen-title":"ProGallery669818973__fullscreen-title","fullscreen-description":"ProGallery669818973__fullscreen-description","fullscreen-icons":"ProGallery669818973__fullscreen-icons","fullscreen-icons-container":"ProGallery669818973__fullscreen-icons-container","fullscreen-arrows":"ProGallery669818973__fullscreen-arrows","fullscreen-arrows-container":"ProGallery669818973__fullscreen-arrows-container"},"vars":{"overFreemiumBanner":"--ProGallery669818973-overFreemiumBanner","overFullscreen":"--ProGallery669818973-overFullscreen"},"stVars":{},"keyframes":{}},
      "",
      -1,
      module.id
  );
  