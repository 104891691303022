import {
  ISubmenuProps,
  MenuMode,
} from '../../../../StylableHorizontalMenu.types';
import { createSubmenuProps_flyout } from './submenuProps_flyout';
import { createSubmenuProps_column } from './submenuProps_column';

export const createSubmenuProps_flyoutAndColumn = (
  className: string,
  menuMode: MenuMode,
): ISubmenuProps => ({
  ...createSubmenuProps_column(className, menuMode),
  ...createSubmenuProps_flyout(className),
});
