import * as React from 'react';
import { ISiteButtonImperativeActions } from '../../../SiteButton.types';
import SiteButtonContent from '../../SiteButtonContent';
import { SkinButtonProps } from '../SkinButton.types';

type ButtonArrowSkinProps = SkinButtonProps & {
  skinsStyle: Record<string, string>;
};

const ButtonArrowSkin: React.ForwardRefRenderFunction<
  ISiteButtonImperativeActions,
  ButtonArrowSkinProps
> = (
  {
    wrapperProps,
    linkProps,
    a11yProps,
    elementType,
    label,
    skinsStyle,
    onFocus,
    onBlur,
  },
  ref,
) => {
  return (
    <div {...wrapperProps} className={skinsStyle.root}>
      <div className={`${skinsStyle.arr} ${skinsStyle.top}`} />
      <div className={`${skinsStyle.arr} ${skinsStyle.bottom}`} />
      <div className={skinsStyle.bg} />
      <SiteButtonContent
        linkProps={linkProps}
        a11yProps={a11yProps}
        elementType={elementType}
        className={skinsStyle.link}
        onFocus={onFocus}
        onBlur={onBlur}
        ref={ref}
      >
        <span className={skinsStyle.label}>{label}</span>
      </SiteButtonContent>
    </div>
  );
};

export default React.forwardRef(ButtonArrowSkin);
