import * as React from 'react';
import { useDidMount } from '@wix/thunderbolt-elements/dist/providers/useDidMount';
import { throttle } from '@wix/thunderbolt-elements/dist/core/commons/utils';

const getNodeHorizontalOffsets = (nodeRef: React.RefObject<HTMLElement>) => {
  const { current: navNode } = nodeRef;
  if (navNode) {
    const { left: navLeft, width: navWidth } = navNode.getBoundingClientRect();

    const documentWidth = document.documentElement.clientWidth;

    return {
      left: navLeft,
      right: documentWidth - navLeft - navWidth,
    };
  }
  return { left: 0, right: 0 };
};

const getCSSVars = ({ left, right }: { left: number; right: number }) => {
  return {
    '--positionBox-offset-left': `${left || 0}`,
    '--positionBox-offset-right': `${right || 0}`,
  };
};

const DELAY = 300;

export const useSubmenuOffsetVars = (nodeRef: React.RefObject<HTMLElement>) => {
  const [horizontalOffsets, setHorizontalOffsets] = React.useState({
    left: 0,
    right: 0,
  });
  function updateOffsets() {
    setHorizontalOffsets(getNodeHorizontalOffsets(nodeRef));
  }
  useDidMount(() => {
    const throttledUpdateOffsets = throttle(updateOffsets, DELAY);
    setTimeout(updateOffsets, DELAY); // delay for component added in the editor
    window?.addEventListener('resize', throttledUpdateOffsets);
    return () => {
      window?.removeEventListener('resize', throttledUpdateOffsets);
    };
  });
  return getCSSVars(horizontalOffsets);
};
