import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  CheckboxProps as CheckboxPlatformProps,
  CheckboxDefinition,
} from '@wix/thunderbolt-components';

export const props = withCompInfo<
  CheckboxPlatformProps,
  CheckboxDefinition,
  CheckboxPlatformProps
>()([], (_deps, carmiData) => {
  return carmiData;
});

export default createComponentMapperModel({ props });
