import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { legacyTransitionTypesMap } from '../../commons/constants';
import {
  SlideShowGalleryCarmiData,
  SlideShowGalleryDefinition,
  SlideShowGalleryProps,
  SlideShowGalleryCSS,
  SlideShowGallerySdkData,
} from '../SlideShowGallery.types';

export const props = withCompInfo<
  SlideShowGalleryProps,
  SlideShowGalleryDefinition,
  SlideShowGalleryCarmiData
>()(['skin', 'compProps'], ({ skin, compProps }, carmiData) => {
  const transition =
    legacyTransitionTypesMap[compProps.transition] || 'NoTransition';
  const hasTransition = transition !== 'NoTransition';
  const transitionDuration = compProps.transDuration * 1000;
  const autoPlayInterval =
    compProps.autoplayInterval * 1000 +
    (hasTransition ? transitionDuration : 0);

  return {
    skin,
    items: carmiData.itemsProps,
    currentIndex: 0,
    transition,
    transitionDuration,
    transitionReverse: compProps.reverse,
    shouldPreserveAspectRatio: compProps.imageMode === 'flexibleHeight',
    autoPlay: compProps.autoplay,
    autoPlayInterval,
    showNavigation: compProps.showNavigation,
    showAutoplay: compProps.showAutoplay,
    showCounter: compProps.showCounter,
    reducedMotion: false,
    imageOnClickAction: compProps.galleryImageOnClickAction,
    alignTextRight: compProps.alignText === 'right',
    imageMode: compProps.imageMode,
  };
});

export const css = withCompInfo<
  SlideShowGalleryCSS,
  SlideShowGalleryDefinition
>()(['compLayout', 'compProps'], ({ compLayout, compProps }) => {
  const { transDuration, imageMode } = compProps;

  return {
    height: imageMode === 'flexibleHeight' ? 'auto' : `${compLayout.height}px`,
    '--transition-duration': `${transDuration * 1000}ms`,
    '--alpha-txt': '1',
  };
});

const imageModeToDisplayMode = {
  clipImage: 'fill',
  flexibleHeight: 'fill',
  flexibleWidthFixed: 'fit',
} as const;

export const sdkData = withCompInfo<
  SlideShowGallerySdkData,
  SlideShowGalleryDefinition
>()(['compProps', 'compData'], ({ compProps, compData }) => {
  return {
    imageDisplayMode: imageModeToDisplayMode[compProps.imageMode], // todo - should be always fill after TB-2206 will be closed
    dataId: compData.id,
  };
});

export default createComponentMapperModel({ props, sdkData, css });
