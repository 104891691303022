import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  ComboBoxInputDefinition,
  ComboBoxInputPlatformProps,
} from '../ComboBoxInput.types';

export const props = withCompInfo<
  ComboBoxInputPlatformProps,
  ComboBoxInputDefinition,
  ComboBoxInputPlatformProps
>()([], (_deps, carmiData) => {
  return carmiData;
});

export default createComponentMapperModel({ props });
