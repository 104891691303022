import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  SelectionTagsListDefinition,
  SelectionTagsListProps,
  SelectionTagsListCSSVars,
  SelectionTagsListCssCarmiData,
  SelectionTagsListAlignmentProps,
} from '../SelectionTagsList.types';
import { getLabelPadding } from '../../../core/commons/inputUtils';

export const props = withCompInfo<
  SelectionTagsListProps,
  SelectionTagsListDefinition
>()(['compData', 'compProps'], ({ compData, compProps }) => {
  const { isDisabled, required, label, alignment } = compProps;
  const { options, value } = compData;

  return { options, value, isDisabled, required, label, alignment };
});

export const css = withCompInfo<
  SelectionTagsListCSSVars,
  SelectionTagsListDefinition,
  SelectionTagsListCssCarmiData
>()(['compProps', 'compLayout'], ({ compProps, compLayout }, carmiProps) => {
  const { direction, labelMargin } = compProps;

  let alignment: string = compProps.alignment;

  if (compProps.alignment === 'left') {
    if (direction === 'rtl') {
      alignment = 'flex-end';
    } else {
      alignment = 'flex-start';
    }
  } else if (compProps.alignment === 'right') {
    if (direction === 'rtl') {
      alignment = 'flex-start';
    } else {
      alignment = 'flex-end';
    }
  }

  return {
    height: 'auto',
    'min-height': compLayout.height || 0,
    '--textAlign': compProps.alignment,
    '--direction': direction,
    '--alignment': alignment as SelectionTagsListAlignmentProps,
    '--fnt': carmiProps['--fnt'],
    '--labelPadding': getLabelPadding(compProps),
    '--labelMarginBottom': `${labelMargin}px`,
  };
});

export default createComponentMapperModel({ props, css: css as any });
