import * as React from 'react';
import { getQaDataAttributes } from '@wix/thunderbolt-elements/dist/core/commons/qaUtils';
import type {
  IStylableHorizontalMenuImperativeActions,
  IStylableHorizontalMenuProps,
} from '../StylableHorizontalMenu.types';
import { MenuItem } from './components/MenuItem/MenuItem';
import { st, classes } from './StylableHorizontalMenu.component.st.css';
import { populateWithChildren } from './utils/populateWithChildren';
import { getHasSubSubs } from './utils/getHasSubSubs';

const StylableHorizontalMenu: React.ForwardRefRenderFunction<
  IStylableHorizontalMenuImperativeActions,
  IStylableHorizontalMenuProps
> = (
  {
    id,
    items,
    submenuProps,
    menuWrapper: MenuWrapper,
    className: stylableClassName,
    containerRootClassName,
    currentPageHref,
    menuMode,
    submenuMode,
    isContainerStretched,
    style,
    slots,
    isQaMode,
    fullNameCompType,
    onMouseEnter,
    onMouseLeave,
    onItemMouseIn,
    onItemMouseOut,
    onItemClick,
    onItemDblClick,
  },
  ref,
) => {
  // TODO: split styles into ui-types
  const className = st(
    classes.root,
    {
      menuMode,
    },
    stylableClassName,
    containerRootClassName,
  );

  const itemsWithChildren = populateWithChildren(items, slots);

  return (
    <div
      id={id}
      style={style}
      {...getQaDataAttributes(isQaMode, fullNameCompType)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <MenuWrapper
        ref={ref}
        items={items}
        className={className}
        currentPageHref={currentPageHref}
      >
        {itemsWithChildren.map((item, index) => {
          const mode =
            submenuMode === 'flyout' && !item.children ? 'flyout' : 'column';
          return (
            <MenuItem
              key={index}
              depth={0}
              item={item}
              currentPageHref={currentPageHref}
              className={classes.menuItem}
              isContainerStretched={isContainerStretched}
              isColumnStretched={submenuMode === 'columnStretched'}
              submenuProps={submenuProps[mode]!}
              onItemClick={onItemClick}
              onItemMouseIn={onItemMouseIn}
              onItemMouseOut={onItemMouseOut}
              onItemDblClick={onItemDblClick}
              // is needed only in the column mode
              hasSubSubs={mode === 'column' && getHasSubSubs(item)}
            >
              {item.children}
            </MenuItem>
          );
        })}
      </MenuWrapper>
    </div>
  );
};

export default React.forwardRef(StylableHorizontalMenu);
