import * as React from 'react';
import WPhoto from '../../WPhotoBase';
import { SkinWPhotoProps } from '../SkinWPhoto';
import { BaseWPhotoSkinProps } from '../../../WPhoto.types';
import skinsStyles from './styles/RoundShadowPhoto.scss';
import BasicWPhotoSkin from './BasicWPhotoSkin';

const RoundShadowPhotoSkin: React.FC<Omit<BaseWPhotoSkinProps, 'skinsStyle'>> =
  props => <BasicWPhotoSkin {...props} skinsStyle={skinsStyles} />;

const RoundShadowPhoto: React.FC<SkinWPhotoProps> = props => (
  <WPhoto {...props} skin={RoundShadowPhotoSkin} />
);

export default RoundShadowPhoto;
