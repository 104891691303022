import * as React from 'react';
import { IMatrixGalleryProps } from '../../../MatrixGallery.types';
import MatrixGallery from '../../MatrixGallery';
import skinsItemStyle from '../../../../MatrixGalleryItem/viewer/style/MatrixGallerySeparateTextBoxSkinItem.scss';
import MatrixGalleryItemWithPanel from '../../../../MatrixGalleryItem/viewer/MatrixGalleryItemWithPanel';
import skinsStyle from './style/MatrixGallerySeparateTextBoxSkin.scss';

const MatrixGallerySeparateTextBoxSkin: React.FC<IMatrixGalleryProps> =
  props => {
    return (
      <MatrixGallery
        {...props}
        skinsStyle={skinsStyle}
        skinsItemStyle={skinsItemStyle}
        MatrixGalleryItemComponent={MatrixGalleryItemWithPanel}
      />
    );
  };

export default MatrixGallerySeparateTextBoxSkin;
