import * as React from 'react';
import classnames from 'classnames';
import { IWelcomeDialogProps } from '../WelcomeDialog.types';
import BasicButton from '../../SiteButton/viewer/skinComps/BaseButton/BasicButton.skin';
import { WelcomeDialogTranslationKeys as keys } from '../constants';
import SiteMembersDialogLayout from '../../SiteMembersDialogLayout/viewer/SiteMembersDialogLayout';
import style from './style/style.scss';

const translationFeature = 'SM';

const WelcomeDialog: React.FC<IWelcomeDialogProps> = props => {
  const { id, isCloseable, onCloseDialogCallback, onSubmitCallback } = props;
  const translate = props.translate!.bind(null, translationFeature);
  const headlineId = `WelcomeHeadline_${id}`;
  const title = translate!(keys.title, 'Welcome To Your Account!');
  const description = `${translate!(
    keys.description,
    `We have some info from when you've been to our site before.`,
  ).replace('&#39;', "'")} `;
  const descriptionSecondary = translate!(
    keys.descriptionSecondary,
    `Check that everything is correct.`,
  );
  const btnTxt = translate!(keys.btnTxt, `Edit Account Info`);
  const backToSiteLinkTxt = translate!(keys.backToSiteLinkTxt, `Back to site`);

  return (
    <SiteMembersDialogLayout
      isCloseable={isCloseable}
      translate={props.translate}
      onCloseDialogCallback={onCloseDialogCallback}
      headlineId={headlineId}
    >
      <div
        id={id}
        className={classnames(style.welcomeContent, style.responsive)}
      >
        <h3 id={headlineId} className={style.title} data-testid="title">
          {title}
        </h3>
        <div className={style.wrapper}>
          <div className={style.content}>
            <div className={style.someInfoTxt} data-testid="someInfoTxt">
              {description}
            </div>
            <div className={style.checkTxt} data-testid="checkTxt">
              {descriptionSecondary}
            </div>
          </div>
          <div className={style.footer}>
            <div className={style.actionButton}>
              <BasicButton
                label={btnTxt}
                id={`okButton_${id}`}
                isDisabled={false}
                hasPlatformClickHandler={true}
                link={undefined}
                onClick={() => onSubmitCallback()}
              />
            </div>
            <button
              id={`backButton_${id}`}
              role="link"
              tabIndex={0}
              className={style.backButton}
              onClick={() => {
                if (onCloseDialogCallback) {
                  onCloseDialogCallback();
                }
              }}
              autoFocus
            >
              {backToSiteLinkTxt}
            </button>
          </div>
        </div>
      </div>
    </SiteMembersDialogLayout>
  );
};

export default WelcomeDialog;
