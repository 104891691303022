import * as React from 'react';
import { CoBrandingBannerProps } from '../CoBrandingBannerMobile.types';
import { getAllTranslations } from '../utils';
import { TEST_IDS } from '../constants';
import BannerWrapper from '../../CoBrandingBannerDesktop/viewer/BannerWrapper';
import style from './styles/CoBrandingBannerMobile.scss';

const CoBrandingBannerMobile: React.FC<CoBrandingBannerProps> = props => {
  const {
    id = 'WIX_ADS',
    translate,
    brandLogoUrl,
    brandSiteUrl,
    direction,
  } = props;

  const translations = React.useMemo(
    () => getAllTranslations(translate, true),
    [translate],
  );

  const titleTextWithLogo = translations.titleText.replace(
    '{Logo}',
    `<img data-testid="${TEST_IDS.logo}" src="${brandLogoUrl}"/>`,
  );

  return (
    <BannerWrapper
      id={id}
      brandSiteUrl={brandSiteUrl}
      direction={direction}
      style={style}
    >
      <span
        data-testid={TEST_IDS.logoWrapper}
        className={style.logoWrapper}
        dangerouslySetInnerHTML={{ __html: titleTextWithLogo }}
      />
    </BannerWrapper>
  );
};

export default CoBrandingBannerMobile;
