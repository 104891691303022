
  var rt = require("@stylable/runtime/cjs/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "ScrollButton2305195801",
      {"classes":{"root":"ScrollButton2305195801__root","icon":"ScrollButton2305195801__icon"},"vars":{},"stVars":{},"keyframes":{}},
      "",
      -1,
      module.id
  );
  