import React, { useRef } from 'react';
import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import { PreviewWrapperProps } from '@wix/editor-elements-types';
import {
  usePreviewEffect,
  usePreviewState,
  usePrevious,
  useResetComponent,
  useModalPreviewState,
} from '@wix/editor-elements-preview-utils';
import {
  IComboBoxInputImperativeActions,
  IComboBoxInputProps,
} from '../ComboBoxInput.types';
import designableListStyle from '../../ComboBoxInputListModal/viewer/style/ComboBoxInputListModal.scss';
import style from './style/ComboBoxInput.scss';
import { useShowTooltip } from './skinComps/Navigation/comboBoxInputNavigationWrapperHooks';

const noop = () => {};

const isListPreviewState = (compPreviewState: string | null | undefined) =>
  compPreviewState?.includes('list');

export default (ViewerComponent: React.ComponentType<IComboBoxInputProps>) =>
  createComponentPreviewEntry(
    React.forwardRef<IComboBoxInputImperativeActions, IComboBoxInputProps>(
      (
        {
          previewWrapperProps = {},
          ...viewerProps
        }: PreviewWrapperProps<
          IComboBoxInputProps,
          {
            shouldResetComponent?: boolean;
            compPreviewState?: string;
            componentViewMode?: string;
          }
        >,
        ref,
      ) => {
        const { shouldResetComponent, compPreviewState, componentViewMode } =
          previewWrapperProps;
        const {
          id,
          value = '',
          designableList,
          setValidityIndication = noop,
          validateValue = noop,
          onChange = noop,
          showTooltip,
          options,
          skin,
        } = viewerProps;

        const shouldShowValidityIndication =
          compPreviewState === 'error' ||
          viewerProps.shouldShowValidityIndication;

        const forceOpenDesignableList =
          componentViewMode === 'editor' &&
          isListPreviewState(compPreviewState) &&
          designableList;

        const defaultValue = useRef(value);
        const [designableListElem, setDesignableListElem] =
          React.useState<HTMLDivElement | null>(null);
        const previousViewMode = usePrevious(componentViewMode);

        React.useEffect(() => {
          if (previousViewMode === 'editor' && componentViewMode === 'editor') {
            defaultValue.current = value;
          }
        }, [value, previousViewMode, componentViewMode]);

        usePreviewEffect({
          componentViewMode,
          onPreviewViewMode: validateValue,
        });

        useResetComponent({
          shouldResetComponent,
          id,
          onResetComponent: () => {
            onChange({
              target: {
                value: defaultValue.current,
              } as any,
            } as any);
            setValidityIndication(false);
            validateValue();
          },
        });

        usePreviewState(id, compPreviewState, {
          focus: {
            selectors: `.${style.select}`,
            type: 'single',
          },
          error: {
            selectors: `.${style.select}`,
            type: 'single',
            state: 'invalid',
          },
          hover: {
            selectors: `.${style.select}`,
            type: 'single',
          },
          disabled: {
            selectors: `.${style.select}`,
            type: 'single',
          },
          'list regular': {
            selectors: `.${style.select}`,
            type: 'single',
            state: 'focus',
          },
          'list hover': {
            selectors: `.${style.select}`,
            type: 'single',
            state: 'focus',
          },
        });

        useModalPreviewState(designableListElem, compPreviewState, {
          'list hover': {
            selectors: `.${designableListStyle.option}`,
            type: 'single',
            state: 'hovered',
          },
        });

        useShowTooltip(id, options, skin, showTooltip, componentViewMode);

        return (
          <ViewerComponent
            {...viewerProps}
            forceOpenDesignableList={forceOpenDesignableList}
            setDesignableListElem={setDesignableListElem}
            ref={ref}
            shouldShowValidityIndication={shouldShowValidityIndication}
          />
        );
      },
    ),
  );
