import React from 'react';
import type {
  VerticalMenuProps,
  VerticalMenuImperativeActions,
} from '../VerticalMenu.types';
import VerticalMenuRoot from './VerticalMenuRoot';
import styles from './styles/VerticalMenu.scss';

const VerticalMenuSeparatedButtonFixedWidthSkin: React.ForwardRefRenderFunction<
  VerticalMenuImperativeActions,
  VerticalMenuProps
> = (props, ref) => (
  <VerticalMenuRoot
    {...props}
    ref={ref}
    style={styles}
    separatedButton={true}
    // Since this skin is the fallback for legacy skins we want need to override them
    // TODO: root component + skin?
    skin={'VerticalMenuSeparatedButtonFixedWidthSkin'}
  />
);

export default React.forwardRef(VerticalMenuSeparatedButtonFixedWidthSkin);
