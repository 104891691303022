import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { SocialAuthDefinition, SocialAuthProps } from '../SocialAuth.types';

export const props = withCompInfo<SocialAuthProps, SocialAuthDefinition>()(
  ['compProps', 'skin', 'language'],
  ({ compProps, skin, language }) => {
    const {
      isSocialLoginFacebookEnabled,
      isSocialLoginGoogleEnabled,
      mode,
      isSmallIcons,
    } = compProps;
    return {
      skin,
      language,
      isSocialLoginGoogleEnabled,
      isSocialLoginFacebookEnabled,
      mode,
      isSmallIcons,
    };
  },
);

export default createComponentMapperModel({ props });
