import React from 'react';
import { ITinyMenuProps } from '../../../TinyMenu.types';
import TinyMenu from '../../TinyMenu';
import { SkinTinyMenuProps } from '../SkinTinyMenu';
import BaseMenu from './BaseMenu';
import skinsStyle from './styles/TinyMenuPullFromRightSkin.scss';

const Skin: React.FC<SkinTinyMenuProps> = props => (
  <BaseMenu shouldCloseOnSwipe="right" {...props} skinsStyle={skinsStyle} />
);

const TinyMenuPullFromRightSkin: React.FC<Omit<ITinyMenuProps, 'skin'>> =
  props => <TinyMenu {...props} skin={Skin} />;

export default TinyMenuPullFromRightSkin;
