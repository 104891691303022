import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import { PreviewWrapperProps } from '@wix/editor-elements-types';
import {
  RatingsDisplayPlatformProps,
  RatingsDisplayDefinition,
} from '../RatingsDisplay.types';
import { props as viewerPropsMapper } from './RatingsDisplay.mapper';

const props = composeMapper<
  PreviewWrapperProps<RatingsDisplayPlatformProps>,
  RatingsDisplayDefinition,
  RatingsDisplayPlatformProps
>()(
  viewerPropsMapper,
  {
    add: [
      'compPreviewState',
      'shouldResetComponent',
      'componentViewMode',
      'compProps',
    ],
  },
  (resolver, dependencies, carmiData) => {
    const viewerProps = resolver(dependencies, carmiData);

    const {
      shouldResetComponent,
      compPreviewState,
      componentViewMode,
      compProps: { shapeSize, shapeSpacing },
    } = dependencies;

    return {
      ...viewerProps,
      previewWrapperProps: {
        componentViewMode,
        shouldResetComponent,
        compPreviewState,
        shapeSize,
        shapeSpacing,
      },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
