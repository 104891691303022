import React from 'react';
import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import { PreviewWrapperProps } from '@wix/editor-elements-types';
import { BackToTopButtonProps } from '../BackToTopButton.types';

export default (ViewerComponent: React.ComponentType<BackToTopButtonProps>) =>
  createComponentPreviewEntry(function ({
    previewWrapperProps = {},
    ...viewerProps
  }: PreviewWrapperProps<
    BackToTopButtonProps,
    {
      componentViewMode?: string;
      isVisibleInEditor?: boolean;
      isMobileView?: boolean;
    }
  >) {
    return (
      <ViewerComponent
        {...viewerProps}
        isVisible={
          (previewWrapperProps.isMobileView &&
            previewWrapperProps.componentViewMode === 'editor' &&
            previewWrapperProps.isVisibleInEditor) ||
          viewerProps.isVisible
        }
      />
    );
  });
