import * as React from 'react';
import classNames from 'classnames';
import { IResponsivePopupPageProps } from '../ResponsivePopupPage.types';
import ResponsiveContainer from '../../../thunderbolt-core-components/ResponsiveContainer/viewer/ResponsiveContainer';
import { TestIds } from '../constants';
import style from './style/ResponsivePopupPage.scss';

const PopupPage: React.FC<IResponsivePopupPageProps> = ({
  id,
  pageDidMount,
  onClick,
  children,
  containerProps,
}) => (
  <div
    id={id}
    className={classNames(style.root, { [style.clickable]: onClick })}
    ref={pageDidMount}
  >
    <div
      className={style.bg}
      onClick={onClick}
      data-testid={TestIds.ResponsivePopupPageBg}
    />
    <ResponsiveContainer
      {...containerProps}
      extraRootClass={style.responsiveContainer}
    >
      {() => <div className={style.lightboxLayer}>{children()}</div>}
    </ResponsiveContainer>
  </div>
);

export default PopupPage;
