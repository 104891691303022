import * as React from 'react';
import { QuickActionBarSkinProps } from '../../QuickActionBar.types';
import { ACTION_CONTAINER } from '../constants';

const BaseFixedSkin: React.ForwardRefRenderFunction<
  never,
  QuickActionBarSkinProps
> = (props, ref) => {
  const { rootProps, classes = {}, id, onMouseEnter, onMouseLeave } = props;

  const rootStyles = {
    display: rootProps.display,
    position: rootProps.position,
    ...props.style,
  };

  return (
    <div
      className={classes.root}
      style={rootStyles}
      id={id}
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={classes.actionsContainer} id={`${id}${ACTION_CONTAINER}`}>
        {props.children}
      </div>
    </div>
  );
};

export default React.forwardRef(BaseFixedSkin);
