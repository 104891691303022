import * as React from 'react';
import SiteButtonContent from '../../SiteButtonContent';
import { SkinButtonProps } from '../SkinButton.types';
import {
  ISiteButtonImperativeActions,
  ISiteButtonProps,
} from '../../../SiteButton.types';
import SiteButton from '../../SiteButton';
import skinsStyle from './styles/skins.scss';

const ShinyGradientButtonSkin = React.forwardRef<
  ISiteButtonImperativeActions,
  SkinButtonProps
>(
  (
    { wrapperProps, linkProps, a11yProps, elementType, label, onFocus, onBlur },
    ref,
  ) => {
    return (
      <div {...wrapperProps} className={skinsStyle.ShinyGradientButton}>
        <div className={`${skinsStyle.pose} ${skinsStyle.mainColor}`} />
        <div className={`${skinsStyle.pose} ${skinsStyle.grd1}`} />
        <div className={`${skinsStyle.pose} ${skinsStyle.topper}`} />
        <SiteButtonContent
          linkProps={linkProps}
          a11yProps={a11yProps}
          elementType={elementType}
          className={skinsStyle.link}
          onFocus={onFocus}
          onBlur={onBlur}
          ref={ref}
        >
          <span className={skinsStyle.label}>{label}</span>
        </SiteButtonContent>
        <div className={`${skinsStyle.pose} ${skinsStyle.bord}`} />
      </div>
    );
  },
);

const ShinyGradientButton: React.ForwardRefRenderFunction<
  ISiteButtonImperativeActions,
  Omit<ISiteButtonProps, 'skin'>
> = (props, ref) => (
  <SiteButton {...props} skin={ShinyGradientButtonSkin} ref={ref} />
);

export default React.forwardRef(ShinyGradientButton);
