import React from 'react';
import {
  IVerticalAnchorsMenuProps,
  ILinkContent,
} from '../VerticalAnchorsMenu.types';
import VerticalAnchorsMenuCommonSkin from './skinComps/VerticalAnchorsMenuCommonSkin';
import styles from './styles/VerticalAnchorsMenu.scss';

const LinkContent = (props: ILinkContent) => {
  const { label, id } = props;
  return (
    <span id={id} className={styles.label}>
      {label}
    </span>
  );
};

const VerticalAnchorsMenuTextSkin: React.FC<IVerticalAnchorsMenuProps> =
  props => {
    return (
      <VerticalAnchorsMenuCommonSkin
        {...props}
        style={styles}
        skin={'VerticalAnchorsMenuTextSkin'}
        linkContent={LinkContent}
      />
    );
  };

export default VerticalAnchorsMenuTextSkin;
