import {
  createComponentMapperModel,
  withCompInfo,
} from '@wix/editor-elements-integrations';
import {
  RatingsInputDefinition,
  RatingsInputPlatformProps,
  RatingsInputCSSVars,
  RatingsInputCssCarmiData,
  RatingsInputPropsCarmiData,
  RatingsInputProperties,
  RatingsInputLabelPosition,
} from '../RatingsInput.types';

export const props = withCompInfo<
  RatingsInputPlatformProps,
  RatingsInputDefinition,
  RatingsInputPropsCarmiData
>()(
  ['compData', 'compProps', 'isMobileView'],
  ({ compData, compProps, isMobileView }, carmiProps) => {
    return {
      ...carmiProps,
      rtl: compProps.direction === 'rtl',
      labelPosition:
        isMobileView && compProps.labelPosition === 'side'
          ? 'top'
          : compProps.labelPosition,
      showTitle: compProps.showTitle,
      title: compData.titleText,
      showLabels: compProps.showLabels,
      value: compData.value,
      labels: compData.labels,
      isDisabled: compProps.isDisabled,
      required: compProps.required,
    };
  },
);

const iconsMarginCss = (
  compProps: RatingsInputProperties,
  labelPosition: RatingsInputLabelPosition,
) => {
  const { shapeSize, shapeSpacing } = compProps;
  const margin = `${
    labelPosition === 'side' ? Math.max(15, shapeSpacing) : shapeSize / 2
  }px`;

  if (labelPosition === 'top') {
    return { '--iconsMarginTop': margin };
  } else if (labelPosition === 'bottom') {
    return { '--iconsMarginBottom': margin };
  }

  if (compProps.direction === 'rtl') {
    return { '--iconsMarginLeft': margin };
  }
  return { '--iconsMarginRight': margin };
};

const notSideCss = (compProps: RatingsInputProperties, iconSize: string) => {
  const inputAlignment = {
    center: 'center',
    left: compProps.direction === 'rtl' ? 'flex-end' : 'flex-start',
    right: compProps.direction === 'rtl' ? 'flex-start' : 'flex-end',
  };

  return {
    '--labelsContainerWidth': iconSize,
    '--inputAlignItems': inputAlignment[compProps.labelAlignment],
    '--inputFlexDirection': 'column',
  };
};

export const css = withCompInfo<
  RatingsInputCSSVars,
  RatingsInputDefinition,
  RatingsInputCssCarmiData
>()(['compProps', 'isMobileView'], ({ compProps, isMobileView }, carmiCss) => {
  const {
    showTitle,
    showLabels,
    shapeSize,
    shapeSpacing,
    labelAlignment,
    direction,
  } = compProps;
  const iconSize = `${shapeSize * 5 + shapeSpacing * 4}px`;
  const labelPosition =
    isMobileView && compProps.labelPosition === 'side'
      ? 'top'
      : compProps.labelPosition;
  const rtl = direction === 'rtl';
  const labelsContainerAlignment = {
    top: 'flex-end',
    bottom: 'flex-start',
    side: 'center',
  };
  let cssVars: RatingsInputCSSVars = {
    ...carmiCss,
    '--inputAlignItems': 'center',
    '--inputFlexDirection': 'row',
    '--iconsMinWidth': iconSize,
    '--iconsOrder': labelPosition === 'top' ? 2 : 0,
    '--labelsContainerAlignItems': labelsContainerAlignment[labelPosition],
    '--inputTextAlign': `${labelAlignment}`,
    '--iconSize': `${shapeSize}px`,
    [`--iconPadding${rtl ? 'Left' : 'Right'}`]: `${shapeSpacing}px`,
    '--direction': direction,
    height: 'auto',
  };

  if (labelPosition !== 'side') {
    cssVars = {
      ...cssVars,
      ...notSideCss(compProps, iconSize),
    };
  }

  if (showTitle || showLabels) {
    return {
      ...cssVars,
      ...iconsMarginCss(compProps, labelPosition),
    };
  }

  return cssVars;
});

export default createComponentMapperModel({ props, css });
