import * as React from 'react';
import { createPortal } from 'react-dom';
import { ICustomSelectProps } from '../../ComboBoxInput.types';
import { usePortalPopper } from '../../../../providers/usePortalPopper';
import ComboBoxInputListModal from '../../../ComboBoxInputListModal/viewer/ComboBoxInputListModal';
import NativeSelect from '../NativeSelect/NativeSelect';
import { keyCodes } from '../../../../core/commons/a11y';
import { useClickOutside } from '../../../../providers/useClickOutside/useClickOutside';
import { calculateElemWidth, noop } from '../../utils';
import { useResizeObserver } from '../../../../providers/useResizeObserver/useResizeObserver';
import { CustomPopperProps } from '../../../../providers/usePopper/usePopper';
import { useExpandableListKeyDown } from '../../../../providers/useExpandableListKeyDown/useExpandableListKeyDown';

const popperConfig: CustomPopperProps = {
  placement: 'bottom-start',
  modifiers: [
    {
      name: 'flip',
      options: {
        boundary: 'clippingParents',
        fallbackPlacements: ['top-start', 'bottom-start'],
        allowedAutoPlacements: ['top-start', 'bottom-start'],
      },
    },
    {
      name: 'computeStyles',
      options: {
        roundOffsets: false,
      },
    },
  ],
};

const CustomSelect: React.ForwardRefRenderFunction<
  HTMLSelectElement,
  ICustomSelectProps
> = (props, ref) => {
  const {
    className,
    styles,
    id,
    placeholder,
    onFocus,
    onSelectedOptionChange = noop,
    onBlur,
    disabled,
    required,
    value,
    options,
    setDesignableListElem,
    forceOpenDesignableList,
    onClick,
    ariaAttributes,
  } = props;

  const [isDesignableListOpen, setIsDesignableListOpen] = React.useState(false);
  const [designableListWidth, setDesignableListWidth] = React.useState(200);
  const { hoveredOptionIndex, setHoveredOptionIndex, onKeyDown } =
    useExpandableListKeyDown({
      isListOpen: isDesignableListOpen,
      openListFn: () => setIsDesignableListOpen(true),
      closeListFn: () => setIsDesignableListOpen(false),
      initialHoveredOptionIndex: placeholder ? -1 : 0,
      openListKeyCodes: [keyCodes.space],
      closeListKeyCodes: [
        keyCodes.enter,
        keyCodes.escape,
        keyCodes.space,
        keyCodes.tab,
      ],
      listLength: options.length,
    });

  const {
    ref: designableListSourceElem,
    setRef: setDesignableListSourceElem,
    popper: designableListTargetElem,
    setPopper: setDesignableListTargetElem,
    styles: popperStyles,
    attributes: popperAttributes,
    poppersWrapper: portalPoppersWrapper,
    mountPortalPoppersWrapper,
    unMountPortalPoppersWrapper,
  } = usePortalPopper<HTMLDivElement>({ id, popperOptions: popperConfig });

  const shouldOpenDesignableList = React.useMemo(
    () => isDesignableListOpen || forceOpenDesignableList,
    [isDesignableListOpen, forceOpenDesignableList],
  );
  React.useEffect(() => {
    if (shouldOpenDesignableList) {
      mountPortalPoppersWrapper();
    } else {
      unMountPortalPoppersWrapper();
    }
  }, [
    shouldOpenDesignableList,
    mountPortalPoppersWrapper,
    unMountPortalPoppersWrapper,
  ]);

  useClickOutside([designableListSourceElem, designableListTargetElem], () =>
    setIsDesignableListOpen(false),
  );

  const updateDesignableListWidth = React.useCallback(() => {
    if (designableListSourceElem) {
      const currentListWidth = calculateElemWidth(designableListSourceElem);

      if (currentListWidth !== designableListWidth) {
        setDesignableListWidth(currentListWidth);
      }
    }
  }, [designableListSourceElem, designableListWidth]);

  const toggleDesignableList = () => {
    updateDesignableListWidth();

    const selectRef =
      ref as React.MutableRefObject<HTMLSelectElement | null> | null;

    if (!isDesignableListOpen) {
      selectRef?.current?.focus();
    }

    setIsDesignableListOpen(!isDesignableListOpen);
  };

  const handleNativeSelectOnMouseDown: React.MouseEventHandler<HTMLSelectElement> =
    e => {
      e.preventDefault();
      toggleDesignableList();
    };

  const handleSearchNavigationWithKey = (
    key: string,
    hoveredOptionIdx: number,
    keyCode: any,
  ) => {
    const searchNavigationBlacklist = [
      keyCodes.enter,
      keyCodes.arrowDown,
      keyCodes.arrowUp,
      keyCodes.arrowLeft,
      keyCodes.arrowRight,
      keyCodes.space,
      keyCodes.escape,
    ];

    if (searchNavigationBlacklist.includes(keyCode)) {
      return;
    }

    const isSearchingOptionWithNewKey =
      key.toLowerCase() !== options[hoveredOptionIndex]?.text[0].toLowerCase();

    const matchingOptionIndex = options.findIndex(
      (option, index) =>
        option.text[0].toLowerCase() === key.toLowerCase() &&
        (index > hoveredOptionIdx || isSearchingOptionWithNewKey),
    );

    if (matchingOptionIndex > -1) {
      handleOnSelectedOptionChange({
        optionValue: options[matchingOptionIndex].value,
        toggleList: false,
      });
      setHoveredOptionIndex(matchingOptionIndex);
    }
  };

  const handleNativeSelectonOnKeyDown: React.KeyboardEventHandler<HTMLSelectElement> =
    e => {
      const callOnChangeKeyCodes: Array<number> = [
        keyCodes.enter,
        keyCodes.arrowDown,
        keyCodes.arrowUp,
      ];
      const { keyCode, key } = e;
      const handleTabKeyDown = () => {
        if (isDesignableListOpen) {
          const selectRef = ref as React.MutableRefObject<HTMLSelectElement>;
          selectRef?.current.focus();
        }
      };
      const additionalActionFn = (
        hoveredOptionIdx: number,
        isHoveredOptionChanged: boolean,
      ) => {
        if (keyCode === keyCodes.tab) {
          handleTabKeyDown();
        }
        if (callOnChangeKeyCodes.includes(keyCode) && isHoveredOptionChanged) {
          handleOnSelectedOptionChange({
            optionValue: options[hoveredOptionIdx].value,
            toggleList: false,
          });
        }

        handleSearchNavigationWithKey(key, hoveredOptionIdx, keyCode);
      };

      onKeyDown(e, additionalActionFn);
    };

  const handleOnSelectedOptionChange = ({
    optionValue,
    toggleList = true,
  }: {
    optionValue: string;
    toggleList?: boolean;
  }) => {
    onSelectedOptionChange(optionValue);

    if (toggleList) {
      toggleDesignableList();
    }
  };

  const onResizeCallback = React.useCallback(() => {
    if (designableListSourceElem) {
      setDesignableListWidth(calculateElemWidth(designableListSourceElem));
    }
  }, [designableListSourceElem]);
  useResizeObserver({
    elem: designableListSourceElem,
    callback: onResizeCallback,
  });

  return (
    <React.Fragment>
      <NativeSelect
        setWrapperRef={
          setDesignableListSourceElem as React.LegacyRef<HTMLDivElement>
        }
        ref={ref}
        className={className}
        styles={styles}
        id={id}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
        required={required}
        onMouseDown={handleNativeSelectOnMouseDown}
        onKeyDown={handleNativeSelectonOnKeyDown}
        value={value}
        options={options}
        placeholder={placeholder}
        isOpen={shouldOpenDesignableList}
        hoveredOptionIndex={hoveredOptionIndex}
        onClick={onClick}
        ariaAttributes={ariaAttributes}
      />
      {portalPoppersWrapper &&
        shouldOpenDesignableList &&
        createPortal(
          <div
            ref={setDesignableListTargetElem}
            style={{
              ...popperStyles.popper,
              ...{
                width: `${designableListWidth}px`,
                zIndex: 'var(--portals-z-index)' as any,
              },
            }}
            {...popperAttributes.popper}
          >
            <ComboBoxInputListModal
              id={id}
              value={value}
              setDesignableListElem={setDesignableListElem}
              onSelectedOptionChange={optionValue =>
                handleOnSelectedOptionChange({ optionValue })
              }
              options={options}
              hoveredOptionIndex={hoveredOptionIndex}
              setHoveredOptionIndex={setHoveredOptionIndex}
            />
          </div>,
          portalPoppersWrapper,
        )}
    </React.Fragment>
  );
};

export default React.forwardRef(CustomSelect);
