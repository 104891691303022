import * as React from 'react';
import { IProgressBarProps } from '../ProgressBar.types';
import { AriaKeys, TestIDs, DataKeys } from './constants';
import { classes, st } from './style/ProgressBar.st.css';

const FULL_PROGRESS = 100;
const NO_PROGRESS = 0;

function getDataAttributes(
  props: IProgressBarProps,
  progressValue: number,
): Record<DataKeys, number | string> {
  return {
    [DataKeys.value]: progressValue,
    [DataKeys.min]: NO_PROGRESS,
    [DataKeys.max]: props.targetValue || FULL_PROGRESS,
  };
}

function getAriaAttributes(props: IProgressBarProps): {
  [key in AriaKeys]: React.HTMLAttributes<HTMLDivElement>[key];
} {
  return {
    [AriaKeys.valuenow]: +props.value || NO_PROGRESS,
    [AriaKeys.valuemin]: NO_PROGRESS,
    [AriaKeys.valuemax]: props.targetValue || FULL_PROGRESS,
  };
}

function getProgressValue(props: IProgressBarProps): number {
  const { value = NO_PROGRESS, targetValue = FULL_PROGRESS } = props;
  const relativeValue = Math.min((value / targetValue) * 100, FULL_PROGRESS);
  return +relativeValue.toFixed(2);
}

const ProgressBar: React.FC<IProgressBarProps> = props => {
  const progressValue = getProgressValue(props);
  return (
    <div
      id={props.id}
      {...getDataAttributes(props, progressValue)}
      {...getAriaAttributes(props)}
      role="progressbar"
      className={st(classes.root)}
    >
      <div data-testid={TestIDs.container} className={classes.barContainer}>
        <div
          data-testid={TestIDs.background}
          className={classes.barBackground}
        />
        <div
          data-testid={TestIDs.foreground}
          className={classes.barForeground}
          style={{ width: `${progressValue}%` }}
        />
      </div>
    </div>
  );
};

ProgressBar.defaultProps = {
  targetValue: FULL_PROGRESS,
};

export default ProgressBar;
