
  var rt = require("@stylable/runtime/cjs/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "itemDepth12472627565",
      {"classes":{"root":"itemDepth12472627565__root","container":"itemDepth12472627565__container","label":"itemDepth12472627565__label","itemWrapper":"itemDepth12472627565__itemWrapper","list":"itemDepth12472627565__list","positionBox":"itemDepth12472627565__positionBox","animationBox":"itemDepth12472627565__animationBox"},"vars":{"translate-x":"--itemDepth12472627565-translate-x"},"stVars":{"subsubmenu-box-opacity":"var(--subsubmenu-box-opacity)","subsubmenu-box-pointer-events":"var(--subsubmenu-box-pointer-events)","subsubmenu-box-position":"var(--subsubmenu-box-position)","subsubmenu-box-show-animation":"var(--subsubmenu-box-show-animation)","subsubmenu-box-left":"var(--subsubmenu-box-left)","subsubmenu-box-right":"var(--subsubmenu-box-right)","subsubmenu-box-translate-x-coef":"var(--subsubmenu-box-translate-x-coef, 1)","white-space":"var(--white-space)","label-word-wrap":"var(--label-word-wrap)","columns-count":"var(--columns-count)"},"keyframes":{"expandSubmenu":"itemDepth12472627565__expandSubmenu"}},
      "",
      -1,
      module.id
  );
  