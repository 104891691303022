import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  FileUploaderDefinition,
  FileUploaderPlatformProps,
} from '../FileUploader.types';

export const props = withCompInfo<
  FileUploaderPlatformProps,
  FileUploaderDefinition,
  FileUploaderPlatformProps
>()([], (_deps, carmiData) => {
  return carmiData;
});

export default createComponentMapperModel({ props });
