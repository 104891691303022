import * as React from 'react';
import { SkinWPhotoProps } from '../SkinWPhoto';
import { WPhotoWrapper } from '../../WPhotoWrapper';
import Link from '../../../../Link/viewer/Link';
import WPhoto from '../../WPhotoBase';
import { isEmptyObject } from '../../../../../core/commons/utils';
import { selectProperComponent, getPropsForLink } from '../../../utils';
import skinsStyle from './styles/skins.scss';

const PaperclipPhotoSkin: React.FC<SkinWPhotoProps> = ({
  id,
  link,
  imageProps,
  title,
  onClick,
  hasPlatformClickHandler = false,
  onClickBehavior,
  onDblClick,
  onMouseEnter,
  onMouseLeave,
  filterEffectSvgString,
  filterEffectSvgUrl,
}) => {
  const ImageComp = selectProperComponent(onClickBehavior);
  const isPopUp = onClickBehavior === 'zoomMode';
  const linkProps = getPropsForLink({
    onClickBehavior,
    className: skinsStyle.link,
    link,
  });

  return (
    <WPhotoWrapper
      id={id}
      className={skinsStyle.PaperclipPhoto}
      title={title}
      onClick={onClick}
      onDblClick={onDblClick}
      withOnClickHandler={
        !isEmptyObject(link) || hasPlatformClickHandler || isPopUp
      }
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      filterEffectSvgString={filterEffectSvgString}
      filterEffectSvgUrl={filterEffectSvgUrl}
    >
      <Link {...linkProps}>
        <div className={skinsStyle.wrp}>
          <ImageComp
            id={`img_${id}`}
            {...imageProps}
            className={skinsStyle.image}
            link={isPopUp ? link : undefined}
          />
        </div>
        <div className={skinsStyle.xxx}></div>
      </Link>
      <div className={skinsStyle.clip}></div>
    </WPhotoWrapper>
  );
};

const PaperclipPhoto: React.FC<SkinWPhotoProps> = props => (
  <WPhoto {...props} skin={PaperclipPhotoSkin} />
);

export default PaperclipPhoto;
