import React, { useState, useRef, useCallback } from 'react';
import ChevronRight from 'wix-ui-icons-common/on-stage/ChevronRight';
import ChevronLeft from 'wix-ui-icons-common/on-stage/ChevronLeft';
import { debounce } from '@wix/thunderbolt-elements/dist/core/commons/utils';
import { useDidMount } from '@wix/thunderbolt-elements/dist/providers/useDidMount';
import { useDidUpdate } from '@wix/thunderbolt-elements/dist/providers/useDidUpdate';
import { MenuMode, TestIds } from '../../constants';
import { ITabsListItem, ITabsListProps } from '../../Tabs.types';
import TabsListItem from './TabsListItem';
import ScrollBtn from './ScrollBtn';
import { classes, st } from './style/TabsList.st.css';
import { animateElementByProp } from './animation';

enum ScrollDirection {
  FORWARD = 1,
  BACKWARD = -1,
}

const clamp = (value: number = 0, min: number = 0, max: number = 0) =>
  Math.max(Math.min(value, max), min);

const TabsList: React.FC<ITabsListProps> = ({
  currentTabId,
  itemsDirection,
  tabItems,
  onTabClick,
  menuMode,
}) => {
  const [shouldShowBackButton, setShouldShowBackButton] = useState(true);
  const [shouldShowForwardButton, setShouldShowForwardButton] = useState(true);

  const activeTabRef = useRef<HTMLDivElement>(null);
  const navRef = useRef<HTMLDivElement>(null);

  const isRtl = itemsDirection === 'rtl';

  const isTabActive = (itemId: ITabsListItem['id']) => currentTabId === itemId;

  const checkActiveTabVisible = () => {
    if (!activeTabRef.current || !navRef.current) {
      return false;
    }
    const activeTabStartPosition = activeTabRef.current.offsetLeft;
    const activeTabEndPosition =
      activeTabRef.current.offsetLeft + activeTabRef.current.offsetWidth;
    const navScrollStartPosition = navRef.current.scrollLeft;
    const navScrollEndPosition =
      navRef.current.scrollLeft + navRef.current.offsetWidth;

    return (
      navScrollEndPosition > activeTabEndPosition &&
      navScrollStartPosition < activeTabStartPosition
    );
  };

  useDidMount(() => {
    const isActiveTabVisible = checkActiveTabVisible();
    if (isActiveTabVisible) {
      handleScrollPosition(0);
    } else {
      activeTabRef.current?.scrollIntoView();
    }
  });

  // Scroll to active tab after selection
  useDidUpdate(() => {
    const isActiveTabVisible = checkActiveTabVisible();
    if (!isActiveTabVisible) {
      activeTabRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [currentTabId]);

  // Returns boundaries of TabList scroll
  const getMaxMinScroll = () => {
    if (!navRef.current) {
      return { max: 0, min: 0 };
    }

    const { scrollWidth, offsetWidth } = navRef.current;
    let max = scrollWidth - offsetWidth;
    let min = 0;

    if (isRtl) {
      min = -1 * max;
      max = 0;
    }

    return { max, min };
  };

  const getBufferWidth = () => {
    if (!navRef.current) {
      return 0;
    }

    const { offsetWidth } = navRef.current;
    return offsetWidth / 50;
  };

  // Sets the visibility of forward and backwards button to match whether there's more content
  const handleScrollPosition = (scrollPosition: number) => {
    const { min, max } = getMaxMinScroll();
    const buffer = getBufferWidth();
    const canScrollBackwards = scrollPosition > min + buffer;
    const canScrollForwards = scrollPosition + buffer < max;
    setShouldShowBackButton(canScrollBackwards);
    setShouldShowForwardButton(canScrollForwards);
  };

  /**
   * @param scrollDirection {Number} 1 if moving forward or -1 if moving backwards
   * When pressing back or forward button, scrolls TabList toward the received direction (with animation)
   */
  const handleScrollButton = (scrollDirection: number) => {
    if (!navRef.current) {
      return;
    }
    const { min, max } = getMaxMinScroll();
    const { scrollLeft, clientWidth } = navRef.current;
    const rtlDirectionFix = isRtl ? -1 : 1;
    const scrollDistance =
      rtlDirectionFix * scrollDirection * (clientWidth / 2);
    const scrollPosition = clamp(scrollLeft + scrollDistance, min, max);

    animateElementByProp({
      propToAnimate: 'scrollLeft',
      element: navRef.current,
      moveTo: scrollPosition,
      duration: 400,
    });
  };

  const handleScrollForward = () => {
    handleScrollButton(ScrollDirection.FORWARD);
  };
  const handleScrollBackward = () => {
    handleScrollButton(ScrollDirection.BACKWARD);
  };
  const onScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollLeft } = event.currentTarget;
    debouncedHandelScrollPosition(scrollLeft);
  };

  // Debounce scroll handling to reduce re-renders
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandelScrollPosition = useCallback(
    debounce(handleScrollPosition, 100),
    [],
  );

  const isScrollMode = menuMode === MenuMode.scroll;

  return (
    <div className={classes.root} dir={itemsDirection}>
      {isScrollMode && (
        <ScrollBtn
          onClick={handleScrollBackward}
          className={st(classes.tabsScrollBtn, {
            hidden: !shouldShowBackButton,
          })}
          dataHook={TestIds.BackwardScrollBtn}
        >
          {isRtl ? <ChevronRight /> : <ChevronLeft />}
        </ScrollBtn>
      )}
      <div
        className={classes.tabsList}
        ref={navRef}
        data-hook={TestIds.TabsList}
        onScroll={onScroll}
      >
        {tabItems?.map((item, index) => {
          const isActive = isTabActive(item.id);
          return (
            <TabsListItem
              {...item}
              isActive={isActive}
              key={`${item.label}-${index}`}
              onClick={() => onTabClick(item.id)}
              ref={isActive ? activeTabRef : undefined}
            />
          );
        })}
      </div>
      <div className={classes.border} />

      {isScrollMode && (
        <ScrollBtn
          onClick={handleScrollForward}
          className={st(classes.tabsScrollBtn, {
            hidden: !shouldShowForwardButton,
          })}
          dataHook={TestIds.ForwardScrollBtn}
        >
          {isRtl ? <ChevronLeft /> : <ChevronRight />}
        </ScrollBtn>
      )}
    </div>
  );
};

export default TabsList;
