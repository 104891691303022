import React from 'react';
import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import { usePreviewState } from '@wix/editor-elements-preview-utils';
import {
  IComponentPreviewWrapper,
  PreviewTooltipProps,
} from '@wix/editor-elements-types';

import { ILoginSocialBarProps } from '../LoginSocialBar.types';
import menuStyles from './components/loginSocialBarCustomMenu/LoginSocialBarCustomMenu.scss';
import loggedInStyles from './components/loggedInSocialBar/LoggedInSocialBar.scss';
import loggedOffStyles from './components/loggedOffSocialBar/LoggedOffSocialBar.scss';

function withComponentPreview(
  WrappedComponent: React.ComponentType<
    ILoginSocialBarProps & PreviewTooltipProps
  >,
): IComponentPreviewWrapper<ILoginSocialBarProps & PreviewTooltipProps> {
  return ({ previewWrapperProps = {}, ...viewerProps }) => {
    const { compPreviewState } = previewWrapperProps;

    usePreviewState(viewerProps.id, compPreviewState, {
      'loggedInMenuOpened selected': {
        selectors: `.${menuStyles.link}`,
        type: 'plural',
      },
      'loggedInMenuOpened hover': {
        selectors: [
          `.${loggedInStyles.user}`,
          `.${loggedOffStyles.login}`,
          `.${menuStyles.link}`,
        ],
        type: 'plural',
      },
    });

    const onLogout = (event: React.MouseEvent<Element, MouseEvent>) => {
      event.stopPropagation();
      viewerProps.showTooltip(
        event.currentTarget.getBoundingClientRect(),
        'PREVIEW_TOOLTIP_GOTO_LIVE_SITE',
        {},
      );
    };

    return <WrappedComponent {...viewerProps} onLogout={onLogout} />;
  };
}

export default (
  ViewerComponent: React.ComponentType<
    ILoginSocialBarProps & PreviewTooltipProps
  >,
) => createComponentPreviewEntry(withComponentPreview(ViewerComponent));
