
  var rt = require("@stylable/runtime/cjs/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "PaginationStrip206008318",
      {"classes":{"root":"PaginationStrip206008318__root","pageStripInner":"PaginationStrip206008318__pageStripInner","pageStripTemplate":"PaginationStrip206008318__pageStripTemplate","pageButtonCommon":"PaginationStrip206008318__pageButtonCommon","currentPage":"PaginationStrip206008318__currentPage","gapLabel":"PaginationStrip206008318__gapLabel","pageButton":"PaginationStrip206008318__pageButton"},"vars":{},"stVars":{"bRadius":"var(--bRadius, 100px)","shadow":"var(--shadow, none)","pageNumbersTextColor":"rgba(var(--pageNumbersTextColor, var(--color_6)), var(--alpha-pageNumbersTextColor, 1))","selectedPageBG":"rgba(var(--selectedPageBG, var(--color_22)), var(--alpha-selectedPageBG, 1))","selectedPageBorderColor":"rgba(var(--selectedPageBorderColor, var(--color_26)), var(--alpha-selectedPageBorderColor, 1))","selectedPageBorderWidth":"var(--selectedPageBorderWidth, 0)","selectedPageTextColor":"rgba(var(--selectedPageTextColor, var(--color_25)), var(--alpha-selectedPageTextColor, 1))"},"keyframes":{}},
      "",
      -1,
      module.id
  );
  