import { useEffect } from 'react';
import { usePrevious } from '../usePrevious';

export type usePreviewEffectOptions = {
  componentViewMode?: string;
  onPreviewViewMode?: () => void;
};

// Runs a callback whenever componentViewMode changes from non-preview to preview
export function usePreviewEffect({
  componentViewMode,
  onPreviewViewMode,
}: usePreviewEffectOptions) {
  const previousViewMode = usePrevious(componentViewMode);

  useEffect(() => {
    if (previousViewMode !== 'preview' && componentViewMode === 'preview') {
      onPreviewViewMode?.();
    }
  }, [previousViewMode, onPreviewViewMode, componentViewMode]);
}
