import { throttle } from '@wix/thunderbolt-elements/dist/core/commons/utils';
import { PositionUpdaters } from './types';

const getAlign = (positionBox: HTMLElement): string =>
  window
    .getComputedStyle(positionBox.firstChild as HTMLElement)
    .getPropertyValue('--flyoutAlignment')
    .trim();

const setStyle = (
  positionBox: HTMLElement,
  key: 'top' | 'left' | 'minWidth',
  value: number,
): void => {
  positionBox.style[key] = `${Math.round(value)}px`;
};

const setSubmenuPosition = (
  label: HTMLElement,
  positionBox: HTMLElement,
): void => {
  const { bottom, left, width } = label.getBoundingClientRect();

  // setting position box right under menu item
  setStyle(positionBox, 'top', bottom);
  // width should be same as the parent item or larger
  setStyle(positionBox, 'minWidth', width);

  const boxWidth = positionBox.offsetWidth;

  const align = getAlign(positionBox);

  if (align === 'center') {
    // center aligned is always center aligned
    setStyle(positionBox, 'left', left + (width - boxWidth) / 2);
    return;
  }

  // align should be reverted if there is not enough space for element to render
  const shouldAlignLeft =
    align === 'right'
      ? left + width - boxWidth < 0 // left edge is out of viewport
      : left + boxWidth <= document.body.offsetWidth; // right edge is in the viewport

  setStyle(
    positionBox,
    'left',
    shouldAlignLeft ? left : left + width - boxWidth,
  );
};

export const depth0PositionUpdaters_flyout: PositionUpdaters = ({
  label,
  positionBox,
}) => {
  let throttledSetPosition = () => {};

  return {
    onEnter: () => {
      throttledSetPosition = throttle(
        () => setSubmenuPosition(label, positionBox),
        50,
      );
      throttledSetPosition();

      window.addEventListener('scroll', throttledSetPosition);
      label.closest('nav')?.addEventListener('scroll', throttledSetPosition);
    },
    onLeave: () => {
      window.removeEventListener('scroll', throttledSetPosition);
      label.closest('nav')?.removeEventListener('scroll', throttledSetPosition);
      positionBox.removeAttribute('style');
    },
  };
};
