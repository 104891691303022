import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import { ImageXDefinition, ImageXProps } from '@wix/thunderbolt-components';
import { ImageXScroll } from '@wix/thunderbolt-components/dist/components/ImageX/ImageX';
import { COMP_SELECTOR_PLACEHOLDER } from '../constants';
import { props as viewerPropsMapper } from './ImageX.mapper';

const parallaxImageHeight = (parallaxSpeed: number) =>
  `${parallaxSpeed * 100}%`;

const getEditorScrollEffectStyles = (
  scrollEffect: ImageXScroll,
  parallaxSpeed: number,
): string => {
  switch (scrollEffect) {
    case 'parallax':
      return (
        `${COMP_SELECTOR_PLACEHOLDER} wix-bg-media {top: 0; position: absolute; width: 100%; height: 100%;} ` +
        `${COMP_SELECTOR_PLACEHOLDER} img {top: 0; position: absolute; height: ${parallaxImageHeight(
          parallaxSpeed,
        )};}`
      );
    case 'fixed':
      return (
        `${COMP_SELECTOR_PLACEHOLDER} wix-bg-media {top: 0; position: absolute; width: 100%; left: 0; height: 100vh;} ` +
        `${COMP_SELECTOR_PLACEHOLDER} img {top: 0; position: absolute; height: 100%;}`
      );
    default:
      return (
        `${COMP_SELECTOR_PLACEHOLDER} wix-bg-media {top: 0; position: absolute; width: 100%; height: 100%;} ` +
        `${COMP_SELECTOR_PLACEHOLDER} img {top: 0; position: absolute; height: 100%;}`
      );
  }
};

const props = composeMapper<ImageXProps, ImageXDefinition, ImageXProps>()(
  viewerPropsMapper,
  { add: ['componentViewMode'] },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);
    const { componentViewMode } = deps;

    const scrollEffectStyles =
      componentViewMode === 'editor'
        ? getEditorScrollEffectStyles(
            viewerProps.imageInfo.imageData.scrollEffect,
            viewerProps.imageInfo.parallaxSpeed,
          )
        : viewerProps.scrollEffectStyles;

    return {
      ...viewerProps,
      scrollEffectStyles,
    };
  },
);

export default createComponentPreviewMapperModel({ props });
