import {
  createComponentMapperModel,
  withCompInfo,
} from '@wix/editor-elements-integrations';
import type { GalleryItem } from '@wix/editor-elements-types';

import {
  ProGalleryDefinition,
  IProGalleryProps,
  IProGallerySdkData,
} from '../ProGallery.types';

export const props = withCompInfo<
  Omit<IProGalleryProps, 'id'>,
  ProGalleryDefinition,
  { itemsProps: GalleryItem }
>()(
  [
    'compData',
    'compProps',
    'compStylableClass',
    'isQaMode',
    'fullNameCompType',
    'viewMode',
    'compLayout',
    'experiments',
    'structure',
  ],
  (data, carmiData) => {
    const {
      compProps,
      compStylableClass,
      isQaMode,
      fullNameCompType,
      viewMode: deviceType,
      compLayout: { width, height },
      structure: { styleId },
      experiments,
    } = data;
    return {
      ...compProps,
      viewMode: 'SITE',
      className: compStylableClass,
      deviceType,
      items: carmiData.itemsProps || [],
      isQaMode,
      fullNameCompType,
      styleId,
      editorLayoutHeight: height,
      editorLayoutWidth: width,
      experiments,
    } as any;
  },
);

/**
 * sdkData is sent to the Velo factory (in API) - needed for gallery utils
 */
export const sdkData = withCompInfo<IProGallerySdkData, ProGalleryDefinition>()(
  ['compData'],
  ({ compData }) => {
    return {
      imageDisplayMode: 'fill', // remove once mot used in EE
      dataId: compData.id,
    };
  },
);

export const css = withCompInfo()([], () => ({}));

export default createComponentMapperModel({ css, props, sdkData });
