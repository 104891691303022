
  var rt = require("@stylable/runtime/cjs/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "itemDepth02233374943",
      {"classes":{"root":"itemDepth02233374943__root","container":"itemDepth02233374943__container","label":"itemDepth02233374943__label","itemWrapper":"itemDepth02233374943__itemWrapper","positionBox":"itemDepth02233374943__positionBox","animationBox":"itemDepth02233374943__animationBox","alignBox":"itemDepth02233374943__alignBox","list":"itemDepth02233374943__list","hideOverflowWhileAnimated":"itemDepth02233374943__hideOverflowWhileAnimated","containerWrapper":"itemDepth02233374943__containerWrapper"},"vars":{"animation-duration":"--itemDepth02233374943-animation-duration"},"stVars":{"columns-count":"var(--columnsAmount)","horizontal-spacing":"var(--horizontalSpacing)","white-space":"var(--white-space)","submenu-box-offset-left":"var(--positionBox-offset-left)","submenu-box-offset-right":"var(--positionBox-offset-right)"},"keyframes":{"expandSubmenu":"itemDepth02233374943__expandSubmenu","hideOverflow":"itemDepth02233374943__hideOverflow"}},
      "",
      -1,
      module.id
  );
  