import { IComponentController } from '@wix/editor-elements-types';
import { ISliderControllerActions } from '../Slider.types';

const mapActionsToProps: IComponentController = ({
  updateProps,
}): ISliderControllerActions => ({
  onChange: value => {
    updateProps({ value });
  },
});

export default mapActionsToProps;
