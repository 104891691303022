import React from 'react';
import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import { PreviewWrapperProps } from '@wix/editor-elements-types';
import type { IPopoverMenuProps } from '../PopoverMenu.props';

export const Selectors = {
  popoverContent: '.root::popoverContent',
  list: '.root::list',
};

export default (ViewerComponent: React.ComponentType<IPopoverMenuProps>) =>
  createComponentPreviewEntry(
    ({
      forceState,
      ...viewerProps
    }: PreviewWrapperProps<
      IPopoverMenuProps & {
        forceState?: { selectionSelector: string };
      },
      IPopoverMenuProps
    >) => {
      const selectionSelector = forceState?.selectionSelector || '';

      // TODO: Open also when editing popover options.
      const shouldForceOpenPopover =
        selectionSelector.startsWith(Selectors.popoverContent) ||
        selectionSelector.startsWith(Selectors.list);

      return (
        <ViewerComponent
          {...viewerProps}
          forceOpenPopover={shouldForceOpenPopover}
        />
      );
    },
  );
