import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import {
  usePreviewEffect,
  usePreviewState,
  usePrevious,
  useResetComponent,
} from '@wix/editor-elements-preview-utils';
import {
  IComponentPreviewWrapper,
  PreviewWrapperProps,
} from '@wix/editor-elements-types';
import React, { useRef } from 'react';
import {
  TimePickerPreviewWrapperProps,
  ITimePickerProps,
  ITimePickerImperativeActions,
} from '../TimePicker.types';
import { getCurrentTimeValue } from '../utils';

const noop = () => {};

function withComponentPreview(
  WrappedComponent: React.ComponentType<ITimePickerProps>,
): IComponentPreviewWrapper<ITimePickerProps, TimePickerPreviewWrapperProps> {
  return React.forwardRef<ITimePickerImperativeActions, ITimePickerProps>(
    (
      {
        previewWrapperProps = {},
        setValidityIndication = noop,
        validateValue = noop,
        ...viewerProps
      }: PreviewWrapperProps<ITimePickerProps, TimePickerPreviewWrapperProps>,
      ref,
    ) => {
      const { shouldResetComponent, compPreviewState, componentViewMode } =
        previewWrapperProps;
      const { value, onValueChange, initialTime } = viewerProps;

      const previousCompViewMode = usePrevious(componentViewMode);
      const defaultValue = useRef(value);

      React.useEffect(() => {
        const shouldInitializeValue = () => {
          const isEnteringPreviewMode =
            componentViewMode === 'preview' &&
            previousCompViewMode !== componentViewMode;
          const isCurrentTime = initialTime === 'current';
          const isEmptyValue = !value;
          // when initial time is "current" in Preview we should show up-to-date current time
          // when TimePicker is rendered.
          // so we initialize current time value when switching to Preview
          return isCurrentTime && isEnteringPreviewMode && isEmptyValue;
        };

        if (shouldInitializeValue()) {
          const currentTime = getCurrentTimeValue();
          onValueChange(currentTime);
          defaultValue.current = currentTime;
        }
      }, [
        onValueChange,
        componentViewMode,
        previousCompViewMode,
        value,
        initialTime,
      ]);

      React.useEffect(() => {
        if (
          previousCompViewMode === 'editor' &&
          componentViewMode === 'editor'
        ) {
          defaultValue.current = value;
        }
      }, [previousCompViewMode, componentViewMode, value]);

      usePreviewEffect({
        componentViewMode: componentViewMode || '',
        onPreviewViewMode: validateValue,
      });

      const key = useResetComponent({
        shouldResetComponent,
        id: viewerProps.id,
        onResetComponent: () => {
          if (viewerProps.initialTime === 'current') {
            defaultValue.current = getCurrentTimeValue();
          }

          onValueChange(defaultValue.current);
          setValidityIndication(false);
          validateValue();
        },
      });

      usePreviewState(viewerProps.id, compPreviewState);

      return (
        <WrappedComponent
          {...viewerProps}
          setValidityIndication={noop}
          key={key}
          ref={ref}
        />
      );
    },
  );
}

export default (ViewerComponent: React.ComponentType<ITimePickerProps>) =>
  createComponentPreviewEntry(withComponentPreview(ViewerComponent));
