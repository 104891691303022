
  var rt = require("@stylable/runtime/cjs/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "RatingsInputcomponent2840320257",
      {"classes":{"root":"RatingsInputcomponent2840320257__root","icons":"RatingsInputcomponent2840320257__icons","labelsContainer":"RatingsInputcomponent2840320257__labelsContainer","label":"RatingsInputcomponent2840320257__label","title":"RatingsInputcomponent2840320257__title"},"vars":{"display":"--display"},"stVars":{"labelFont":"var(--labelFont, var(--font_8))","labelFontColor":"rgba(var(--labelFontColor, var(--color_15)), var(--alpha-labelFontColor, 1))","errorLabelFontColor":"rgba(var(--errorLabelFontColor, var(--color_17)), var(--alpha-errorLabelFontColor, 1))","inputFlexDirection":"var(--inputFlexDirection, column)","inputTextAlign":"var(--inputTextAlign, center)","inputAlignItems":"var(--inputAlignItems, center)","iconsOrder":"var(--iconsOrder, 2)","iconsMinWidth":"var(--iconsMinWidth, auto)","iconsMarginTop":"var(--iconsMarginTop, 0)","iconsMarginBottom":"var(--iconsMarginBottom, 0)","iconsMarginLeft":"var(--iconsMarginLeft, 0)","iconsMarginRight":"var(--iconsMarginRight, 0)","labelsContainerAlignItems":"var(--labelsContainerAlignItems, flex-end)","iconPaddingLeft":"var(--iconPaddingLeft, 0)","iconPaddingRight":"var(--iconPaddingRight, 0)","direction":"var(--direction, ltr)"},"keyframes":{}},
      "",
      -1,
      module.id
  );
  