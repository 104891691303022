import { IComponentController } from '@wix/editor-elements-types';
import { ICheckboxControllerActions } from '../Checkbox.types';

const mapActionsToProps: IComponentController = ({
  updateProps,
}): ICheckboxControllerActions => ({
  onCheckedChange: (isChecked: boolean) => {
    updateProps({
      checked: isChecked,
    });
  },
  setValidityIndication: (shouldShowValidityIndication: boolean) => {
    updateProps({
      shouldShowValidityIndication,
    });
  },
});

export default mapActionsToProps;
