import { IComponentController } from '@wix/editor-elements-types';
import { PayPalButtonStateRefs } from '../PayPalButton.types';

const compController: IComponentController<PayPalButtonStateRefs> = {
  mapStateToProps: ({ getVisitorId, userId }) => ({
    getVisitorId,
    userId,
  }),
};

export default compController;
