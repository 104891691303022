import {
  withCompPreviewProps,
  createComponentPreviewMapperModel,
} from '@wix/editor-elements-integrations';
import {
  ExpandableMenuProps,
  ExpandableMenuDefinition,
} from '../ExpandableMenu.types';
import { forceOpenMenu } from './utils/forceOpenMenu';

const props = withCompPreviewProps<
  ExpandableMenuProps,
  ExpandableMenuDefinition,
  ExpandableMenuProps
>()(['compPreviewState'], ({ compPreviewState }, { items, ...carmiData }) => ({
  items: ['regular', 'selected'].includes(compPreviewState as string)
    ? forceOpenMenu(items)
    : items,
  ...carmiData,
}));

export default createComponentPreviewMapperModel({ props } as any);
