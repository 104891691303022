import * as React from 'react';
import {
  SlavePlayer,
  ArtistName,
  TrackName,
  PlayPause,
  Cover,
  ProgressBar,
  TimeStamp,
} from '@wix/soundhead-react';
import {
  IMusicPlayerProps,
  IMusicPlayerImperativeActions,
  IPlayerRef,
} from '../MusicPlayer.types';
import { st, classes } from './style/MusicPlayer.st.css';
import Icon from './Icon';
import { TestHooks } from './constants';

const MusicPlayer: React.ForwardRefRenderFunction<
  IMusicPlayerImperativeActions,
  IMusicPlayerProps
> = (
  {
    id,
    iconId,
    isClassicLayout,
    showCover,
    showArtistName,
    showTrackName,
    showTimeStamp,
    showProgressBar,
    fallbackCover,
    displayTime,
    playClassName,
    pauseClassName,
    onMouseEnter,
    onMouseLeave,
    onPause,
    onPlay,
    onEnded,
    onTimeUpdated,
    ...props
  },
  ref,
) => {
  const playerRef = React.useRef<IPlayerRef>();
  const isPlayingRef = React.useRef<boolean>(false);

  const showTitle = showTrackName || showArtistName;

  const hasOnlyPlay = !showTitle && !showProgressBar && !showTimeStamp;

  const className = st(classes.root, {
    hasProgressBar: showProgressBar,
    hasTitleTimeRow: showTitle && showTimeStamp && !showProgressBar,
    hasSingleRow:
      showTitle !== showProgressBar ||
      (!showTitle && !showProgressBar && showTimeStamp),
    hasOnlyPlay,
    isClassicLayout,
    isIconLayout: !isClassicLayout,
  });

  React.useImperativeHandle(ref, () => ({
    seek: time => playerRef.current && playerRef.current.seekTo(time),
    play: () => playerRef.current && playerRef.current.play(),
    pause: () => playerRef.current && playerRef.current.pause(),
    togglePlay: () => playerRef.current && playerRef.current.togglePlay(),
    stop: () => {
      if (!playerRef.current) {
        return;
      }
      const seekP = playerRef.current.seekTo(0) || Promise.resolve();

      return seekP.then(() => {
        if (!playerRef.current) {
          return;
        }
        return playerRef.current.pause();
      });
    },
  }));

  const onPlayCallback = React.useCallback(() => {
    isPlayingRef.current = true;
    if (onPlay) {
      onPlay({ type: 'onPlay' });
    }
  }, [onPlay]);
  const onPauseCallback = React.useCallback(() => {
    isPlayingRef.current = false;
    if (onPause) {
      onPause({ type: 'onPause' });
    }
  }, [onPause]);
  const onEndedCallback = React.useCallback(() => {
    isPlayingRef.current = false;
    if (onEnded) {
      onEnded({ type: 'onEnded' });
    }
  }, [onEnded]);
  const onTimeUpdatedCallback = React.useCallback(
    currentTime =>
      onTimeUpdated &&
      onTimeUpdated({ type: 'onTimeUpdated', ...{ currentTime } }),
    [onTimeUpdated],
  );

  return (
    <SlavePlayer
      playerId={id}
      onPlay={onPlayCallback}
      onPause={onPauseCallback}
      onEnded={onEndedCallback}
      onTimeUpdated={onTimeUpdatedCallback}
      preload="none"
      {...props}
      playerRef={(player: IPlayerRef) => (playerRef.current = player)}
    >
      <div
        id={id}
        className={className}
        data-hook={TestHooks.player}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {isClassicLayout ? (
          <div className={classes.mainLayoutWrapper}>
            {(showCover || hasOnlyPlay) && (
              <div
                className={classes.coverLayout}
                data-hook={TestHooks.coverLayout}
              >
                {showCover && (
                  <Cover
                    className={classes.cover}
                    dataHook="cover"
                    fallbackCover={fallbackCover}
                  />
                )}
                {hasOnlyPlay && (
                  <div
                    className={classes.playLayout}
                    data-hook={TestHooks.playLayout}
                  >
                    <PlayPause className={classes.playButton} dataHook="play" />
                  </div>
                )}
              </div>
            )}

            {!hasOnlyPlay && (
              <div className={classes.mainLayout}>
                <div className={classes.upperLayout}>
                  {showTitle && showProgressBar && (
                    <div
                      className={classes.titleLayout}
                      data-hook={TestHooks.titleLayout}
                    >
                      {showTrackName && (
                        <TrackName
                          className={classes.title}
                          dataHook="trackName"
                        />
                      )}
                      {showArtistName && (
                        <ArtistName
                          className={classes.title}
                          dataHook="artistName"
                        />
                      )}
                    </div>
                  )}
                </div>

                <div className={classes.lowerLayout}>
                  <div
                    className={classes.playLayout}
                    data-hook={TestHooks.playLayout}
                  >
                    <PlayPause
                      className={classes.playButton}
                      dataHook={TestHooks.play}
                    />
                  </div>
                  {showProgressBar && (
                    <div
                      className={classes.progressLayout}
                      data-hook={TestHooks.progressLayout}
                    >
                      <ProgressBar
                        classNameRangeBar={classes.progressRangeBar}
                        dataHook="progressBar"
                      />
                    </div>
                  )}
                  {!showProgressBar && showTitle && (
                    <div
                      className={classes.titleLayout}
                      data-hook={TestHooks.titleLayout}
                    >
                      {showTrackName && (
                        <TrackName
                          className={classes.title}
                          dataHook="trackName"
                        />
                      )}
                      {showArtistName && (
                        <ArtistName
                          className={classes.title}
                          dataHook="artistName"
                        />
                      )}
                    </div>
                  )}
                  {showTimeStamp && (
                    <TimeStamp
                      className={classes.timeStamp}
                      dataHook="timeStamp"
                      display={displayTime}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className={classes.mainLayoutWrapper}>
            <PlayPause
              className={classes.playPauseBtn}
              dataHook={TestHooks.play}
              iconPlay={
                <Icon type="play" className={playClassName} iconId={iconId} />
              }
              iconPause={
                <Icon type="pause" className={pauseClassName} iconId={iconId} />
              }
            />
          </div>
        )}
      </div>
    </SlavePlayer>
  );
};

export default React.forwardRef(MusicPlayer);
