import { TranslationGetter } from '@wix/editor-elements-types';
import { TRANSLATIONS } from './constants';

export const getAllTranslations = (
  translate: TranslationGetter | undefined,
  isMobile: boolean,
) => {
  const translations = TRANSLATIONS[isMobile ? 'mobile' : 'desktop'];
  const titleText = translate!(
    TRANSLATIONS.feature,
    translations.title.key,
    translations.title.defaultText,
  );
  return {
    titleText,
  };
};
