import * as React from 'react';
import { IResponsiveAppWidgetProps } from '../../../AppWidget.types';
import ResponsiveContainer from '../../../../../thunderbolt-core-components/ResponsiveContainer/viewer/ResponsiveContainer';

const ResponsiveAppWidget: React.FC<IResponsiveAppWidgetProps> = props => {
  const { id, children, containerProps, containerRootClassName = '' } = props;

  return (
    <div id={id} className={containerRootClassName}>
      <ResponsiveContainer {...containerProps}>{children}</ResponsiveContainer>
    </div>
  );
};

export default ResponsiveAppWidget;
