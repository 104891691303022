import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import { PreviewWrapperProps } from '@wix/editor-elements-types';
import {
  RadioGroupProps as RadioGroupPlatformProps,
  RadioGroupDefinition,
} from '@wix/thunderbolt-components';
import { props as viewerPropsMapper } from './RadioGroup.mapper';

const props = composeMapper<
  PreviewWrapperProps<RadioGroupPlatformProps>,
  RadioGroupDefinition,
  RadioGroupPlatformProps
>()(
  viewerPropsMapper,
  { add: ['compPreviewState', 'shouldResetComponent', 'componentViewMode'] },
  (resolver, dependencies, carmiData) => {
    const viewerProps = resolver(dependencies, carmiData);

    const { shouldResetComponent, compPreviewState, componentViewMode } =
      dependencies;

    return {
      ...viewerProps,
      previewWrapperProps: {
        componentViewMode,
        shouldResetComponent,
        compPreviewState,
      },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
