import * as React from 'react';
import {
  IComboBoxInputImperativeActions,
  IComboBoxInputProps,
} from '../ComboBoxInput.types';
import ComboBoxInputBase from './ComboBoxInputBase';
import styles from './style/ComboBoxInput.scss';

const ComboBoxInputSkin: React.ForwardRefRenderFunction<
  IComboBoxInputImperativeActions,
  IComboBoxInputProps
> = (props, ref) => {
  return (
    <ComboBoxInputBase ref={ref} {...props} styles={styles}>
      {({
        id,
        className,
        onDblClick,
        onMouseEnter,
        onMouseLeave,
        content,
      }: {
        id: string;
        className: string;
        onDblClick: React.MouseEventHandler<HTMLDivElement>;
        onMouseEnter: React.MouseEventHandler<HTMLDivElement>;
        onMouseLeave: React.MouseEventHandler<HTMLDivElement>;
        content: any;
      }) => (
        <div
          id={id}
          className={className}
          onDoubleClick={onDblClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {content}
        </div>
      )}
    </ComboBoxInputBase>
  );
};

export default React.forwardRef(ComboBoxInputSkin);
