import {
  createComponentMapperModel,
  withCompInfo,
} from '@wix/editor-elements-integrations';
import {
  MatrixGalleryDefinition,
  MatrixGalleryProps,
  MatrixGalleryCSSVars,
  MatrixGalleryCarmiData,
  MatrixGallerySdkData,
} from '../MatrixGallery.types';

export const props = withCompInfo<
  MatrixGalleryProps,
  MatrixGalleryDefinition,
  MatrixGalleryCarmiData
>()(['skin', 'compProps'], ({ skin, compProps }, carmiData) => {
  const {
    incRows: extraRows,
    maxRows: rows,
    numCols: columns,
    showMoreLabel,
    galleryImageOnClickAction,
    imageMode,
  } = compProps;

  return {
    skin,
    extraRows,
    rows,
    columns,
    items: carmiData.itemsProps,
    showMoreLabel: showMoreLabel || '',
    imageOnClickAction: galleryImageOnClickAction,
    isFitMode:
      imageMode === 'flexibleWidthFixed' && skin !== 'MatrixGalleryCircleSkin',
  };
});

const MIN_HEIGHT = 70;

const showMoreHeightMap = {
  MatrixGalleryDefaultSkin: 25,
  MatrixGalleryLiftedShadow: 25,
  MatrixGallerySloopy: 25,
  MatrixGalleryCircleSkin: 40,
  MatrixGalleryTextSlideUpSkin: 25,
  MatrixGalleryIronSkin: 40,
  MatrixGalleryScotchTapeSkin: 50,
  MatrixGalleryTextOnCenterSkin: 25,
  MatrixGalleryTransparentSkin: 25,
  TextBottomCustomHeightSkin: 25,
  PolaroidCustomHeightSkin: 25,
  MatrixGallerySeparateTextBoxSkin: 40,
  MatrixGalleryPolaroidSkin: 25,
  MatrixGalleryTextBelowSkin: 25,
  BlogMatrixGallery: 25,
  MatrixGalleryVintage: 25,
  PolaroidMatrixGallery: 0,
} as const;

const getRowHeight = (
  galleryHeight: number,
  margin: number,
  rows: number,
  galleryBottomPadding: number,
) => {
  const totalMargins = margin * (rows - 1);

  return Math.max(
    Math.floor((galleryHeight - totalMargins - galleryBottomPadding) / rows),
    0,
  );
};

export const css = withCompInfo<
  MatrixGalleryCSSVars,
  MatrixGalleryDefinition
>()(['compProps', 'compLayout', 'skin'], ({ compProps, compLayout, skin }) => {
  const { height = MIN_HEIGHT } = compLayout;
  const {
    margin,
    maxRows: rows,
    numCols: columns,
    alignText = 'left',
    itemHeight,
  } = compProps;
  const showMoreContainerHeight = showMoreHeightMap[skin];
  const rowHeight =
    itemHeight || getRowHeight(height, margin, rows, showMoreContainerHeight);

  return {
    height: 'auto',
    '--number-of-columns': columns,
    '--spacing': `${margin}px`,
    '--row-height': `${rowHeight}px`,
    '--text-align': alignText,
    '--show-more-container-height': `${showMoreContainerHeight}px`,
  };
});

export const sdkData = withCompInfo<
  MatrixGallerySdkData,
  MatrixGalleryDefinition
>()(['compData'], ({ compData }) => {
  return {
    imageDisplayMode: 'fill',
    dataId: compData.id,
  };
});

export default createComponentMapperModel({
  props,
  css,
  sdkData,
});
