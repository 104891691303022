
  var rt = require("@stylable/runtime/cjs/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "TimePickerStepper2251188822",
      {"classes":{"root":"TimePickerStepper2251188822__root","controller":"TimePickerStepper2251188822__controller","nativeInput":"TimePickerStepper2251188822__nativeInput"},"vars":{},"stVars":{"textFont":"var(--textFont, var(--font_1))","inputPadding":"var(--inputPadding, 0)","textAlign":"var(--textAlign, left)","borderRadius":"var(--borderRadius, 0px)"},"keyframes":{}},
      "",
      -1,
      module.id
  );
  