import React from 'react';
import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import { IComponentPreviewWrapper } from '@wix/editor-elements-types';
import { IStateBoxProps } from '../StateBox.types';
import { ISlideShowContainerImperativeActions } from '../../SlideShowContainer/SlideShowContainer.types';

function withComponentPreview(
  WrappedComponent: React.ComponentType<IStateBoxProps>,
): IComponentPreviewWrapper<IStateBoxProps> {
  return React.forwardRef<ISlideShowContainerImperativeActions, IStateBoxProps>(
    (viewerProps: IStateBoxProps, ref) => {
      return <WrappedComponent {...viewerProps} ref={ref} />;
    },
  );
}

export default (ViewerComponent: React.ComponentType<IStateBoxProps>) =>
  createComponentPreviewEntry(withComponentPreview(ViewerComponent));
