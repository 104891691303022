import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  FreemiumBannerMobileDefinition,
  FreemiumBannerMobilePlatformProps,
  FreemiumBannerMobileCarmiData,
} from '../FreemiumBannerMobile.types';

export const props = withCompInfo<
  FreemiumBannerMobilePlatformProps,
  FreemiumBannerMobileDefinition,
  FreemiumBannerMobileCarmiData
>()([], (_deps, carmiData) => {
  return { ...carmiData, shouldReportBi: true };
});

export default createComponentMapperModel({ props });
