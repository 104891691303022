import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import { PreviewWrapperProps } from '@wix/editor-elements-types';
import {
  LanguageSelectorDefinition,
  LanguageSelectorCSSVars,
} from '@wix/thunderbolt-components';
import { LanguageSelectorWithPreviewProps } from '../LanguageSelector.types';
import mapper from './LanguageSelector.mapper';

const props = composeMapper<
  PreviewWrapperProps<LanguageSelectorWithPreviewProps>,
  LanguageSelectorDefinition,
  LanguageSelectorWithPreviewProps
>()(
  mapper.props,
  {
    add: ['shouldResetComponent', 'componentViewMode', 'compPreviewState'],
  },
  (resolver, dependencies, carmiData) => {
    const viewerProps = resolver(dependencies, carmiData);

    const { shouldResetComponent, compPreviewState, componentViewMode } =
      dependencies;

    const isOpen = compPreviewState === 'open';

    return {
      ...viewerProps,
      isOpen,
      previewWrapperProps: {
        componentViewMode,
        shouldResetComponent,
        compPreviewState,
      },
    };
  },
);

type LanguageSelectorStyles = { 'min-width'?: string; 'min-height'?: string };

const css = composeMapper<
  LanguageSelectorCSSVars,
  LanguageSelectorDefinition,
  LanguageSelectorCSSVars & LanguageSelectorStyles
>()(
  mapper.css,
  {
    add: ['compProps', 'styleProperties'],
  },
  (resolver, dependencies, carmiData) => {
    const viewerCss = resolver(dependencies, carmiData);
    const { compProps, styleProperties } = dependencies;

    const minDimensions = getMinDimensions(compProps, styleProperties);
    const cssProps = {
      ...viewerCss,
      ...(minDimensions
        ? {
            'min-width': `${minDimensions.minWidth}px`,
            'min-height': `${minDimensions.minHeight}px`,
          }
        : {}),
    };

    return cssProps;
  },
);

function getMinDimensions(
  compProps: LanguageSelectorDefinition['property'],
  styleProperties: Record<string, string>,
): { minWidth: number; minHeight: number } | undefined {
  if (compProps.displayMode !== 'dropdown') {
    return;
  }
  const buttonVerticalPadding = 20;
  const squareAspect = 13 / 8;
  const paddings = {
    icon: 6,
    arrow: 34 + 12, // paddings + width
    text: 14,
  };

  const getIconType = () => {
    const { iconType } = compProps.iconSize ? compProps : styleProperties;
    return iconType;
  };

  const getIconSize = () => {
    return compProps.iconSize || parseInt(styleProperties.iconSize, 10);
  };

  const getMinContentHeight = (): number => {
    const { itemFont } = styleProperties;
    const iconSize = getIconSize();
    const matchFontStyle = (fontData: RegExp) =>
      (itemFont.match(fontData) || ['0'])[0];
    const fontSize = parseInt(matchFontStyle(/\d+px/), 10);
    const lineHeight = parseFloat(matchFontStyle(/\d+\.?\d*em/));
    const fontHeight = Math.ceil(fontSize * lineHeight);

    const iconHeight = getIconType() === 'none' ? 0 : iconSize;
    return Math.max(fontHeight, iconHeight) + buttonVerticalPadding;
  };

  const getMinContentWidth = (): number => {
    const iconSize = getIconSize();
    const iconType = getIconType();
    const hasText = compProps.itemFormat !== 'iconOnly';
    const hasIcon = iconType !== 'none';

    const iconMultiplier = iconType === 'circle' ? 1 : squareAspect;
    const iconWidth = iconSize * iconMultiplier;

    const iconOuterWidth = hasIcon ? iconWidth + paddings.icon : 0;
    const textPadding = hasText ? paddings.text : 0;

    return iconOuterWidth + textPadding + paddings.arrow;
  };

  const borderWidthFromTwoSides = parseInt(styleProperties.borderWidth, 10) * 2;

  const minHeight = getMinContentHeight() + borderWidthFromTwoSides;
  const minWidth = getMinContentWidth() + borderWidthFromTwoSides;
  return { minHeight, minWidth };
}

export default createComponentPreviewMapperModel({
  props,
  css,
});
