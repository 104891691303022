import {
  withCompPreviewInfo,
  createComponentPreviewMapperModel,
} from '@wix/editor-elements-integrations';
import {
  PopupContainerDefinition,
  PopupContainerProps as PopupContainerPlatformProps,
} from '@wix/thunderbolt-components';
import { PreviewWrapperProps } from '@wix/editor-elements-types';
import { PopupContainerPreviewProps } from '../PopupContainer.types';

const getFillLayersPreviewProps = (
  carmiData: PopupContainerPlatformProps,
  {
    isPlayingAllowed,
    isMobileView,
    componentViewMode,
    isFullWidthPopup,
  }: {
    isPlayingAllowed: boolean;
    isMobileView: boolean;
    componentViewMode: string;
    isFullWidthPopup: boolean;
  },
) => {
  let { fillLayers } = carmiData;
  if (fillLayers.video) {
    fillLayers = JSON.parse(JSON.stringify(fillLayers));
    fillLayers.video!.videoInfo.autoPlay =
      fillLayers.video!.videoInfo.autoPlay && isPlayingAllowed;
    return {
      ...carmiData,
      fillLayers: {
        ...fillLayers,
        reducedMotion: componentViewMode === 'editor',
      },
      previewWrapperProps: {
        componentViewMode,
        isMobileView,
        autoPlayVideo: fillLayers.video?.videoInfo.autoPlay,
        isPlayingAllowed,
        isFullWidthPopup,
      },
    };
  }
  return {
    ...carmiData,
    previewWrapperProps: {
      componentViewMode,
      isFullWidthPopup,
      isMobileView,
    },
  };
};

const props = withCompPreviewInfo<
  PreviewWrapperProps<PopupContainerPlatformProps, PopupContainerPreviewProps>,
  PopupContainerDefinition,
  PopupContainerPlatformProps
>()(
  ['componentViewMode', 'isMobileView', 'isPlayingAllowed', 'compProps'],
  (
    { componentViewMode, isMobileView, isPlayingAllowed, compProps },
    carmiData: PopupContainerPlatformProps,
  ) => {
    const reactProps = getFillLayersPreviewProps(carmiData, {
      isPlayingAllowed,
      isMobileView,
      componentViewMode,
      isFullWidthPopup: compProps.alignmentType === 'fullWidth',
    });

    return {
      ...reactProps,
      previewWrapperProps: {
        ...reactProps.previewWrapperProps,
        ...compProps,
      },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
