import React from 'react';
import { IFooterContainerProps } from '../../../FooterContainer.types';
import LiftedBottomScreen from '../../../../ScreenWidthContainer/viewer/skinComps/LiftedBottomScreen/LiftedBottomScreen';
import FooterContainer from '../../FooterContainer';

const LiftedBottomScreenFooter: React.FC<Omit<IFooterContainerProps, 'skin'>> =
  props => (
    <FooterContainer {...props} skin={LiftedBottomScreen}></FooterContainer>
  );

export default LiftedBottomScreenFooter;
