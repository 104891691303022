
  var rt = require("@stylable/runtime/cjs/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "Tabs758789917",
      {"classes":{"root":"Tabs758789917__root","multiStateBoxWrapper":"Tabs758789917__multiStateBoxWrapper"},"vars":{"display":"--display"},"stVars":{},"keyframes":{}},
      "",
      -1,
      module.id
  );
  