import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import { PreviewWrapperProps } from '@wix/editor-elements-types';
import {
  HeaderSectionCss,
  HeaderSectionDefinition,
  HeaderSectionProps,
} from '../HeaderSection.types';
import {
  css as viewerCssMapper,
  props as viewerPropsMapper,
} from './HeaderSection.mapper';

const props = composeMapper<
  PreviewWrapperProps<HeaderSectionProps>,
  HeaderSectionDefinition,
  HeaderSectionProps
>()(
  viewerPropsMapper,
  { add: ['componentViewMode'] },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);

    return deps.componentViewMode === 'editor'
      ? {
          ...viewerProps,
          animate: 'none',
          skin: 'RectangleArea',
        }
      : viewerProps;
  },
);

const css = composeMapper<
  HeaderSectionCss,
  HeaderSectionDefinition,
  HeaderSectionCss
>()(viewerCssMapper, {}, (resolver, dependencies, carmiData) =>
  resolver(dependencies, carmiData),
);

export default createComponentPreviewMapperModel({
  props,
  css,
});
