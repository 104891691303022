import { IBreadcrumbMetaData, IBreadcrumbsProps } from './Breadcrumbs.types';

/**
 * home/current items should be ignored during calculations
 * of amount of items which should be rendered
 * in order to preserve position of ellipsis
 *
 * As an example:
 *
 * home/item/current - without ellipsis
 * home/.../current - with ellipsis
 * .../current - homePage hidden
 * home/... - currentPage hidden
 *
 * Same with longer breadcrumbs
 * home/item/.../item/current => item/.../item/current || home/item/.../item
 */

export const getHeadIndex = (
  itemsBeforeCollapse: IBreadcrumbsProps['itemsBeforeCollapse'],
  showHomePage: IBreadcrumbsProps['showHomePage'],
) => itemsBeforeCollapse - Number(!showHomePage);

export const getTailIndex = (
  itemsAfterCollapse: IBreadcrumbsProps['itemsAfterCollapse'],
  showCurrentPage: IBreadcrumbsProps['showCurrentPage'],
) => itemsAfterCollapse - Number(!showCurrentPage);

type CanRenderEllipsis = (
  value: Pick<
    IBreadcrumbsProps,
    | 'itemsBeforeCollapse'
    | 'itemsAfterCollapse'
    | 'showHomePage'
    | 'showCurrentPage'
  > & {
    breadcrumbs: IBreadcrumbsProps['breadcrumbs'] | Array<IBreadcrumbMetaData>;
  },
) => boolean;

export const canRenderEllipsis: CanRenderEllipsis = ({
  itemsBeforeCollapse,
  itemsAfterCollapse,
  breadcrumbs,
  showHomePage,
  showCurrentPage,
}) => {
  /**
   * Even when "props.shouldWrap === false", there is a situation,
   * when ellipsis will not be rendered.
   *
   * And this situation is when we do not have enough items
   */
  const headIndex = getHeadIndex(itemsBeforeCollapse, showHomePage);
  const tailIndex = getTailIndex(itemsAfterCollapse, showCurrentPage);

  return headIndex + tailIndex < breadcrumbs.length;
};
