import {
  withStateRefs,
  createComponentMapperModel,
  withCompInfo,
} from '@wix/editor-elements-integrations';
import { VideoBoxDefinition } from '@wix/thunderbolt-components';
import { VideoBoxProps } from '../VideoBox.types';

export const props = withCompInfo<
  VideoBoxProps,
  VideoBoxDefinition,
  VideoBoxProps
>()([], (_, carmiData) => {
  return carmiData;
});

export const stateRefs = withStateRefs<{ reducedMotion: boolean }>()(
  [],
  ({ refApi }) => ({
    reducedMotion: refApi.env.reducedMotion(),
  }),
);

export default createComponentMapperModel({ props, stateRefs });
