import { LinkProps } from '@wix/thunderbolt-components';
import classNamesFn from 'classnames';
import React from 'react';
import Link, { isValidLink } from '../../Link/viewer/Link';
import { Keys, TestIds } from '../constants';
import { ITinyMenuItemProps } from '../TinyMenu.types';

export type TinyMenuItemProps = Omit<
  ITinyMenuItemProps,
  'textAlignment' | 'link'
> & {
  link?: ITinyMenuItemProps['link'];
  hideSubmenuHeader?: boolean;
  skinsStyle: Record<string, string>;
  onClick?: () => void;
};

const formatDisplayCount = (displayCount: number) =>
  displayCount >= 1000
    ? `${parseInt(`${displayCount / 1000}`, 10)}k`
    : displayCount;

const LinkWithDisplayCount: React.FC<{
  label: string;
  link?: LinkProps;
  classNames: Record<string, string>;
  displayCount?: number;
}> = ({ label, link, classNames, displayCount }) => (
  <Link {...link} className={classNames.root}>
    {label}
    {typeof displayCount === 'number' && (
      <span data-testid={TestIds.counter} className={classNames.counter}>
        ({formatDisplayCount(displayCount)})
      </span>
    )}
  </Link>
);

const LinkWithToggler: React.FC<{
  label: string;
  link: LinkProps;
  isSelected?: boolean;
  skinsStyle: Record<string, string>;
  displayCount?: number;
  dataTestId?: string;
  onToggle: () => void;
  onLinkClick?: () => void;
}> = ({
  label,
  link,
  isSelected,
  skinsStyle,
  onToggle,
  displayCount,
  dataTestId,
}) => {
  const onTogglerClick = (e: React.MouseEvent) => {
    onToggle();

    // Stops the closing event arriving from the item itself - we don't want to close when toggling a submenu
    e.stopPropagation();
  };

  return (
    <>
      <LinkWithDisplayCount
        label={label}
        link={link}
        classNames={{
          root: classNamesFn(skinsStyle.link, isSelected && skinsStyle.current),
          counter: skinsStyle.counter,
        }}
        displayCount={displayCount}
      />
      <span
        data-testid={`${dataTestId}-${TestIds.subMenuTogglerSuffix}`}
        className={classNamesFn(
          skinsStyle.toggler,
          isSelected && skinsStyle.current,
        )}
        onClick={onTogglerClick}
      />
    </>
  );
};

const TinyMenuItem: React.FC<TinyMenuItemProps> = ({
  label,
  link = {},
  items = [],
  isSelected,
  hideSubmenuHeader,
  isCurrentHref,
  displayCount,
  initialIsOpen,
  dataTestId,
  skinsStyle,
  onClick,
}) => {
  const [isOpen, setIsOpen] = React.useState(initialIsOpen);
  const hasItems = items.length > 0;

  const toggleSubMenu = () => setIsOpen(!isOpen);

  const renderLinkWithToggler = () => (
    <LinkWithToggler
      label={label}
      link={link}
      isSelected={isSelected}
      skinsStyle={skinsStyle}
      displayCount={displayCount}
      dataTestId={dataTestId}
      onToggle={toggleSubMenu}
      onLinkClick={onClick}
    />
  );

  return (
    <li
      className={classNamesFn(skinsStyle.item, {
        [skinsStyle.open]: isOpen,
        [skinsStyle.hasChildren]: hasItems,
      })}
      data-testid={`${dataTestId}${
        isSelected ? `-${TestIds.selectedItemSuffix}` : ''
      }`}
      onClick={
        hasItems && (!isValidLink(link) || isCurrentHref)
          ? toggleSubMenu
          : onClick
      }
    >
      {hasItems ? (
        <>
          {hideSubmenuHeader ? (
            renderLinkWithToggler()
          ) : (
            <div
              className={skinsStyle.header}
              onClick={toggleSubMenu}
              data-testid={`${dataTestId}-${TestIds.subMenuHeaderSuffix}`}
            >
              {renderLinkWithToggler()}
            </div>
          )}
          <ul className={skinsStyle.submenu}>
            {items.map(
              (
                {
                  link: subItemLink,
                  label: subItemLabel,
                  displayCount: subItemDisplayCount,
                  isSelected: subItemIsSelected,
                  onClick: onSubItemClick,
                }: ITinyMenuItemProps,
                index: number,
              ) => (
                <li
                  key={`${Keys.subItem}-${index}`}
                  className={skinsStyle.item}
                  data-testid={`${TestIds.subItem}-${index}${
                    subItemIsSelected ? `-${TestIds.selectedItemSuffix}` : ''
                  }`}
                  onClick={onSubItemClick}
                >
                  <LinkWithDisplayCount
                    label={subItemLabel}
                    link={subItemLink}
                    classNames={{
                      root: classNamesFn(
                        skinsStyle.link,
                        subItemIsSelected && skinsStyle.current,
                      ),
                      counter: skinsStyle.counter,
                    }}
                    displayCount={subItemDisplayCount}
                  />
                </li>
              ),
            )}
          </ul>
        </>
      ) : (
        <LinkWithDisplayCount
          label={label}
          link={link}
          classNames={{
            root: classNamesFn(
              skinsStyle.link,
              isSelected && skinsStyle.current,
            ),
            counter: skinsStyle.counter,
          }}
          displayCount={displayCount}
        />
      )}
    </li>
  );
};

export default TinyMenuItem;
