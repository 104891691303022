import {
  withCompPreviewProps,
  createComponentPreviewMapperModel,
} from '@wix/editor-elements-integrations';
import {
  ToggleSwitchDefinition,
  ToggleSwitchProps,
} from '../ToggleSwitch.types';

const props = withCompPreviewProps<ToggleSwitchProps, ToggleSwitchDefinition>()(
  // componentViewMode is a dependency in order to reset props according to initial state in editor.
  ['compData', 'compProps', 'compPreviewState', 'componentViewMode'],
  ({ compData, compProps, compPreviewState }) => {
    // TODO: Replace with real type
    let { checked } = compData as any;
    // TODO: Replace with real type
    const { value } = compData as any;
    // TODO: Replace with real type
    const { isDisabled } = compProps as any;

    if (compPreviewState) {
      if (compPreviewState.includes('checked')) {
        compPreviewState = compPreviewState
          .split(' ')
          .filter(item => item !== 'checked')
          .join(' ');
        checked = true;
      } else {
        checked = false;
      }
    }

    return {
      value,
      checked,
      isDisabled,
      previewWrapperProps: { compPreviewState },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
