import { IComponentController } from '@wix/editor-elements-types';

const mapActionsToProps: IComponentController = ({ updateProps }) => {
  return {
    onValueChange: (value?: Date) => {
      updateProps({
        value,
      });
    },
    isToggle: (isOpen: boolean) => {
      updateProps({
        isOpen,
      });
    },
    setUseTodayAsDefaultValue: (useTodayAsDefaultValue?: boolean) => {
      updateProps({
        useTodayAsDefaultValue,
      });
    },
    setValidityIndication: (shouldShowValidityIndication: boolean) => {
      updateProps({
        shouldShowValidityIndication,
      });
    },
  };
};

export default mapActionsToProps;
