import * as React from 'react';
import SiteButtonContent from '../../SiteButtonContent';
import { SkinButtonProps } from '../SkinButton.types';
import {
  ISiteButtonImperativeActions,
  ISiteButtonProps,
} from '../../../SiteButton.types';
import SiteButton from '../../SiteButton';
import skinsStyle from './styles/skins.scss';

const IronButtonSkin = React.forwardRef<
  ISiteButtonImperativeActions,
  SkinButtonProps
>(
  (
    { wrapperProps, linkProps, a11yProps, elementType, label, onFocus, onBlur },
    ref,
  ) => {
    return (
      <div {...wrapperProps} className={skinsStyle.IronButton}>
        <SiteButtonContent
          linkProps={linkProps}
          a11yProps={a11yProps}
          elementType={elementType}
          className={skinsStyle.link}
          onFocus={onFocus}
          onBlur={onBlur}
          ref={ref}
        >
          <div className={skinsStyle.glow} />
          <div className={`${skinsStyle['screw-tl']} ${skinsStyle.screw}`} />
          <div className={`${skinsStyle['screw-tr']} ${skinsStyle.screw}`} />
          <div className={`${skinsStyle['screw-bt']} ${skinsStyle.screw}`} />
          <div className={`${skinsStyle['screw-br']} ${skinsStyle.screw}`} />
          <span className={skinsStyle.label}>{label}</span>
        </SiteButtonContent>
      </div>
    );
  },
);

const IronButton: React.ForwardRefRenderFunction<
  ISiteButtonImperativeActions,
  Omit<ISiteButtonProps, 'skin'>
> = (props, ref) => <SiteButton {...props} skin={IronButtonSkin} ref={ref} />;

export default React.forwardRef(IronButton);
