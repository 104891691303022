import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { getInputHeightInPx } from '../../../core/commons/inputUtils';
import {
  AddressInputDefinition,
  AddressInputPlatformProps,
  AddressInputCSSVars,
  AddressInputAlignment,
  AddressInputCssCarmiData,
} from '../AddressInput.types';

export const props = withCompInfo<
  AddressInputPlatformProps,
  AddressInputDefinition
>()(
  ['compData', 'compProps', 'language'],
  ({ compData, compProps, language }) => {
    return {
      placeholder: compData.placeholder,
      readOnly: compProps.readOnly,
      required: compProps.required,
      isDisabled: compProps.isDisabled,
      iconVisible: compProps.iconVisible,
      dividerVisible: compProps.dividerVisible,
      country: compData.filter?.country,
      alignment: compProps.alignment,
      lang: language,
      label: compData.label,
      provider: 'atlas',
    };
  },
);

const alignmentCssVars = (
  alignment: AddressInputAlignment,
  labelPadding: number,
) => {
  return {
    '--textAlign': alignment,
    ...(alignment === 'right'
      ? {
          '--dir': 'rtl' as 'rtl',
        }
      : {
          '--dir': 'ltr' as 'ltr',
        }),
    ...(alignment === 'right'
      ? {
          '--labelPadding': `0 ${labelPadding}px 0 0`,
        }
      : alignment === 'center'
      ? {
          '--labelPadding': '0',
        }
      : {
          '--labelPadding': `0 0 0 ${labelPadding}px`,
        }),
  };
};

export const css = withCompInfo<
  AddressInputCSSVars,
  AddressInputDefinition,
  AddressInputCssCarmiData
>()(
  ['styleProperties', 'compProps', 'compLayout', 'isMobileView'],
  ({ styleProperties, compProps, compLayout, isMobileView }, cssVars) => {
    const { labelMargin, labelPadding, alignment } = compProps;

    return {
      ...cssVars,
      '--dropdownOptionPadding': `${styleProperties.dropdownOptionPadding}px`,
      ...alignmentCssVars(alignment, labelPadding),
      '--labelMarginBottom': `${labelMargin}px`,
      '--inputHeight': getInputHeightInPx({
        compProps,
        compLayout,
        isMobileView,
      }),
    };
  },
);

export default createComponentMapperModel({ props, css });
