import * as React from 'react';
import {
  ITimePickerImperativeActions,
  ITimePickerDropdownProps,
} from '../../../TimePicker.types';
import { ReactComponent as FormFieldSpinnerDown } from '../../assets/FormFieldSpinnerDown.svg';
import {
  getAmpmTime,
  getAvailableTimesPerDay,
  getTimePickerInputImperativeHandle,
} from '../../../utils';
import { TEST_IDS } from '../../../constants';
import { st, classes } from './styles/TimePickerDropdown.st.css';

const noop = () => {};

const TimePickerDropdownBase: React.ForwardRefRenderFunction<
  ITimePickerImperativeActions,
  ITimePickerDropdownProps
> = (props, ref) => {
  const {
    compId,
    isDisabled,
    onValueChange,
    useAmPmFormat,
    value,
    placeholder,
    step,
    required,
    onFocus = noop,
    onBlur = noop,
    onChange = noop,
    validateValueAndShowIndication = noop,
    className,
    enabledTimes,
    translate = noop,
  } = props;

  const inputRef = React.useRef<HTMLSelectElement>(null);

  React.useImperativeHandle(ref, () => {
    return getTimePickerInputImperativeHandle(inputRef, translate);
  });

  const _onChange: React.ChangeEventHandler<HTMLSelectElement> = event => {
    onValueChange(event.target.value);
    validateValueAndShowIndication();
    onChange({ type: 'change' } as any);
  };

  const formattedValue = React.useMemo(
    () => (useAmPmFormat ? getAmpmTime(value) : value),
    [value, useAmPmFormat],
  );

  const options = React.useMemo(
    () =>
      getAvailableTimesPerDay(step, enabledTimes).map(({ time, ampmTime }) => (
        <option key={time} value={time}>
          {useAmPmFormat ? ampmTime : time}
        </option>
      )),
    [step, useAmPmFormat, enabledTimes],
  );

  const getDummyOptionValue = React.useMemo(() => {
    if (!value) {
      return '';
    }

    const matchingOption = getAvailableTimesPerDay(step, enabledTimes).find(
      time => time.time === value,
    );
    return matchingOption ? '' : value;
  }, [value, enabledTimes, step]);

  const generateDummyOption = () => [
    <option
      value={getDummyOptionValue}
      disabled
      className={classes.dummyOption}
      key={'dummyOption'}
    ></option>,
  ];

  return (
    <div
      data-testid={TEST_IDS.dropdown}
      aria-label="Time Picker"
      className={st(classes.controller, {}, className)}
    >
      <select
        ref={inputRef}
        id={`input_${compId}`}
        disabled={isDisabled}
        value={value as string}
        onChange={_onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        required={required}
      >
        {generateDummyOption().concat(options)}
      </select>
      <span className={classes[formattedValue ? 'value' : 'placeholder']}>
        {formattedValue || placeholder || ''}
      </span>
      <FormFieldSpinnerDown />
    </div>
  );
};

export default React.forwardRef(TimePickerDropdownBase);
