import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import { PreviewWrapperProps } from '@wix/editor-elements-types';
import {
  IMultiStateBoxPlatformProps,
  MultiStateBoxDefinition,
} from '../MultiStateBox.types';
import { props as viewerPropsMapper } from './MultiStateBox.mapper';
import { getStateToRender } from './utils';

const props = composeMapper<
  PreviewWrapperProps<IMultiStateBoxPlatformProps>,
  MultiStateBoxDefinition,
  IMultiStateBoxPlatformProps
>()(
  viewerPropsMapper,
  { add: ['componentViewMode'] },
  (resolver, dependencies, carmiData) => {
    const viewerProps = resolver(dependencies, carmiData);

    const {
      componentViewMode,
      compData: { defaultStateId, selectedStateId },
      compId,
      structure,
    } = dependencies;

    const isEditing = componentViewMode === 'editor';
    const stateIds = structure.components ?? [];

    return {
      ...viewerProps,
      selectedStateId: getStateToRender({
        defaultStateId,
        selectedStateId,
        stateIds,
        msbId: compId,
        isEditing,
      }),
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
