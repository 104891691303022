import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import { PreviewWrapperProps } from '@wix/editor-elements-types';
import { GalleryViewMode } from '../ProGallery.types';

import { props as viewerPropsMapper } from './ProGallery.mapper';

// TODO - fix types
const props = composeMapper<PreviewWrapperProps<any>, any, any>()(
  viewerPropsMapper,
  { add: ['componentViewMode'] },
  (resolver, dependencies, carmiData) => {
    const viewerProps = resolver(dependencies, carmiData);

    const { componentViewMode } = dependencies;

    const viewMode: GalleryViewMode =
      componentViewMode === 'editor' ? 'EDIT' : 'PREVIEW';

    const allowFullscreen = componentViewMode === 'editor';

    return {
      ...viewerProps,
      viewMode,
      allowFullscreen,
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
