
  var rt = require("@stylable/runtime/cjs/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "SelectionTagsList3624110994",
      {"classes":{"root":"SelectionTagsList3624110994__root","tagList":"SelectionTagsList3624110994__tagList","tag":"SelectionTagsList3624110994__tag","label":"SelectionTagsList3624110994__label","labelAsterisk":"SelectionTagsList3624110994__labelAsterisk","mobile-only":"device1280975229__mobile-only"},"vars":{},"stVars":{"textColor":"rgba(var(--txt), var(--alpha-txt, 1))","backgroundColor":"rgba(var(--bg), var(--alpha-bg, 1))","borderColor":"rgba(var(--brd), var(--alpha-brd, 1))","errorFontColor":"rgba(var(--txt_e), var(--alpha-txt_e, 1))","errorBackgroundColor":"rgba(var(--bg_e), var(--alpha-bg_e, 1))","errorBorderColor":"rgba(var(--brd_e), var(--alpha-brd_e, 1))","hoverFontColor":"rgba(var(--txt_h), var(--alpha-txt_h, 1))","hoverBackgroundColor":"rgba(var(--bg_h), var(--alpha-bg_h, 1))","hoverBorderColor":"rgba(var(--brd_h), var(--alpha-brd_h, 1))","selectedFontColor":"rgba(var(--txt_s), var(--alpha-txt_s, 1))","selectedBackgroundControl":"rgba(var(--bg_s), var(--alpha-bg_s, 1))","selectedBorderColor":"rgba(var(--brd_s), var(--alpha-brd_s, 1))","disabledFontColor":"rgba(var(--txt_d), var(--alpha-txt_d, 1))","disabledBackgroundColor":"rgba(var(--bg_d), var(--alpha-bg_d, 1))","disabledBorderColor":"rgba(var(--brd_d), var(--alpha-brd_d, 1))","labelColor":"rgba(var(--txtlbl, var(--color_15)), var(--alpha-txtlbl, 1))","labelAsteriskColor":"rgba(var(--txtlblrq, var(--color_15)), var(--alpha-txtlblrq, 1))"},"keyframes":{}},
      "",
      -1,
      module.id
  );
  