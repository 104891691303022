import React from 'react';
import { convertToHTML, convertFromHTML } from 'draft-convert';
import {
  ContentBlock,
  EditorState,
  ContentState,
  RawDraftEntity,
} from 'draft-js';
import { isBrowser } from '../../../core/commons/utils';

const contentLength = (editorState: EditorState) => {
  return editorState.getCurrentContent().getPlainText().length;
};

export const isMaxLength = (editorState: EditorState, maxLength: number) => {
  return contentLength(editorState) > maxLength;
};

const truncate = (editorState: EditorState, charCount: number) => {
  const contentState = editorState.getCurrentContent();
  const blocks = contentState.getBlockMap();

  let count = 0;
  let isTruncated = false;
  const truncatedBlocks: Array<ContentBlock> = [];
  blocks.forEach(block => {
    if (!block) {
      return;
    }
    if (!isTruncated) {
      const length = block.getLength();
      if (count + length > charCount) {
        isTruncated = true;
        const truncatedText = block.getText().slice(0, charCount - count);
        const state = ContentState.createFromText(`${truncatedText}`);
        truncatedBlocks.push(state.getFirstBlock());
      } else {
        truncatedBlocks.push(block);
      }
      count += length + 1;
    }
  });

  if (isTruncated) {
    const state = ContentState.createFromBlockArray(truncatedBlocks);
    return EditorState.createWithContent(state);
  }

  return editorState;
};

const toHtmlConfiguration = {
  styleToHTML: (style: string) => {
    switch (style) {
      case 'BOLD':
        return <span style={{ fontWeight: 'bold' }} />;
      case 'ITALIC':
        return <span style={{ fontStyle: 'italic' }} />;
      case 'UNDERLINE':
        return <span style={{ textDecoration: 'underline' }} />;
      default:
        return;
    }
  },
  blockToHTML: (block: ContentBlock) => {
    // @ts-ignore
    const textAlign = block.data && block.data.textAlignment;
    const createTag = (Type: string) => ({
      // @ts-ignore
      element: <Type style={{ textAlign }} />,
      // @ts-ignore
      empty: <Type style={{ textAlign }}>&nbsp;</Type>,
    });
    const createListItemTag = (Type: string) => ({
      element: <li style={{ textAlign }} />,
      nest: <Type />,
    });

    // @ts-ignore
    switch (block.type) {
      case 'header-two':
        return createTag('h2');
      case 'header-three':
        return createTag('h3');
      case 'unordered-list-item':
        return createListItemTag('ul');
      case 'ordered-list-item':
        return createListItemTag('ol');
      case 'p':
      case 'unstyled':
      default:
        return createTag('p');
    }
  },
  entityToHTML: (entity: RawDraftEntity, text: string) => {
    if (entity.type === 'LINK') {
      let href = entity.data.url;
      if (href.indexOf('http') !== 0) {
        href = 'http://' + href;
      }
      return {
        start: `<span style="text-decoration:underline"><a href=${href} target=${entity.data.target}>`,
        end: '</a></span>',
      };
    }
    return text;
  },
};

const toHtml = convertToHTML(toHtmlConfiguration);

const fixParagraphsContainingOnlyWhiteSpace = (html: string) =>
  html.replace(/<p>\s+<\/p>/g, '<p>\u00a0</p>');

const fromHtmlConfig = {
  htmlToBlock: (nodeName: string, node: HTMLElement) => {
    const ignoreBlocks = ['ul', 'ol'];
    if (ignoreBlocks.indexOf(nodeName) >= 0) {
      return;
    }
    const textAlignment = node.style && node.style.textAlign;
    if (textAlignment) {
      return {
        data: { textAlignment },
      };
    }
    return;
  },
  htmlToEntity: (
    elementName: string,
    element: HTMLElement,
    createEntity: Function,
  ) => {
    if (elementName === 'a' && (element as HTMLAnchorElement).href) {
      return createEntity('LINK', 'MUTABLE', {
        url: element.getAttribute('href'),
        target: element.getAttribute('target'),
        rel: element.getAttribute('rel'),
      });
    }
  },
};

const removeWhiteSpaceBetweenListItemTags = (html: string) =>
  html.replace(/<li>\s+/g, '<li>').replace(/\s+<\/li>/g, '</li>');

const createEditorStateFromHTML = (html: string, maxLength?: number) => {
  let editorState = EditorState.createWithContent(
    // @ts-ignore
    convertFromHTML(fromHtmlConfig)(html),
  );

  if (maxLength) {
    if (isMaxLength(editorState, maxLength)) {
      editorState = truncate(editorState, maxLength);
    }
  }

  return editorState;
};

export const editorStateToHtml = (editorState: EditorState) => {
  return fixParagraphsContainingOnlyWhiteSpace(
    toHtml(editorState.getCurrentContent()),
  );
};

export const htmlToEditorState = (html: string, maxLength?: number) => {
  html = removeWhiteSpaceBetweenListItemTags(html || '');
  // draft-convert does not pass SSR, it uses document to create DOM node, so in SSR we render empty state
  return isBrowser()
    ? createEditorStateFromHTML(html, maxLength)
    : EditorState.createEmpty();
};
