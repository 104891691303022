import { IComponentController } from '@wix/editor-elements-types';
import {
  FileMetaData,
  IFileUploaderControllerActions,
} from '../FileUploader.types';

const mapActionsToProps: IComponentController = ({
  updateProps,
}): IFileUploaderControllerActions => ({
  onSelectedFilesChange: (selectedFiles: Array<FileMetaData>) => {
    updateProps({
      value: selectedFiles,
      uploadStatus: 'Not_Started',
      shouldShowValidityIndication: true,
    });
  },
  resetFileUploader: () => {
    updateProps({
      shouldShowValidityIndication: false,
      value: [],
      uploadStatus: 'Not_Started',
      isInvalidToUpload: false,
    });
  },
});

export default mapActionsToProps;
