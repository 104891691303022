import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import { PreviewWrapperProps } from '@wix/editor-elements-types';
import {
  MenuToggleCarmiProps,
  MenuToggleDefinition,
  MenuTogglePlatformProps,
  MenuToggleCSS,
  MenuToggleCarmiCSS,
} from '../MenuToggle.types';
import {
  props as viewerPropsMapper,
  css as viewerCssMapper,
} from './MenuToggle.mapper';

const props = composeMapper<
  PreviewWrapperProps<MenuTogglePlatformProps>,
  MenuToggleDefinition,
  MenuToggleCarmiProps
>()(
  viewerPropsMapper,
  { add: ['compPreviewState'] },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);
    return {
      ...viewerProps,
      isOpen: deps.compPreviewState
        ? deps.compPreviewState === 'open'
        : viewerProps.isOpen,
      previewWrapperProps: {
        compPreviewState: deps.compPreviewState,
      },
    };
  },
);

type MenuTogglePreviewStyles = MenuToggleCSS & {
  zIndex?: string;
  'margin-left'?: string;
};

const css = composeMapper<
  MenuTogglePreviewStyles,
  MenuToggleDefinition,
  MenuToggleCarmiCSS
>()(
  viewerCssMapper,
  { add: ['shouldResetTinyMenuZIndex', 'compLayout'] },
  (resolver, dependencies, carmiData) => {
    const { compLayout } = dependencies;
    const viewerCss = resolver(dependencies, carmiData);
    const { shouldResetTinyMenuZIndex } = dependencies;
    const styles: MenuTogglePreviewStyles = {};

    if (shouldResetTinyMenuZIndex) {
      styles.zIndex = 'auto';
    }

    if (compLayout.fixedPosition) {
      styles['margin-left'] = 'calc((100% - var(--site-width)) / 2)';
    }

    return { ...styles, ...viewerCss };
  },
);

export default createComponentPreviewMapperModel({
  props,
  css,
});
