import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import { PreviewWrapperProps } from '@wix/editor-elements-types';
import {
  TimePickerPlatformProps,
  TimePickerDefinition,
} from '../TimePicker.types';
import { getCurrentTimeValue } from '../utils';
import {
  props as viewerPropsMapper,
  css as viewerCssMapper,
} from './TimePicker.mapper';

const props = composeMapper<
  PreviewWrapperProps<TimePickerPlatformProps>,
  TimePickerDefinition
>()(
  viewerPropsMapper,
  { add: ['compPreviewState', 'shouldResetComponent', 'componentViewMode'] },
  (resolver, dependencies, carmiData) => {
    const viewerProps = resolver(dependencies, carmiData);

    const { shouldResetComponent, compPreviewState, componentViewMode } =
      dependencies;
    const { initialTime, value } = viewerProps;

    return {
      ...viewerProps,
      value: initialTime === 'current' ? getCurrentTimeValue() : value,
      previewWrapperProps: {
        componentViewMode,
        shouldResetComponent,
        compPreviewState,
      },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
  css: viewerCssMapper,
});
