import { IComponentController } from '@wix/editor-elements-types';
import { ITinyMenuControllerActions } from '../TinyMenu.types';

const mapActionsToProps: IComponentController = ({
  updateProps,
}): ITinyMenuControllerActions => ({
  setIsOpen: (isOpen: boolean) => {
    updateProps({
      isOpen,
    });
  },
});

export default mapActionsToProps;
