import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import {
  QuickActionBarPlatformProps,
  QuickActionBarDefinition,
} from '../QuickActionBar.types';
import { props as viewerPropsMapper } from './QuickActionBar.mapper';

const props = composeMapper<
  QuickActionBarPlatformProps,
  QuickActionBarDefinition,
  QuickActionBarPlatformProps
>()(
  viewerPropsMapper,
  { add: ['compPreviewState'] },
  (resolver, deps, carmiData) => {
    const { compPreviewState } = deps;

    return {
      ...resolver(deps, carmiData),
      previewWrapperProps: { compPreviewState },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
