import { useCallback, useState } from 'react';
import { useSiteStylesObserver } from '@wix/thunderbolt-elements/dist/providers/useSiteStylesObserver/useSiteStylesObserver';

/* rerender component to track when --flyoutAlignment css var was changed */
export const useFlyoutAlignmentUpdate = (shouldObserve: boolean) => {
  const [, setState] = useState(1);
  const incrementState = useCallback(
    () => setState(currentState => currentState + 1),
    [],
  );

  useSiteStylesObserver({
    shouldObserve,
    callback: incrementState,
  });
};
