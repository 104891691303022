import * as React from 'react';

import { ITestCompProps } from '../TestComp.types';
import { st, classes } from './style/TestComp.component.st.css';

const TestComp: React.FC<ITestCompProps> = props => {
  const { id, className, label } = props;

  return (
    // the viewer platform passes the id to the component
    // and uses it to inject css variables scoped for the component
    <div id={id}>
      <div className={st(classes.root, className)}>
        <span className={classes.text}>{label}</span>
      </div>
    </div>
  );
};

export default TestComp;
