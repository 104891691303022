import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';

import {
  RichTextBoxDefinition,
  RichTextBoxProps,
  RichTextBoxCSSVars,
  RichTextCarmiData,
} from '../RichTextBox.types';

const getToolbarPosition = (
  toolbarPosition: RichTextBoxProps['toolbarPosition'],
  isMobileView: boolean,
): RichTextBoxProps['toolbarPosition'] => {
  if (isMobileView) {
    return 'bottom';
  }

  return toolbarPosition;
};

export const props = withCompInfo<RichTextBoxProps, RichTextBoxDefinition>()(
  ['compData', 'compProps', 'isMobileView'],
  ({ compData, compProps, isMobileView }) => {
    const toolbarPosition = getToolbarPosition(
      compProps.toolbarPosition,
      isMobileView,
    );
    const result: RichTextBoxProps = {
      allowLinks: compProps.allowLinks,
      placeholder: compProps.placeholder,
      value: compData.value,
      textAlignment: compProps.textAlignment,
      toolbarPosition,
      readOnly: compProps.readOnly || false,
      isDisabled: compProps.isDisabled || false,
      maxLength: compData.maxLength || undefined,
    };

    return result;
  },
);

export const css = withCompInfo<
  RichTextBoxCSSVars,
  RichTextBoxDefinition,
  RichTextCarmiData
>()(
  ['compProps', 'styleProperties'],
  ({ compProps, styleProperties }, carmiData) => {
    const textPadding =
      compProps.textPadding && compProps.textPadding > 0
        ? compProps.textPadding
        : 0;
    const borderRadius = styleProperties.rd.split(' ');
    // santa-editor default value doesn't include px, so we have to add it :(
    // https://github.com/wix-private/santa-editor/blob/master/editor-add-panel/addPanelSectionsEN/sections/developer/richTextBoxSection.js#L89
    const borderWidth = styleProperties.brw.includes('px')
      ? styleProperties.brw
      : `${styleProperties.brw}px`;

    return {
      '--fontSize': `${carmiData.font.size}px`,
      '--textPadding': `${textPadding}px`,
      '--textPaddingTop': `${textPadding / 2}px`,
      '--toolbar-top-radius': `${borderRadius[0]} ${borderRadius[1]} 0 0`,
      '--toolbar-bottom-radius': `0 0 ${borderRadius[2]} ${borderRadius[3]}`,
      '--brw': borderWidth,
    };
  },
);

export default createComponentMapperModel({ props, css });
