import React from 'react';
import { keyCodes } from '@wix/thunderbolt-elements/src/core/commons/a11y';
import { INavButtonProps } from '../../Pagination.types';
import { st, classes } from './style/NavButton.st.css';

const NavButton: React.FC<INavButtonProps> = ({
  testId,
  className,
  children,
  onChange,
  isDisabled: disabled,
  ariaLabel,
}) => {
  const onKeyDownHandler = (event: React.KeyboardEvent) => {
    const { keyCode } = event;
    if (keyCode === keyCodes.enter || keyCode === keyCodes.space) {
      event.preventDefault(); // prevents viewer from scrolling after keyboard press
      onChange();
    }
  };

  return (
    <a
      data-testid={testId}
      className={st(classes.root, { disabled }, className)}
      aria-label={ariaLabel}
      aria-disabled={disabled}
      {...(!disabled && {
        tabIndex: 0,
        onClick: onChange,
        onKeyDown: onKeyDownHandler,
      })}
    >
      {children}
    </a>
  );
};

export default NavButton;
