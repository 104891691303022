import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  PinterestPinItDefinition,
  PinterestPinItProps,
} from '../PinterestPinIt.types';
import { BUTTON_SIZES } from '../constants';

export const props = withCompInfo<
  PinterestPinItProps,
  PinterestPinItDefinition
>()(
  ['compData', 'compProps', 'topology'],
  ({ compData, compProps, topology }) => {
    const { width, height } =
      BUTTON_SIZES[compProps.counterPosition][compProps.size];
    return {
      uri: compData.uri,
      description: compData.description,
      size: compProps.size,
      color: compProps.color,
      counterPosition: compProps.counterPosition,
      staticMediaUrl: topology.staticMediaUrl,
      width: `${width}px`,
      height: `${height}px`,
    };
  },
);
export default createComponentMapperModel({ props });
