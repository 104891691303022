import * as React from 'react';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import { INumberOfFilesLinkProps } from '../../FileUploader.types';
import {
  translationsNamespace,
  NumberOfFilesTranslationPlaceholder,
  NumberOfErrorsTranslationPlaceholder,
  testIds,
  TRANSLATION_FALLBACKS,
  TRANSLATION_KEYS,
  POPPERS_WRAPPER_ID,
  openFilesPopperKeyCodes,
  closeFilesPopperKeyCodes,
  keyCodesToExcludeFromPreventDefault,
} from '../../constants';
import { usePopper } from '../../../../providers/usePopper/usePopper';
import { useClickOutside } from '../../../../providers/useClickOutside/useClickOutside';
import { FilesPopperContent } from '../FilesPopperContent/FilesPopperContent';
import { ReactComponent as SuccessIcon } from '../assets/successIcon.svg';
import { Loader } from '../Loader/Loader';
import {
  getFileNameId,
  getNumberOfFilesLinkId,
  removePoppersWrapperFromDOM,
} from '../utils';
import { useExpandableListKeyDown } from '../../../../providers/useExpandableListKeyDown/useExpandableListKeyDown';
import style from './style/NumberOfFilesLink.scss';

const noop = () => {};

export const NumberOfFilesLink: React.FunctionComponent<INumberOfFilesLinkProps> =
  ({
    files,
    placeholderLabel,
    translate,
    disabled,
    onFileRemoval,
    onXIconKeyDown,
    shouldShowValidityIndication,
    parentCompId,
    uploadStatus = 'Not_Started',
    externallyOpenPopper,
    externallyClosePopper,
    numFilesLimit,
    filesAlignment,
    filesFont,
    fileType,
    setTextBellowButtonElem,
    updateLiveRegion,
  }) => {
    const isFixedPositionPopper = !!externallyOpenPopper;
    const hasFiles = !!files.length;
    const isPlaceholder = !hasFiles;
    const exceededFilesLimit = files.length > numFilesLimit;
    const placeholder = placeholderLabel || ' ';
    const [isOpen, setOpen] = React.useState(false);
    const [poppersWrapper, setPoppersWrapper] =
      React.useState<HTMLElement | null>();

    const initPoppersWrapper = React.useCallback(() => {
      let PoppersWrapperElement = document.getElementById(POPPERS_WRAPPER_ID);

      if (!PoppersWrapperElement) {
        PoppersWrapperElement = createPoppersWrapper();
        document.body.appendChild(PoppersWrapperElement);
      }

      setPoppersWrapper(PoppersWrapperElement);
    }, []);

    const closePopper = React.useCallback(
      (e?: React.MouseEvent<HTMLDivElement>) => {
        e?.stopPropagation();

        setOpen(false);
        externallyClosePopper?.();

        if (poppersWrapper) {
          removePoppersWrapperFromDOM(poppersWrapper);
          setPoppersWrapper(null);
        }
      },
      [externallyClosePopper, poppersWrapper],
    );

    const openPopper = React.useCallback(
      (e?: React.MouseEvent<HTMLInputElement>) => {
        if (isPlaceholder) {
          return;
        }

        e?.stopPropagation();

        setOpen(true);
        externallyOpenPopper?.();
        initPoppersWrapper();
      },
      [externallyOpenPopper, initPoppersWrapper, isPlaceholder],
    );

    const createPoppersWrapper = () => {
      const el = document.createElement('div');

      el.setAttribute('data-testid', testIds.poppersWrapper);
      el.setAttribute('id', POPPERS_WRAPPER_ID);

      return el;
    };

    React.useEffect(() => {
      return () => {
        const poppersEl = document.getElementById(POPPERS_WRAPPER_ID);

        if (poppersEl) {
          removePoppersWrapperFromDOM(poppersEl);
        }
      };
    }, []);

    React.useEffect(() => {
      if (uploadStatus === 'Failed') {
        openPopper();
      }
    }, [uploadStatus, openPopper]);

    const {
      ref: popperSourceElem,
      setRef: setPopperSourceElem,
      popper,
      setPopper: setPopperTargetElem,
      styles,
      attributes,
    } = usePopper<HTMLInputElement>({
      placement: 'bottom-start',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
        {
          name: 'flip',
          options: {
            boundary: 'clippingParents',
            fallbackPlacements: ['top-start', 'bottom-start'],
            allowedAutoPlacements: ['top-start', 'bottom-start'],
          },
        },
      ],
    });

    useClickOutside([popper], closePopper);

    React.useEffect(() => {
      if (isOpen && poppersWrapper && popper) {
        popper.focus({ preventScroll: true });
      }
    }, [isOpen, poppersWrapper, popper]);

    React.useEffect(() => {
      setTextBellowButtonElem(popperSourceElem);
    }, [setTextBellowButtonElem, popperSourceElem]);

    const numberOfErrors = files.reduce((acc, currentFile) => {
      return currentFile.valid ? acc : acc + 1;
    }, 0);

    const numberOfFilesLinkClassName = classNames(style.numberOfFilesLink, {
      [style.hasFiles]: hasFiles,
      [style.link]: !exceededFilesLimit,
      [style.placeholder]: isPlaceholder,
      [style.error]: numberOfErrors > 0 || exceededFilesLimit,
      [style.fixedPositionPopper]: !!externallyOpenPopper && isOpen,
      [style.centerAligned]: filesAlignment === 'center',
    });

    const getNumberOfSelectedFilesText = () => {
      const isSingleFileSelected = files.length === 1;
      const translatedText = isSingleFileSelected
        ? translate(
            translationsNamespace,
            TRANSLATION_KEYS['FileUploader.SingleSelectedFile'],
            TRANSLATION_FALLBACKS['FileUploader.SingleSelectedFile'],
          )
        : translate(
            translationsNamespace,
            TRANSLATION_KEYS['FileUploader.NumberOfSelectedFiles'],
            TRANSLATION_FALLBACKS['FileUploader.NumberOfSelectedFiles'],
          );

      return isSingleFileSelected
        ? translatedText
        : translatedText.replace(
            NumberOfFilesTranslationPlaceholder,
            `${files.length}`,
          );
    };

    const handleClick: React.MouseEventHandler<HTMLDivElement> = e => {
      e.preventDefault();

      openPopper();
    };

    const getTextBelowButton = () => {
      if (exceededFilesLimit) {
        return translate(
          translationsNamespace,
          TRANSLATION_KEYS['FileUploader.ExceededFilesLimit'],
          TRANSLATION_FALLBACKS[
            'upload_button.validation_error.exceeded_files_limit'
          ],
        ).replace(NumberOfFilesTranslationPlaceholder, `${numFilesLimit}`);
      }
      if (numberOfErrors > 0) {
        return getErrorText();
      } else {
        return getNumberOfSelectedFilesText();
      }
    };

    const getErrorText = () => {
      const numOfErrors = numberOfErrors;
      let translatedText = '';

      if (numOfErrors > 1) {
        translatedText = translate(
          translationsNamespace,
          TRANSLATION_KEYS['FileUploader.MultipleErrors'],
          TRANSLATION_FALLBACKS[
            'upload_button.validation_error.multiple_file_errors'
          ],
        ).replace(NumberOfErrorsTranslationPlaceholder, `${numOfErrors}`);
      } else {
        translatedText = translate(
          translationsNamespace,
          TRANSLATION_KEYS['FileUploader.SingleError'],
          TRANSLATION_FALLBACKS[
            'upload_button.validation_error.single_file_error'
          ],
        );
      }

      return translatedText;
    };

    const { hoveredOptionIndex, onKeyDown: onKeyDownOpenClosePopper } =
      useExpandableListKeyDown({
        isListOpen: isOpen,
        openListFn: openPopper,
        closeListFn: closePopper,
        openListKeyCodes: openFilesPopperKeyCodes,
        closeListKeyCodes: closeFilesPopperKeyCodes,
        keyCodesToExcludeFromPreventDefault,
        initialHoveredOptionIndex: -1,
        listLength: files.length,
        circularMode: true,
        resetOnListClose: true,
        resetOnLengthChange: true,
      });

    const handleOnKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
      onKeyDownOpenClosePopper(e);

      if (hoveredOptionIndex >= 0) {
        onXIconKeyDown(hoveredOptionIndex, e);
      }
    };

    const shouldDisableLiveRegionUpdatesForPopperContent =
      uploadStatus === 'Done';

    React.useEffect(() => {
      if (uploadStatus === 'Done') {
        updateLiveRegion('All files uploadeded');
      }
    }, [uploadStatus, updateLiveRegion]);
    const isFirstRender = React.useRef(true);
    React.useEffect(() => {
      if (isFirstRender.current && popperSourceElem) {
        popperSourceElem?.focus();
        isFirstRender.current = false;
      }
    }, [isFirstRender, popperSourceElem]);

    return (
      <div
        id={getNumberOfFilesLinkId(parentCompId)}
        className={numberOfFilesLinkClassName}
        data-testid={testIds.numberOfFilesLink}
        onClick={exceededFilesLimit ? undefined : handleClick}
        ref={setPopperSourceElem as React.LegacyRef<HTMLDivElement>}
        tabIndex={0}
        aria-haspopup={true}
        aria-expanded={isOpen}
        role={exceededFilesLimit ? 'alert' : 'button'}
        onKeyDown={handleOnKeyDown}
        aria-activedescendant={
          hoveredOptionIndex >= 0
            ? getFileNameId({ parentCompId, fileIndex: hoveredOptionIndex })
            : undefined
        }
      >
        <div
          data-testid={testIds.textBelowButton}
          className={classNames(style.textBelowButton, {
            [style.centerAligned]: filesAlignment === 'center',
          })}
        >
          {isPlaceholder ? placeholder : getTextBelowButton()}
        </div>

        <div
          data-testid={testIds.closedPopperStatusIndicator}
          className={classNames(style.statusIndicator, {
            [style.visible]: !isOpen,
          })}
        >
          <Loader uploadStatus={uploadStatus} />
          <SuccessIcon
            className={classNames(style.successIcon, {
              [style.uploadedSuccessfully]: uploadStatus === 'Done',
            })}
          />
        </div>
        {isOpen &&
          poppersWrapper &&
          createPortal(
            <div
              className={classNames(style.popperWrapper, {
                [style.fixedPositionPopper]: isFixedPositionPopper,
              })}
            >
              <FilesPopperContent
                popperRef={setPopperTargetElem}
                parentCompId={parentCompId}
                popperStyles={styles.popper}
                popperAttributes={attributes.popper}
                closePopper={closePopper}
                files={files}
                disabled={disabled}
                shouldShowValidityIndication={shouldShowValidityIndication}
                onFileRemoval={onFileRemoval}
                uploadStatus={uploadStatus}
                isFixedPositionPopper={isFixedPositionPopper}
                translate={translate}
                filesAlignment={filesAlignment}
                filesFont={filesFont}
                fileType={fileType}
                currentVirtualFocusedItem={
                  hoveredOptionIndex >= 0 ? hoveredOptionIndex : undefined
                }
                updateLiveRegion={
                  shouldDisableLiveRegionUpdatesForPopperContent
                    ? noop
                    : updateLiveRegion
                }
              />
            </div>,
            poppersWrapper,
          )}
      </div>
    );
  };
