import React from 'react';
import { FiveGridLineWrapper } from '../../FiveGridLineWrapper';
import { SkinFiveGridLineProps } from '../SkinFiveGridLine';
import skinsStyle from './ZigzagLineSkin.scss';

const ZigzagLineSkin: React.FC<SkinFiveGridLineProps> = wrapperProps => {
  return (
    <FiveGridLineWrapper {...wrapperProps} className={skinsStyle.root}>
      <div className={skinsStyle.filler} />
      <div className={skinsStyle.zigzag} />
    </FiveGridLineWrapper>
  );
};

export default ZigzagLineSkin;
