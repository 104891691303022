import * as React from 'react';
import { ItunesButtonProps } from '../ItunesButton.types';
import Link from '../../Link/viewer/Link';
import style from './style/ItunesButton.scss';
import { translations, image } from './constants';

const ItunesButton: React.FC<ItunesButtonProps> = props => {
  const { id, language = 'EN', link = {}, translate } = props;
  const imageUrl = image.URL.replace(
    image.languagePlaceholder,
    language.toUpperCase(),
  );
  const translatedAriaLabel = translate!(
    translations.ARIA_LABEL_NAMESPACE,
    translations.ARIA_LABEL_KEY,
    translations.ARIA_LABEL_DEFAULT,
  );

  return (
    <div id={id} className={style.root} title={translations.ARIA_LABEL_DEFAULT}>
      <Link className={style.link} {...link} aria-label={translatedAriaLabel}>
        <img className={style.img} src={imageUrl} alt="" />
      </Link>
    </div>
  );
};

export default ItunesButton;
