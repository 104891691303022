import {
  createComponentMapperModel,
  withStateRefs,
} from '@wix/editor-elements-integrations';
import { WPhotoStateRefs } from '../WPhoto.types';

export const stateRefs = withStateRefs<WPhotoStateRefs>()([], ({ refApi }) => ({
  experiments: refApi.env.experiments(),
}));

export default createComponentMapperModel({ stateRefs });
