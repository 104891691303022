import {
  ContentBlock,
  ContentState,
  EditorState,
  Modifier,
  RichUtils,
  SelectionState,
} from 'draft-js';

const _hasSelectedText = (editorState: EditorState) =>
  !editorState.getSelection().isCollapsed();

const _getSelectedBlock = (editorState: EditorState) => {
  const selection = editorState.getSelection();
  const currentContent = editorState.getCurrentContent();

  // Resolves the current block of the selection
  const anchorKey = selection.getAnchorKey();
  const currentBlock = currentContent.getBlockForKey(anchorKey);

  // Resolves the current block with extra information
  return {
    contentBlock: currentBlock,
    startOffset: selection.getStartOffset(),
    endOffset: selection.getEndOffset(),
    startKey: selection.getStartKey(),
    endKey: selection.getEndKey(),
  };
};

const _removeEntityFromBlock = (
  editorState: EditorState,
  contentBlock: ContentBlock,
  entity: string,
) => {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();

  let selectionWithEntity: SelectionState = selectionState;

  contentBlock.findEntityRanges(
    character => character.getEntity() === entity,
    (start, end) => {
      // Creates a selection state that contains the whole text that's linked to the entity
      selectionWithEntity = selectionState.merge({
        anchorOffset: start,
        focusOffset: end,
      });
    },
  );

  // Removes the linking between the text and entity
  const contentStateWithoutEntity = Modifier.applyEntity(
    contentState,
    selectionWithEntity,
    null,
  );

  const newEditorState = EditorState.push(
    editorState,
    contentStateWithoutEntity,
    'apply-entity',
  );

  return RichUtils.toggleLink(newEditorState, selectionState, null);
};

type callback = (start: number, end: number) => void;

const getLinkEntity = (editorState: EditorState) => {
  if (_hasSelectedText(editorState)) {
    const { contentBlock, startOffset } = _getSelectedBlock(editorState);
    // Finds the entity that's related to the selected text
    return contentBlock.getEntityAt(startOffset);
  }

  return null;
};

const getCurrentEntityKey = (editorState: EditorState) => {
  const selection = editorState.getSelection();
  const anchorKey = selection.getAnchorKey();
  const contentState = editorState.getCurrentContent();
  const anchorBlock = contentState.getBlockForKey(anchorKey);
  // @ts-ignore - inconsistencies of @types with draft-js package
  const offset = selection.anchorOffset;
  // @ts-ignore - inconsistencies of @types with draft-js package
  const index = selection.isBackward ? offset - 1 : offset;
  return anchorBlock.getEntityAt(index);
};

export const findLinkEntities = (
  contentBlock: ContentBlock,
  callback: callback,
  contentState: ContentState,
) => {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'LINK'
    );
  }, callback);
};

export const createLink = (
  editorState: EditorState,
  data: { text: string; url: string; targetNewWindow: boolean },
) => {
  const { text, url, targetNewWindow } = data;
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();
  const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', {
    url,
    targetNewWindow,
  });
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const startPosition = selectionState.getStartOffset();
  const endPosition = startPosition + text.length;

  // A key for the block that containing the start of the selection range
  const blockKey = selectionState.getStartKey();

  // Replaces the content in specified selection range with text
  const contentStateWithText = Modifier.replaceText(
    contentState,
    selectionState,
    text,
  );

  const newSelectionState = new SelectionState({
    anchorOffset: startPosition,
    anchorKey: blockKey,
    focusOffset: endPosition,
    focusKey: blockKey,
  });

  const newEditorState = EditorState.push(
    editorState,
    contentStateWithText,
    'insert-characters',
  );
  return RichUtils.toggleLink(newEditorState, newSelectionState, entityKey);
};

export const getLinkData = (editorState: EditorState) => {
  const contentState = editorState.getCurrentContent();
  const entityKey = getCurrentEntityKey(editorState);
  // @ts-ignore - inconsistencies of @types with draft-js package
  const data = contentState.getEntity(entityKey).get('data');
  return {
    url: data.url,
    targetNewWindow: data.targetNewWindow,
  };
};

export const linkExists = (editorState: EditorState) => {
  return getLinkEntity(editorState) !== null;
};

export const removeLink = (editorState: EditorState) => {
  const { contentBlock, startOffset } = _getSelectedBlock(editorState);
  const entity = contentBlock.getEntityAt(startOffset);

  return _removeEntityFromBlock(editorState, contentBlock, entity);
};

export const hasEntity = (editorState: EditorState, entity: string) => {
  const selection = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const currentKey = contentState
    .getBlockForKey(selection.getStartKey())
    .getEntityAt(selection.getStartOffset());

  if (currentKey) {
    const currentEntity = contentState.getEntity(currentKey);
    // @ts-ignore
    return currentEntity.type === entity;
  }

  return false;
};

export const getSelectedText = (editorState: EditorState) => {
  const { contentBlock, startOffset, endOffset } =
    _getSelectedBlock(editorState);

  return contentBlock.getText().slice(startOffset, endOffset);
};

export const blockHasStyle = (block: ContentBlock, style: string) => {
  return !!block.getInlineStyleAt(0).get(style);
};
