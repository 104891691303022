import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  HeaderContainerDefinition,
  HeaderContainerCarmiProps,
  HeaderContainerCarmiCss,
  HeaderSdkStyles,
} from '../HeaderContainer.types';

export const props = withCompInfo<
  HeaderContainerCarmiProps,
  HeaderContainerDefinition,
  HeaderContainerCarmiProps
>()([], (_, carmiData) => {
  return carmiData;
});

export const css = withCompInfo<
  HeaderContainerCarmiCss,
  HeaderContainerDefinition,
  HeaderContainerCarmiCss
>()([], (_, carmiCss) => {
  return carmiCss;
});

export const sdkData = withCompInfo<
  HeaderSdkStyles,
  HeaderContainerDefinition,
  HeaderSdkStyles
>()([], (_, carmiSdkData) => {
  return carmiSdkData;
});

export default createComponentMapperModel({ props, css, sdkData });
