import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { PreviewWrapperProps } from '@wix/editor-elements-types';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import { CaptchaDefinition, CaptchaPlatformProps } from '../Captcha.types';
import { props as viewerPropsMapper } from './Captcha.mapper';

const props = composeMapper<
  PreviewWrapperProps<CaptchaPlatformProps>,
  CaptchaDefinition
>()(
  viewerPropsMapper,
  {
    add: ['shouldResetComponent'],
  },
  (resolver, dependencies, carmiData) => {
    const { shouldResetComponent } = dependencies;
    const viewerProps = resolver(dependencies, carmiData);

    return {
      ...viewerProps,
      previewWrapperProps: { shouldResetComponent },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
