import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import { PreviewWrapperProps } from '@wix/editor-elements-types';
import { getSocialCompBlockingLayerProps } from '../../../preview-utils/socialComponents';
import {
  DisqusCommentsDefinition,
  DisqusCommentsProps,
} from '../DisqusComments.types';

import { props as viewerPropsMapper } from './DisqusComments.mapper';

const props = composeMapper<
  PreviewWrapperProps<DisqusCommentsProps>,
  DisqusCommentsDefinition
>()(
  viewerPropsMapper,
  { add: ['isSocialInteractionAllowed'] },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);
    return {
      ...viewerProps,
      currentUrl: {
        href: new URL('https://www.wix.com'),
      },
      previewWrapperProps: {
        ...getSocialCompBlockingLayerProps(deps.isSocialInteractionAllowed),
      },
    };
  },
);

export default createComponentPreviewMapperModel({ props });
