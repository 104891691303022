import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import { PreviewWrapperProps } from '@wix/editor-elements-types';
import {
  SingleAudioPlayerDefinition,
  SingleAudioPlayerProps,
} from '../SingleAudioPlayer.types';
import { props as viewerPropsMapper } from './SingleAudioPlayer.mapper';

const props = composeMapper<
  PreviewWrapperProps<SingleAudioPlayerProps>,
  SingleAudioPlayerDefinition
>()(
  viewerPropsMapper,
  { add: ['isPlayingAllowed', 'shouldResetComponent'] },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);
    const { isPlayingAllowed, shouldResetComponent } = deps;
    return {
      ...viewerProps,
      autoPlay: isPlayingAllowed && viewerProps.autoPlay,
      volume: isPlayingAllowed ? viewerProps.volume : 0.5,
      previewWrapperProps: {
        shouldResetComponent,
      },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
