import React from 'react';
import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import {
  IComponentPreviewWrapper,
  PreviewTooltipProps,
} from '@wix/editor-elements-types';

import { LoginButtonProps } from '../LoginButton.types';

export type LoginButtonPreviewProps = LoginButtonProps & PreviewTooltipProps;

function withComponentPreview(
  WrappedComponent: React.ComponentType<LoginButtonProps>,
): IComponentPreviewWrapper<LoginButtonPreviewProps> {
  return ({ showTooltip, ...viewerProps }) => {
    const onLogout = (event: React.MouseEvent<Element, MouseEvent>) => {
      event.stopPropagation();
      showTooltip(
        event.currentTarget.getBoundingClientRect(),
        'PREVIEW_TOOLTIP_GOTO_LIVE_SITE',
        {},
      );
    };

    return <WrappedComponent {...viewerProps} onLogout={onLogout} />;
  };
}

export default (ViewerComponent: React.ComponentType<LoginButtonProps>) =>
  createComponentPreviewEntry(withComponentPreview(ViewerComponent));
