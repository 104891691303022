import * as vectorImage from '@wix/thunderbolt-commons/dist/vectorImage';
import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  StylableButtonDefinition,
  StylableButtonPlatformProps,
  StylableButtonCarmiProps,
  StylableButtonSdkData,
} from '../StylableButton.types';

export const props = withCompInfo<
  StylableButtonPlatformProps,
  StylableButtonDefinition,
  StylableButtonCarmiProps
>()(
  ['isQaMode', 'fullNameCompType'],
  ({ isQaMode, fullNameCompType }, carmiData) => {
    return {
      ...carmiData,
      link: carmiData.link && { ...carmiData.link },
      isQaMode,
      fullNameCompType,
    };
  },
);

export const sdkData = withCompInfo<
  StylableButtonSdkData,
  StylableButtonDefinition
>()(['compData', 'topology'], ({ compData, topology }) => {
  const { mediaRootUrl } = topology;
  const { getSvgBaseUrl } = vectorImage.buildSvgUrlFactory();
  return {
    mediaSvgUrl: getSvgBaseUrl(mediaRootUrl),
    svgId: compData.svgId,
  };
});

export default createComponentMapperModel({
  props,
  sdkData,
});
