import {
  withCompPreviewProps,
  createComponentPreviewMapperModel,
} from '@wix/editor-elements-integrations';
import { CompInfoDependency } from '@wix/editor-elements-types';
import {
  HoneycombCarmiData,
  HoneycombDefinition,
  HoneycombPlatformProps,
} from '../Honeycomb.types';
import { props as viewerPropsMapper } from './Honeycomb.mapper';

const viewerDeps = Object.keys(
  viewerPropsMapper.deps,
) as Array<CompInfoDependency>;

const props = withCompPreviewProps<
  HoneycombPlatformProps,
  HoneycombDefinition,
  HoneycombCarmiData
>()(['isPlayingAllowed', ...viewerDeps], (deps, carmiData) => {
  const viewerProps = viewerPropsMapper.resolver(deps, carmiData);
  return {
    ...viewerProps,
    isPlayingAllowed: deps.isPlayingAllowed,
  };
});

export default createComponentPreviewMapperModel({
  props: props as any,
});
