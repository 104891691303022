import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import { PreviewWrapperProps } from '@wix/editor-elements-types';
import {
  HtmlComponentDefinition,
  HtmlComponentProps,
} from '../HtmlComponent.types';
import { props as viewerPropsMapper } from './HtmlComponent.mapper';

const props = composeMapper<
  PreviewWrapperProps<HtmlComponentProps>,
  HtmlComponentDefinition,
  HtmlComponentProps
>()(
  viewerPropsMapper,
  { add: ['shouldResetComponent'] },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);
    const { shouldResetComponent } = deps;

    return {
      ...viewerProps,
      previewWrapperProps: {
        shouldResetComponent,
      },
    };
  },
);

export default createComponentPreviewMapperModel({ props });
