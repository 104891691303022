import {
  createComponentMapperModel,
  withCompInfo,
} from '@wix/editor-elements-integrations';
import { itemsAlignmentMap, menuModeMapper } from '../constants';
import { ITabsDefinition } from '../documentManagement/Tabs.definition';
import { ITabsCarmiData, ITabsMapperProps } from '../Tabs.types';

export const props = withCompInfo<
  ITabsMapperProps,
  ITabsDefinition,
  ITabsCarmiData
>()(
  ['compData', 'compProps', 'compStylableClass'],
  ({ compData, compProps, compStylableClass }, carmiData) => {
    const { tabItems } = carmiData;
    const { defaultTabId } = compData;

    return {
      ...compData,
      ...compProps,
      className: compStylableClass,
      tabItems,
      currentTabId: defaultTabId,
    };
  },
);

export const css = withCompInfo<any, ITabsDefinition, never>()(
  ['compProps'],
  ({ compProps }) => {
    const {
      containerSpacing,
      itemSpacing,
      itemsAlignment,
      contentAlignment,
      horizontalTabPadding,
      verticalTabPadding,
      menuMode,
      stretchTabsToMenuWidth,
      itemRowSpacing,
    } = compProps;
    return {
      '--tabs-list-wrap': menuModeMapper[menuMode],
      '--tabs-list-items-gap': `${itemSpacing}px`,
      '--tabs-list-container-gap': `${containerSpacing}px`,
      '--tabs-list-items-alignment': itemsAlignmentMap[itemsAlignment],
      '--tabs-list-content-alignment': itemsAlignmentMap[contentAlignment],
      '--tabs-list-horizontal-padding': `${horizontalTabPadding}px`,
      '--tabs-list-vertical-padding': `${verticalTabPadding}px`,
      '--tabs-list-item-flex-grow': stretchTabsToMenuWidth ? '1' : '0',
      '--tabs-list-wrap-row-gap': `${itemRowSpacing}px`,
    };
  },
);

export default createComponentMapperModel({ props, css });
