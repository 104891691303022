import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import { PreviewWrapperProps } from '@wix/editor-elements-types';
import {
  ComboBoxInputDefinition,
  ComboBoxInputPlatformProps,
} from '../ComboBoxInput.types';
import { props as viewerPropsMapper } from './ComboBoxInput.mapper';

const props = composeMapper<
  PreviewWrapperProps<ComboBoxInputPlatformProps>,
  ComboBoxInputDefinition,
  ComboBoxInputPlatformProps
>()(
  viewerPropsMapper,
  {
    add: ['shouldResetComponent', 'compPreviewState', 'componentViewMode'],
  },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);
    const { shouldResetComponent, compPreviewState, componentViewMode } = deps;

    return {
      ...viewerProps,
      previewWrapperProps: {
        shouldResetComponent,
        compPreviewState,
        componentViewMode,
      },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
