import React from 'react';
import classNames from 'classnames';
import { ISpotifyPlayerProps } from '../SpotifyPlayer.types';
import { A11Y_TITLE, TRANSLATION_FEATURE, TRANSLATION_KEY } from '../constants';
import style from './style/SpotifyPlayer.scss';

const SpotifyPlayer: React.FC<ISpotifyPlayerProps> = props => {
  const {
    id,
    iframeSrc,
    width,
    height,
    translate,
    onMouseEnter,
    onMouseLeave,
  } = props;
  const ariaLabel = translate!(
    TRANSLATION_FEATURE,
    TRANSLATION_KEY,
    A11Y_TITLE,
  );

  return (
    <wix-iframe
      id={id}
      title={A11Y_TITLE}
      data-src={iframeSrc}
      aria-label={ariaLabel}
      tabIndex={0}
      style={{ width: `${width}px`, height: `${height + 2}px` }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <iframe
        className={classNames(style.iframe, {
          [style.displayNone]: iframeSrc === null,
        })}
        style={{ width: `${width}px`, height: `${height}px` }}
        title={A11Y_TITLE}
        data-src={iframeSrc}
        scrolling="no"
      />
      <div className={style.placeholder} />
    </wix-iframe>
  );
};

export default SpotifyPlayer;
