
  var rt = require("@stylable/runtime/cjs/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "RatingsInputItemcomponent551530387",
      {"classes":{"root":"RatingsInputItemcomponent551530387__root","hiddenInput":"RatingsInputItemcomponent551530387__hiddenInput","icon":"RatingsInputItemcomponent551530387__icon"},"vars":{"force-state-metadata":"--force-state-metadata"},"stVars":{"filledShapeColor":"rgba(var(--filledShapeColor, var(--color_5)), var(--alpha-filledShapeColor, 1))","emptyShapeColor":"rgba(var(--emptyShapeColor, var(--color_15)), var(--alpha-emptyShapeColor, 1))","shapeBorderColor":"rgba(var(--shapeBorderColor, var(--color_32)), var(--alpha-shapeBorderColor, 1))","shapeBorderWidth":"var(--shapeBorderWidth, 3)","labelFont":"var(--labelFont, var(--font_8))","labelFontColor":"rgba(var(--labelFontColor, var(--color_15)), var(--alpha-labelFontColor, 1))","errorFilledShapeColor":"rgba(var(--errorFilledShapeColor, var(--color_7)), var(--alpha-errorFilledShapeColor, 1))","errorShapeBorderColor":"rgba(var(--errorShapeBorderColor, var(--color_37)), var(--alpha-errorShapeBorderColor, 1))","errorShapeBorderWidth":"var(--errorShapeBorderWidth, 7)","focusEmptyShapeColor":"rgba(var(--focusEmptyShapeColor, 176,171,186), var(--alpha-focusEmptyShapeColor, 1))","focusShapeBorderColor":"rgba(var(--focusShapeBorderColor, 176,171,186), var(--alpha-focusShapeBorderColor, 1))","focusShapeBorderWidth":"var(--focusShapeBorderWidth, 1)","iconSize":"var(--iconSize, auto)"},"keyframes":{}},
      "",
      -1,
      module.id
  );
  