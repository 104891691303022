import { TranslationGetter } from '@wix/editor-elements-types';
import {
  FileType,
  fileTypeToBaseFileType,
} from '@wix/editor-elements-corvid-utils';
import {
  getFileExtension,
  getMaxFileSize,
} from '../../../core/corvid/inputUtils';
import { FileMetaData } from '../FileUploader.types';
import { GeneralErrorTranslation, translationsNamespace } from '../constants';

export const removePoppersWrapperFromDOM = (el: HTMLElement) => el?.remove();

export const translationKeyToPlaceholderData: {
  [key: string]: {
    getValueToReplace: (file: FileMetaData, fileType?: FileType) => string;
    placeholder: string;
  };
} = {
  'upload-button.wpm_error.unsupported_file_extension': {
    getValueToReplace: file => getFileExtension(file.name),
    placeholder: '{extension}',
  },
  'upload-button.wpm_error.mime_type_mismatch': {
    getValueToReplace: file => getFileExtension(file.name),
    placeholder: '{extension}',
  },
  'upload-button.wpm_error.file_size_exceeded_limit': {
    getValueToReplace: (file, fileType) =>
      getMaxFileSize(fileTypeToBaseFileType(fileType as FileType, file.name)),
    placeholder: '{sizeLimit}',
  },
  '': {
    getValueToReplace: () => '',
    placeholder: '',
  },
};

export const invalidKeyToTranslationKey: { [key: string]: string } = {
  fileSizeExceedsLimit: 'wpm_error.file_size_exceeded_limit',
  fileTypeNotAllowed: 'wpm_error.unsupported_file_extension',
};

export const getTranslationKey = (invalidKey: string) => {
  if (!invalidKey) {
    return '';
  }

  return `upload-button.${
    invalidKeyToTranslationKey[invalidKey] || invalidKey
  }`;
};

export const parseErrorMessage = (
  translation: string,
  placeholder: string,
  value: string,
) => translation.replace(placeholder, value);

export const hasPlaceholder = (translation: string) => {
  const placeholders = ['{extension}', '{sizeLimit}'];

  return !!placeholders.find(placeholder => translation.includes(placeholder));
};

export const getFileValidationMessage = (
  file: FileMetaData,
  fileType: FileType,
  translate: TranslationGetter,
) => {
  if (!file || !file.validityInfo || !file.validityInfo.invalidKey) {
    return '';
  }

  const { validityInfo } = file;
  let translation;

  translation = translate(
    translationsNamespace,
    getTranslationKey(validityInfo.invalidKey),
    validityInfo.invalidInfo,
  );

  if (!translation) {
    translation = translate(
      translationsNamespace,
      GeneralErrorTranslation.key,
      GeneralErrorTranslation.fallback,
    );
  } else if (hasPlaceholder(translation)) {
    translation = parseErrorMessage(
      translation,
      translationKeyToPlaceholderData[
        getTranslationKey(validityInfo.invalidKey)
      ].placeholder,
      translationKeyToPlaceholderData[
        getTranslationKey(validityInfo.invalidKey)
      ].getValueToReplace(file, fileType),
    );
  }

  return translation;
};

export const getNumberOfFilesLinkId = (parentCompId: string) =>
  `number-of-files-link_${parentCompId}`;

export const getFileNameId = ({
  parentCompId,
  fileIndex,
}: {
  parentCompId: string;
  fileIndex: number;
}) => `file-name_${fileIndex}_${parentCompId}`;

export const getFileNameErrorTooltipId = (fileNameId: string) =>
  `${fileNameId}_tooltip`;
