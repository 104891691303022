import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import { PreviewWrapperProps } from '@wix/editor-elements-types';
import { getSocialCompBlockingLayerProps } from '../../../preview-utils/socialComponents';
import {
  WTwitterTweetDefinition,
  WTwitterTweetProps,
} from '../WTwitterTweet.types';
import { props as viewerPropsMapper } from './WTwitterTweet.mapper';

const props = composeMapper<
  PreviewWrapperProps<WTwitterTweetProps>,
  WTwitterTweetDefinition
>()(
  viewerPropsMapper,
  { add: ['isSocialInteractionAllowed'] },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);

    return {
      ...viewerProps,
      previewWrapperProps: {
        ...getSocialCompBlockingLayerProps(deps.isSocialInteractionAllowed),
      },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
