import {
  createComponentMapperModel,
  withCompInfo,
} from '@wix/editor-elements-integrations';
import {
  CarouselDefinition,
  TPA3DCarouselPropsPlatformProps,
  CarouselPropsCarmiData,
  CarouselCSSVars,
} from '../TPA3DCarousel.types';
import {
  generateCss,
  generateSdkData,
  propsDependencies,
  propsResolver,
} from '../../TPAGallery/viewer/TPAGalleryMapperUtil';

export const props = withCompInfo<
  TPA3DCarouselPropsPlatformProps,
  CarouselDefinition,
  CarouselPropsCarmiData
>()(propsDependencies, (data, carmiProps) => ({
  ...propsResolver<CarouselDefinition>(
    data,
    carmiProps,
    'Carousel/Carousel.html',
  ),
  currentIndex: 0,
}));

const sdkData = generateSdkData<CarouselDefinition>();

const css = generateCss<CarouselCSSVars, CarouselDefinition>();

export default createComponentMapperModel({ css, props, sdkData });
