import * as React from 'react';
import { TestIds } from '../../constants';
import { ITabsListItem } from '../../Tabs.types';
import { st, classes } from './style/TabsList.st.css';

export type ITabsListItemProps = ITabsListItem & {
  isActive: boolean;
  onClick: () => void;
};

const TabsListItem: React.ForwardRefRenderFunction<
  HTMLDivElement,
  ITabsListItemProps
> = (props, ref) => {
  const { label, id, isActive, onClick } = props;

  return (
    <div
      className={st(classes.tab, { current: isActive })}
      id={`tab-${id}`}
      data-hook={TestIds.TabItem}
      onClick={onClick}
      ref={ref}
    >
      <span data-hook={TestIds.TabItemLabel}>{label}</span>
    </div>
  );
};

export default React.forwardRef(TabsListItem);
