import {
  createComponentPreviewMapperModel,
  withCompPreviewProps,
} from '@wix/editor-elements-integrations';
import {
  IRefComponentProps,
  IRefComponentWrapperProps,
} from '../RefComponent.types';

const props = withCompPreviewProps<
  IRefComponentProps,
  any,
  IRefComponentProps,
  IRefComponentWrapperProps
>()(['structure'], ({ structure }, carmiData: IRefComponentProps) => {
  return {
    ...carmiData,
    previewWrapperProps: {
      firstChildId: structure.components && structure.components[0],
    },
  };
});

export default createComponentPreviewMapperModel({
  props,
});
