import { IComponentController } from '@wix/editor-elements-types';
import { IStateBoxControllerActions } from '../StateBox.types';

const mapActionsToProps: IComponentController = ({
  updateProps,
}): IStateBoxControllerActions => ({
  changeSlide: (slideIndex: number) => {
    updateProps({
      currentSlideIndex: slideIndex,
    });
  },
});

export default mapActionsToProps;
