// TODO: deep import is bad, fix importing from index
import Container from '@wix/thunderbolt-elements/dist/components/Container/viewer/Container';

/**
 * As a rule of thumb, we use functional components, and use React's hooks and forwardRef for more advanced capabillities.
 * To learn more about React components guidelines, read [the code-guidelines](https://bo.wix.com/pages/editor-elements-docs/docs/development/contributing#code-guidelines)
 */
const StretchedContainer = Container;

export default StretchedContainer;
