import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { LinkProps } from '@wix/editor-elements-types';
import {
  ICollapsibleTextMapperProps,
  CollapsibleTextDefinition,
  ButtonAlignment,
} from '../CollapsibleText.types';

const getAlignSelf = (alignButton: ButtonAlignment) =>
  alignButton === 'center'
    ? 'center'
    : alignButton === 'right'
    ? 'flex-end'
    : 'flex-start';

export const props = withCompInfo<
  Partial<ICollapsibleTextMapperProps>,
  CollapsibleTextDefinition,
  { link: LinkProps }
>()(
  ['compData', 'compProps', 'compStylableClass', 'isConnectedToDataBinding'],
  (
    {
      compData,
      compProps,
      compStylableClass: className,
      isConnectedToDataBinding,
    },
    carmiData,
  ) => ({
    ...compData,
    ...(isConnectedToDataBinding ? { tag: 'div' } : {}),
    ...compProps,
    ...carmiData,
    className,
  }),
);

export const css = withCompInfo()(
  ['compProps', 'compLayout'],
  ({ compProps: { maxLines, expandControlSpacing, alignButton } }) => {
    const alignSelf = getAlignSelf(alignButton);
    return {
      height: 'auto',
      '--max-lines': maxLines,
      '--expand-control-spacing': `${expandControlSpacing}px`,
      '--expand-control-align': `${alignSelf}`,
    };
  },
);

export default createComponentMapperModel({
  props,
  css,
});
