import {
  createComponentMapperModel,
  withCompInfo,
  withStateRefs,
} from '@wix/editor-elements-integrations';
import { VideoBoxAudioProps } from '../../VideoBox/VideoBox.types';

export const props = withCompInfo<
  VideoBoxAudioProps,
  any,
  VideoBoxAudioProps
>()([], (_, carmiData) => {
  return carmiData;
});

export const stateRefs = withStateRefs<{ reducedMotion: boolean }>()(
  [],
  ({ refApi }) => ({
    reducedMotion: refApi.env.reducedMotion(),
  }),
);

export default createComponentMapperModel({ props, stateRefs });
