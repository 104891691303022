import { Component } from '@wix/editor-elements-types';
import { DeviceType } from '@wix/thunderbolt-ssr-api';
import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { DatePickerCarmiProps } from '@wix/thunderbolt-components-native';
import {
  DatePickerPlatformProps,
  DatePickerDefinition,
  DatePickerCSSVars,
  DatePickerCssCarmiData,
} from '../DatePicker.types';
import {
  getInputHeightInPx,
  getLabelPadding,
  getRequiredIndicationDisplay,
} from '../../../core/commons/inputUtils';
import { calendarButtonPositionVars } from '../../DatePickerCalendar/viewer/DatePickerCalendar.mapper';

export const isMobileViewOrDevice = (
  isMobileView: boolean,
  deviceType: DeviceType,
) => isMobileView || deviceType === 'Smartphone';

export const props = withCompInfo<
  DatePickerPlatformProps,
  DatePickerDefinition,
  DatePickerCarmiProps
>()(
  [
    'compData',
    'compProps',
    'skin',
    'isMobileView',
    'hasResponsiveLayout',
    'deviceType',
    'experiments',
  ],
  (
    {
      compData,
      compProps,
      skin,
      isMobileView,
      hasResponsiveLayout,
      deviceType,
      experiments,
    },
    carmiProps,
  ) => {
    const { templateId } = carmiProps;
    const {
      placeholder: dataPlaceholder,
      minDate,
      maxDate,
      allowPastDates,
      allowFutureDates,
      disabledDates,
      enabledDateRanges,
      disabledDateRanges,
      disabledDaysOfWeek,
      label,
    } = compData;

    const {
      placeholder: propsPlaceholder,
      defaultTextType,
      isDisabled,
      required,
      readOnly,
      dateFormat,
      dayToStart,
    } = compProps;

    const withCalendarPortal =
      experiments['specs.thunderbolt.DatePickerPortal'] === 'new' ||
      experiments['specs.thunderbolt.DatePickerPortal'] === true;

    const isMobile = isMobileViewOrDevice(isMobileView, deviceType);

    const normalizedSkin = skin || 'DatePickerDefaultSkin';

    return {
      skin: normalizedSkin,
      label,
      placeholder:
        defaultTextType === 'today'
          ? ''
          : dataPlaceholder || propsPlaceholder || '',
      minDate,
      maxDate,
      allowPastDates,
      allowFutureDates,
      disabledDates,
      enabledDateRanges:
        !enabledDateRanges || enabledDateRanges.length === 0
          ? null
          : enabledDateRanges,
      disabledDateRanges: disabledDateRanges || [],
      disabledDaysOfWeek,
      dateFormat,
      useTodayAsDefaultValue: defaultTextType === 'today',
      weekStartDay: dayToStart === 'Sunday' ? 0 : 1,
      readOnly,
      required,
      isDisabled,
      isCompactMode: isMobile,
      isResponsive: hasResponsiveLayout,
      timeZone: null,
      withCalendarPortal,
      templateId,
    };
  },
);

const inputWrapperAlignVar = (
  textAlignment: DatePickerDefinition['property']['textAlignment'],
) => {
  switch (textAlignment) {
    case 'right':
      return {
        '--inputWrapperAlign': 'right' as 'right',
      };
    default:
      return {
        '--inputWrapperAlign': 'left' as 'left',
      };
  }
};

const inputPaddingVar = (
  textAlignment: DatePickerDefinition['property']['textAlignment'],
  textPadding: DatePickerDefinition['property']['textPadding'],
) => {
  switch (textAlignment) {
    case 'right':
      return {
        '--inputPadding': `1px ${textPadding}px 1px 2px`,
      };
    default:
      return {
        '--inputPadding': `1px 2px 1px ${textPadding}px`,
      };
  }
};

const editorSpecificStyling = ({
  compProps,
  compLayout,
  isMobileView,
  hasResponsiveLayout,
}: {
  compProps: DatePickerDefinition['property'];
  compLayout: Component['layout'];
  isMobileView: boolean;
  hasResponsiveLayout: boolean;
}) => {
  if (!hasResponsiveLayout) {
    return {
      '--inputWrapperHeight': getInputHeightInPx({
        compProps,
        compLayout,
        isMobileView,
      }),
      height: 'auto' as 'auto',
    };
  }
  return {};
};

export const getCss = (
  {
    compProps,
    compLayout,
    styleProperties,
    isMobileView,
    hasResponsiveLayout,
  }: {
    compProps: DatePickerDefinition['property'];
    compLayout: Component['layout'];
    styleProperties: Record<string, string>;
    isMobileView: boolean;
    hasResponsiveLayout: boolean;
  },
  cssVars: DatePickerCssCarmiData,
): DatePickerCSSVars => {
  const { textAlignment, textPadding, labelMargin } = compProps;

  return {
    ...cssVars,
    '--textAlign': textAlignment,
    '--dir': (textAlignment === 'right' ? 'rtl' : 'ltr') as 'ltr' | 'rtl',
    '--labelMarginBottom': `${labelMargin}px`,
    '--labelPadding': getLabelPadding(compProps) || 'auto',
    '--requiredIndicationDisplay':
      getRequiredIndicationDisplay(styleProperties),
    ...inputWrapperAlignVar(textAlignment),
    ...inputPaddingVar(textAlignment, textPadding),
    '--inputWrapperAlignItems':
      textAlignment === 'right'
        ? ('flex-end' as const)
        : ('flex-start' as const),
    ...editorSpecificStyling({
      compProps,
      compLayout,
      isMobileView,
      hasResponsiveLayout,
    }),
    ...calendarButtonPositionVars(textAlignment),
  };
};

export const css = withCompInfo<
  DatePickerCSSVars,
  DatePickerDefinition,
  DatePickerCssCarmiData
>()(
  [
    'compProps',
    'compLayout',
    'styleProperties',
    'isMobileView',
    'hasResponsiveLayout',
  ],
  (
    {
      compProps,
      compLayout,
      styleProperties,
      isMobileView,
      hasResponsiveLayout,
    },
    cssVars,
  ) => {
    return getCss(
      {
        compProps,
        compLayout,
        styleProperties,
        isMobileView,
        hasResponsiveLayout,
      },
      cssVars,
    );
  },
);

export default createComponentMapperModel({ props, css });
