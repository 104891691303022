
  var rt = require("@stylable/runtime/cjs/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "RangeSlider1928817616",
      {"classes":{"root":"RangeSlider1928817616__root","container":"RangeSlider1928817616__container","label":"RangeSlider1928817616__label","baseSlider":"RangeSlider1928817616__baseSlider","markLine":"Slider1882423810__markLine","markLabel":"Slider1882423810__markLabel","slider-rail":"RangeSlider1928817616__slider-rail","sliderThumb":"Slider1882423810__sliderThumb","slider-track":"RangeSlider1928817616__slider-track","sliderHandle":"Slider1882423810__sliderHandle"},"vars":{"MinThumbPos":"--MinThumbPos","MaxThumbPos":"--MaxThumbPos","defaultThumbHeight":"--defaultThumbHeight","label-left-padding":"--RangeSlider1928817616-label-left-padding","label-right-padding":"--RangeSlider1928817616-label-right-padding","label-bottom-padding":"--RangeSlider1928817616-label-bottom-padding","rail-percentage":"--RangeSlider1928817616-rail-percentage","thumbHeight":"--RangeSlider1928817616-thumbHeight","thumbWidth":"--RangeSlider1928817616-thumbWidth","halfThumbWidth":"--RangeSlider1928817616-halfThumbWidth","minThumbDeviation":"--RangeSlider1928817616-minThumbDeviation","maxThumbDeviation":"--RangeSlider1928817616-maxThumbDeviation","maxThumbOppositeDeviation":"--RangeSlider1928817616-maxThumbOppositeDeviation"},"stVars":{"sliderMarkDotSize":"3px","sliderMarkLineHeight":"10px","sliderMarkMarginSpace":"7px"},"keyframes":{}},
      "",
      -1,
      module.id
  );
  