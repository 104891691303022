import React, { FC, useMemo } from 'react';
import Container from '@wix/thunderbolt-elements/dist/components/Container/viewer/Container';
import cn from 'classnames';

import PluginIcon from 'wix-ui-icons-common/classic-editor/Plugin';
import { ISlotsPlaceholderProps } from '../SlotsPlaceholder.props';
import classes from './SlotsPlaceholder.scss';

const SlotsPlaceholder: FC<ISlotsPlaceholderProps> = props => {
  const { slots, isHighlighted, isWixBlocks, ...otherProps } = props;
  const slotContent = slots?.slot;
  const slotContentComponent = useMemo(() => () => slotContent, [slotContent]);

  const isEmptySlot = !slotContent;

  if (isEmptySlot && !isHighlighted && !isWixBlocks) {
    return null;
  }

  const containerProps = {
    ...otherProps,
    className: cn({
      [classes.highlighted]: isHighlighted,
      [classes.withContent]: !isEmptySlot && !isWixBlocks,
    }),
  };

  if (isEmptySlot && isWixBlocks) {
    containerProps.className = [containerProps.className, classes.empty].join(
      ' ',
    );
    return (
      <Container {...containerProps}>
        {() => <PluginIcon id="empty-slots-placeholder" />}
      </Container>
    );
  }

  return <Container {...containerProps}>{slotContentComponent}</Container>;
};

export default SlotsPlaceholder;
