import * as React from 'react';
import { sanitizeHTML } from '@wix/thunderbolt-elements/src/core/commons/htmlUtils';
import { ICollapsibleTextCompProps, TagValue } from '../CollapsibleText.types';
import { TestIds } from '../constants';
import { st, classes } from './style/CollapsibleText.component.st.css';
import {
  ExpandControl,
  ExpandControlCompProps,
} from './ExpandControl/ExpandControl';

const noop = () => {};

const CollapsibleText: React.FC<ICollapsibleTextCompProps> = props => {
  const {
    id,
    text,
    className,
    direction,
    showExpandControl,
    expandMode,
    ellipsis,
    wrappingStyle,
    isExpanded,
    tag: TagName = TagValue.P,
    onClick = noop,
    onDblClick = noop,
    onMouseEnter = noop,
    onMouseLeave = noop,
  } = props;

  const shouldShowExpandControl = () =>
    ellipsis && (showExpandControl || expandMode === 'button');
  const textClassName = st(classes.text, {
    collapse: ellipsis && !isExpanded,
    wrappingStyle,
  });

  // Needed for rich-text support
  const Text: React.FC<{ _text: string }> = ({ _text }) =>
    React.createElement(TagName, {
      dangerouslySetInnerHTML: {
        __html: sanitizeHTML(_text),
      },
      className: textClassName,
    });

  return (
    <div
      id={id}
      onClick={onClick}
      onDoubleClick={onDblClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div
        dir={direction}
        className={st(classes.root, {}, className)}
        data-testid={TestIds.viewer.root}
        role="group"
      >
        {shouldShowExpandControl() && (
          <ExpandControl {...(props as ExpandControlCompProps)} />
        )}

        {
          <div>
            <Text _text={text} />
          </div>
        }
      </div>
    </div>
  );
};

export default CollapsibleText;
