import cn from 'classnames';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import Link from '@wix/thunderbolt-elements/dist/components/Link/viewer/Link';
import { testIds } from '../../testIds';
import { getIsCurrentPage } from '../../utils/getIsCurrentPage';
import type { IMenuItemLabelProps } from '../../../StylableHorizontalMenu.types';
import { getLabelClasses } from './styles/getLabelClasses';

const createEventListeners = (setIsHovered: (value: boolean) => void) => {
  const onEnter = () => setIsHovered(true);
  const onLeave = () => setIsHovered(false);
  return {
    onMouseEnter: onEnter,
    onMouseLeave: onLeave,
    onFocusCapture: onEnter,
    onBlurCapture: onLeave,
  };
};

export const MenuItemLabel: React.FC<IMenuItemLabelProps> = ({
  item,
  className,
  withSubItemsClassname,
  currentPageHref,
  depth,
  hasSubSubs,
  positionUpdaters: positionUpdatersMap,
  positionBoxRef,
  children,
  onItemClick,
  onItemDblClick,
  onItemMouseIn,
  onItemMouseOut,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const labelRef = useRef<HTMLAnchorElement | HTMLDivElement | null>(null);

  const { label, link, forceHovered = false } = item;
  const eventListeners = createEventListeners(setIsHovered);
  const isCurrentPage =
    // Provided by Velo API to force the current page to be highlighted/unhighlighted
    item.selected ?? getIsCurrentPage(link, currentPageHref);
  const classes = getLabelClasses({
    depth,
    isHovered,
    isCurrentPage,
    className,
  });

  const handleOnClick = useMemo(
    () =>
      onItemClick
        ? (mouseEvent: React.MouseEvent) => {
            onItemClick?.(mouseEvent, {
              ...item,
              selected: isCurrentPage,
            });
          }
        : undefined,
    [onItemClick, isCurrentPage, item],
  );

  const handleOnDoubleClick = useMemo(
    () =>
      onItemDblClick
        ? (mouseEvent: React.MouseEvent) => {
            onItemDblClick?.(mouseEvent, {
              ...item,
              selected: isCurrentPage,
            });
          }
        : undefined,
    [onItemDblClick, isCurrentPage, item],
  );

  const handleOnMouseIn = useMemo(
    () =>
      onItemMouseIn
        ? (mouseEvent: React.MouseEvent) => {
            onItemMouseIn?.(mouseEvent, {
              ...item,
              selected: isCurrentPage,
            });
          }
        : undefined,
    [onItemMouseIn, isCurrentPage, item],
  );

  const handleOnMouseOut = useMemo(
    () =>
      onItemMouseOut
        ? (mouseEvent: React.MouseEvent) => {
            onItemMouseOut?.(mouseEvent, {
              ...item,
              selected: isCurrentPage,
            });
          }
        : undefined,
    [onItemMouseOut, isCurrentPage, item],
  );

  const positionUpdaters = positionUpdatersMap[depth];
  useEffect(() => {
    if (
      !isHovered ||
      !labelRef.current ||
      !positionBoxRef.current ||
      !positionUpdaters
    ) {
      return;
    }

    const { onEnter, onLeave } = positionUpdaters({
      label: labelRef.current,
      positionBox: positionBoxRef.current,
    });
    onEnter();
    return onLeave;
  }, [isHovered, positionBoxRef, positionUpdaters, item]);

  useEffect(() => {
    setIsHovered(forceHovered);
  }, [forceHovered]);

  // heading should listen events only inside label
  const isHeading = hasSubSubs && depth === 1;

  return (
    <li
      className={cn(classes.itemWrapper, withSubItemsClassname)}
      data-testid={testIds.menuItem(depth)}
      data-item-depth={depth}
      data-is-current={isCurrentPage}
      {...(isHovered && {
        'data-hovered': true,
      })}
      {...(!isHeading && eventListeners)}
    >
      <Link
        {...link}
        {...(isHeading && eventListeners)}
        className={classes.root}
        ref={labelRef}
        onClick={handleOnClick}
        onMouseEnter={handleOnMouseIn}
        onMouseLeave={handleOnMouseOut}
        onDoubleClick={handleOnDoubleClick}
      >
        <div className={classes.container}>
          <span className={classes.label}>{label}</span>
        </div>
      </Link>
      {children}
    </li>
  );
};
