import {
  createComponentPreviewMapperModel,
  withCompInfo,
} from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import {
  StylableButtonDefinition,
  StylableButtonPlatformProps,
  StylableButtonCarmiProps,
} from '../StylableButton.types';
import { props as viewerPropsMapper } from './StylableButton.mapper';

const props = composeMapper<
  StylableButtonPlatformProps,
  StylableButtonDefinition,
  StylableButtonCarmiProps
>()(viewerPropsMapper, { add: [] }, (resolver, deps, carmiData) => {
  const viewerProps = resolver(deps, carmiData);

  return {
    ...viewerProps,
  };
});

// eslint-disable-next-line no-empty-pattern
export const css = withCompInfo()(
  ['compProps'],
  ({ compProps: { isMaxContent } }) => {
    if (isMaxContent) {
      return {
        'min-width': 'max-content',
      };
    }
    return {};
  },
);

export default createComponentPreviewMapperModel({
  props,
  css,
});
