import { IComponentController } from '@wix/editor-elements-types';

const mapActionsToProps: IComponentController = ({
  trackEvent,
  updateProps,
}) => ({
  onTrackEvent(eventName: string, params: Record<string, string>) {
    if (trackEvent) {
      trackEvent({ eventName, params });
    }
  },
  openActionBar: () => {
    updateProps({
      isOpen: true,
    });
  },
  closeActionBar: () => {
    updateProps({
      isOpen: false,
    });
  },
});

export default mapActionsToProps;
