import { composeMapper } from '@wix/editor-elements-preview-utils';
import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { ITabsMapperProps, ITabsCarmiData } from '../Tabs.types';
import { ITabsDefinition } from '../documentManagement/Tabs.definition';
import { props as viewerPropsMapper } from './Tabs.mapper';

const props = composeMapper<
  ITabsMapperProps,
  ITabsDefinition,
  ITabsCarmiData
>()(
  // the component mapper
  viewerPropsMapper,
  // additional dependencies to inject to the preview mapper function
  { add: ['compData', 'componentViewMode'] },
  // preview mapper function
  (resolver, deps, carmiData) => {
    // calculate the props of the mapper
    const viewerProps = resolver(deps, carmiData);
    const { componentViewMode, compData } = deps;
    const { currentTabId } = compData;
    const isEditing = componentViewMode === 'editor';

    return {
      ...viewerProps,
      currentTabId: isEditing ? currentTabId : viewerProps.currentTabId,
    };
  },
);

export default createComponentPreviewMapperModel({ props });
