import { IComponentController } from '@wix/editor-elements-types';
import { ITabsControllerActions } from '../Tabs.types';

const mapActionsToProps: IComponentController = ({
  updateProps,
}): ITabsControllerActions => ({
  onTabClick: currentTabId => {
    updateProps({ currentTabId });
  },
});

export default mapActionsToProps;
