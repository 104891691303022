import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  FreemiumBannerDesktopDefinition,
  FreemiumBannerDesktopPlatformProps,
  FreemiumBannerDesktopCarmiData,
} from '../FreemiumBannerDesktop.types';

export const props = withCompInfo<
  FreemiumBannerDesktopPlatformProps,
  FreemiumBannerDesktopDefinition,
  FreemiumBannerDesktopCarmiData
>()([], (_deps, carmiData) => {
  return carmiData;
});
export default createComponentMapperModel({ props });
