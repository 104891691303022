import { IComponentController } from '@wix/editor-elements-types';
import { IFreemiumBannerMobilesControllerActions } from '../FreemiumBannerMobile.types';
import { biEvents } from './biEvents';

const mapActionsToProps: IComponentController = ({
  reportBi,
}): IFreemiumBannerMobilesControllerActions => ({
  onBannerLoad: () => {
    reportBi(biEvents.ONE_APP_FREE_BANNER_SHOWN, { endpoint: 'ugc-viewer' });
  },
  onBannerClicked: () => {
    reportBi(biEvents.ONE_APP_FREE_BANNER_CLICK, { endpoint: 'ugc-viewer' });
  },
});

export default mapActionsToProps;
