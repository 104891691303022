import {
  IComponentPreviewWrapper,
  PreviewWrapperProps,
  IComponentCustomMeasureImperativeAPI,
} from '@wix/editor-elements-types';

import React from 'react';
import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import {
  IRefComponentProps,
  IRefComponentWrapperProps,
} from '../RefComponent.types';

function withComponentPreview(
  ViewerComponent: React.ComponentType<IRefComponentProps>,
): IComponentPreviewWrapper<IRefComponentProps, IRefComponentWrapperProps> {
  return React.forwardRef<
    IComponentCustomMeasureImperativeAPI,
    PreviewWrapperProps<IRefComponentProps, IRefComponentWrapperProps>
  >(({ previewWrapperProps = {}, ...viewerProps }, ref) => {
    const { id } = viewerProps;
    const { firstChildId } = previewWrapperProps;

    React.useImperativeHandle(
      ref,
      () => ({
        getCustomMeasures: () => ({
          height: {
            [id]: () => {
              return (
                firstChildId &&
                document.getElementById(firstChildId)?.offsetHeight
              );
            },
          },
          width: {
            [id]: () => {
              return (
                firstChildId &&
                document.getElementById(firstChildId)?.offsetWidth
              );
            },
          },
        }),
      }),
      [id, firstChildId],
    );

    return <ViewerComponent {...viewerProps} />;
  });
}

export default (ViewerComponent: React.ComponentType<IRefComponentProps>) =>
  createComponentPreviewEntry(withComponentPreview(ViewerComponent));
