import * as React from 'react';
// TODO: deep import is bad, fix importing from index
import Link from '@wix/thunderbolt-elements/dist/components/Link/viewer/Link';
import {
  CollapsibleTextControllerActions,
  ExpandControlData,
  ExpandControlProps,
} from '../../CollapsibleText.types';
import { st, classes } from '../style/CollapsibleText.component.st.css';
import { TestIds } from '../../constants';

export type ExpandControlCompProps = ExpandControlData &
  ExpandControlProps &
  CollapsibleTextControllerActions;

export const ExpandControl: React.FC<ExpandControlCompProps> = ({
  link,
  linkText,
  expandMode,
  readMoreText,
  readLessText,
  isExpanded,
  setIsExpanded,
}) => {
  switch (expandMode) {
    case 'button':
      return (
        <button
          className={st(classes['expand-control'])}
          onClick={() => setIsExpanded(!isExpanded)}
          data-testid={TestIds.viewer.expand_control}
          aria-pressed={isExpanded}
        >
          {isExpanded ? readLessText : readMoreText}
        </button>
      );
    case 'link':
      return (
        <Link
          {...link}
          className={st(classes['expand-control'])}
          dataTestId={TestIds.viewer.expand_control}
        >
          {linkText}
        </Link>
      );
    default:
      return null;
  }
};
