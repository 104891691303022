import * as React from 'react';
import lottie, { AnimationItem } from 'lottie-web';
import { useState, useEffect, useRef, useImperativeHandle } from 'react';
import type {
  ILottieEmbedCompProps,
  ILottieEmbedImperativeActions,
} from '../LottieEmbed.types';
import { addSelfDestructingEventListener } from '../utils';
import { classes } from './LottieEmbed.component.st.css';
// @ts-ignore
import loader from './loader.json';

const noop = () => {};

type LottieEmbedState = {
  animationData: object;
};

const LottieEmbed: React.ForwardRefRenderFunction<
  ILottieEmbedImperativeActions,
  ILottieEmbedCompProps
> = (props, ref) => {
  const {
    id,
    animationUrl,
    animationJson,
    loop,
    autoplay,
    speed,
    direction,
    alt,
    onClick = noop,
    onDblClick = noop,
    onBlur = noop,
    onFocus = noop,
    reducedMotion,
  } = props;
  const lottieContainer = useRef<HTMLDivElement>(null);
  const lottieAnimationRef: React.MutableRefObject<AnimationItem | null> =
    useRef(null);
  const focusRef = useRef<HTMLDivElement>(null);
  const [animationData, setAnimationData] =
    useState<LottieEmbedState['animationData']>(loader);

  useEffect(() => {
    if (lottieAnimationRef.current) {
      lottieAnimationRef.current.destroy();
    }
    lottieAnimationRef.current = lottie.loadAnimation({
      container: lottieContainer.current!,
      renderer: 'svg',
      loop,
      autoplay: !reducedMotion && autoplay,
      animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid meet',
        viewBoxOnly: true,
        className: classes.svg,
      },
    });
    lottieAnimationRef.current?.setSpeed(speed);
    return () => lottieAnimationRef.current?.destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animationData, autoplay, loop]);

  useEffect(() => {
    const fetchAnimationData = async () => {
      const response = await fetch(animationUrl);
      const data = await response.json();
      setAnimationData(data);
    };

    if (animationUrl) {
      fetchAnimationData();
    }
    if (animationJson) {
      setAnimationData(animationJson);
    }
  }, [animationUrl, animationJson]);

  useEffect(() => {
    lottieAnimationRef.current?.setSpeed(speed);
  }, [speed]);

  useEffect(() => {
    lottieAnimationRef.current?.setDirection(direction === 'forward' ? 1 : -1);
  }, [direction]);

  useImperativeHandle(ref, () => ({
    play: () => {
      lottieAnimationRef.current?.play();
      return new Promise(resolve => {
        addSelfDestructingEventListener(
          lottieAnimationRef.current!,
          'complete',
          () => {
            resolve();
            lottieAnimationRef.current?.goToAndStop(0, false);
          },
        );
      });
    },
    stop: () => lottieAnimationRef.current?.stop(),
    pause: () => lottieAnimationRef.current?.pause(),
    focus: () => {
      focusRef.current?.focus();
    },
    blur: () => {
      focusRef.current?.blur();
    },
  }));

  return (
    <div
      id={id}
      ref={focusRef}
      className={classes.container}
      onClick={onClick}
      onDoubleClick={onDblClick}
      onFocus={onFocus}
      onBlur={onBlur}
      tabIndex={0}
    >
      <div ref={lottieContainer} role="img" aria-labelledby={alt}></div>
    </div>
  );
};

export default React.forwardRef(LottieEmbed);
