import * as React from 'react';

import { IUnsupportedComponentProps } from '../UnsupportedComponent.types';
import { st, classes } from './style/UnsupportedComponent.st.css';

const UnsupportedComponent: React.FC<IUnsupportedComponentProps> = props => {
  const { id, className } = props;

  return (
    <div id={id} className={st(classes.root, className)}>
      !
    </div>
  );
};

export default UnsupportedComponent;
