import {
  ISubmenuProps,
  MenuMode,
} from '../../../../StylableHorizontalMenu.types';
import { getClasses_column } from '../styles/getClasses_column';
import { createInjectCssVars_column } from '../styles/injectCssVars_column';
import { depth0PositionUpdaters__wrapColumn } from '../positionUpdaters/itemDepth0_wrapColumn';

export const createSubmenuProps_column = (
  className: string,
  menuMode: MenuMode,
): ISubmenuProps => ({
  column: {
    positionUpdaters:
      menuMode === 'wrap' ? [depth0PositionUpdaters__wrapColumn] : [],
    getClasses: getClasses_column,
    injectCssVars: createInjectCssVars_column(className),
  },
});
