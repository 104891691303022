export const ViewerType = 'wixui.Tabs' as const;
export const PropertiesType = 'TabsProperties' as const;

export const INITIAL_DEFAULT_TAB = 'FIRST_TAB' as const;

export const TranslationKeys = {
  gfppHelpId: 'help.gfpp-Tabs',
  manageItemsGfppAction: 'gfpp_mainaction_tabs',
} as const;

export const Skins = {
  containerDefaultSkin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
} as const;

export const TestIds = {
  AddPanel: 'tabs-add-panel-section',
  ManageItemsAction: 'tabs-manage-items-panel-gfpp-action',
  ManageItemsPanelContainer: 'tabs-manage-items-panel-container',
  SortDragList: 'SortDragList',
  AddTabButton: 'AddTabButton',
  DuplicateTabButton: 'DuplicateTabButton',
  EditTabNameMenuItem: 'EditTabNameMenuItem',
  DuplicateTabMenuItem: 'DuplicateTabMenuItem',
  DeleteTabMenuItem: 'DeleteTabMenuItem',
  TabItem: 'tab-item',
  TabItemLabel: 'tab-item-label',
  ForwardScrollBtn: 'ForwardScrollBtn',
  BackwardScrollBtn: 'BackwardScrollBtn',
  TabsList: 'TabsListWrapper',
} as const;

export enum MenuMode {
  wrap = 'wrap',
  scroll = 'scroll',
}

export const menuModeMapper = {
  wrap: 'wrap',
  scroll: 'nowrap',
} as const;

export const itemsAlignmentMap = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
} as const;
