import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefs,
} from '@wix/editor-elements-integrations';
import {
  VerticalAnchorsMenuDefinition,
  VerticalAnchorsMenuProps as VerticalAnchorsMenuPlatformProps,
  VerticalAnchorsMenuPropsCarmiData,
  VerticalAnchorsMenuItemCarmiData,
} from '@wix/thunderbolt-components-native';
import { PAGE_ANCHORS } from '../constants';
import {
  VerticalAnchorsMenuCSSVars,
  VerticalAnchorsMenuItem,
  VerticalAnchorsMenuStateRefs,
} from '../VerticalAnchorsMenu.types';

export const props = withCompInfo<
  VerticalAnchorsMenuPlatformProps,
  VerticalAnchorsMenuDefinition,
  VerticalAnchorsMenuPropsCarmiData
>()(['compData', 'compProps'], ({ compData, compProps }, carmiProps) => {
  const { pageTopLabel, hiddenAnchorIds } = compData;
  const { autoColor, itemsAlignment, orientation } = compProps;
  const { requestedPageId, pageAnchors, shouldSort } = carmiProps;
  const pageHiddenAnchorIds: Array<string> =
    hiddenAnchorIds && hiddenAnchorIds.hasOwnProperty(requestedPageId)
      ? (hiddenAnchorIds as Record<string, Array<string>>)[requestedPageId]
      : [];
  const topAnchor: VerticalAnchorsMenuItemCarmiData = {
    compId: PAGE_ANCHORS.TOP_ANCHOR_COMP_ID,
    dataId: PAGE_ANCHORS.TOP_ANCHOR_ID,
    name: pageTopLabel || '',
  };
  const reducedAnchors = () => {
    const res = Object.values(pageAnchors);
    if (shouldSort) {
      res.sort(
        (
          a: VerticalAnchorsMenuItemCarmiData,
          b: VerticalAnchorsMenuItemCarmiData,
        ) => a.top! - b.top!,
      );
    }
    return res.map(
      (item: VerticalAnchorsMenuItemCarmiData): VerticalAnchorsMenuItem => {
        const { dataId, compId, name } = item;
        return { dataId, compId, name };
      },
    );
  };
  const anchors = [topAnchor]
    .concat(reducedAnchors())
    .filter(
      (anchorItem: VerticalAnchorsMenuItem) =>
        !pageHiddenAnchorIds.includes(anchorItem.compId),
    );
  return {
    pageTopLabel,
    anchors: anchors.length ? anchors : [topAnchor],
    autoColor,
    itemsAlignment,
    orientation,
    activeAnchor: {
      compId: PAGE_ANCHORS.TOP_ANCHOR_COMP_ID,
      dataId: PAGE_ANCHORS.TOP_ANCHOR_ID,
    },
  };
});

const css = withCompInfo<
  VerticalAnchorsMenuCSSVars,
  VerticalAnchorsMenuDefinition
>()(['compProps'], ({ compProps }) => {
  const { itemsAlignment, orientation } = compProps;
  const itemsAlignmentFlexVals = {
    left: 'flex-start' as const,
    center: 'center' as const,
    right: 'flex-end' as const,
  };
  return {
    '--items-alignment': itemsAlignmentFlexVals[itemsAlignment],
    '--text-alignment': itemsAlignment,
    '--orientation': orientation === 'left' ? 'row' : 'row-reverse',
  };
});

export const stateRefs = withStateRefs<VerticalAnchorsMenuStateRefs>()(
  [],
  ({ refApi }) => ({
    currentPageHref: refApi.router.currentUrl(),
  }),
);

export default createComponentMapperModel({ css, props, stateRefs });
