import {
  withCompPreviewProps,
  createComponentPreviewMapperModel,
} from '@wix/editor-elements-integrations';
import { CompInfoDependency } from '@wix/editor-elements-types';
import {
  FreestyleDefinition,
  FreestylePlatformProps,
  FreestylePropsCarmiData,
} from '../Freestyle.types';
import { props as viewerPropsMapper } from './Freestyle.mapper';

const viewerDeps = Object.keys(
  viewerPropsMapper.deps,
) as Array<CompInfoDependency>;

const props = withCompPreviewProps<
  FreestylePlatformProps,
  FreestyleDefinition,
  FreestylePropsCarmiData
>()(['isPlayingAllowed', ...viewerDeps], (deps, carmiData) => {
  const viewerProps = viewerPropsMapper.resolver(deps, carmiData);
  return {
    ...viewerProps,
    isPlayingAllowed: deps.isPlayingAllowed,
  };
});

export default createComponentPreviewMapperModel({
  props: props as any,
});
