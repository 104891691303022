import * as React from 'react';
import { MenuButtonProps } from '../../../MenuButton.types';
import SeparateShinyIIMenuButton from './SeparateShinyIIMenuButton';
import skinsStyle from './styles/SeparateShinyIIMenuButtonNBorderRadiusFixSkin.scss';

const SeparateShinyIIMenuButtonNBorderRadiusFixSkin: React.FC<MenuButtonProps> =
  props => {
    return (
      <SeparateShinyIIMenuButton
        {...props}
        skin={'SeparateShinyIIMenuButtonNBorderRadiusFixSkin'}
        skinsStyle={skinsStyle}
      />
    );
  };

export default SeparateShinyIIMenuButtonNBorderRadiusFixSkin;
