
  var rt = require("@stylable/runtime/cjs/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "PaginationForm1997100310",
      {"classes":{"root":"PaginationForm1997100310__root","input":"PaginationForm1997100310__input","totalPages":"PaginationForm1997100310__totalPages","slash":"PaginationForm1997100310__slash"},"vars":{},"stVars":{"bRadius":"var(--bRadius, 100px)","shadow":"var(--shadow, none)","pageInputBG":"rgba(var(--pageInputBG, var(--color_23)), var(--alpha-pageInputBG, 1))","pageInputBorderColor":"rgba(var(--pageInputBorderColor, var(--color_27)), var(--alpha-pageInputBorderColor, 1))","pageInputBorderWidth":"var(--pageInputBorderWidth, 0)","pageInputTextColor":"rgba(var(--pageInputTextColor, var(--color_26)), var(--alpha-pageInputTextColor, 1))","totalPagesTextColor":"rgba(var(--totalPagesTextColor, var(--color_7)), var(--alpha-totalPagesTextColor, 1))","pageInputFocusBG":"rgba(var(--pageInputFocusBG, var(--color_8)), var(--alpha-pageInputFocusBG, 1))","pageInputFocusBorderColor":"rgba(var(--pageInputFocusBorderColor, var(--color_38)), var(--alpha-pageInputFocusBorderColor, 1))","pageInputFocusBorderWidth":"var(--pageInputFocusBorderWidth, 3)","pageInputErrorBG":"rgba(var(--pageInputErrorBG, var(--color_2)), var(--alpha-pageInputErrorBG, 1))","pageInputErrorBorderColor":"rgba(var(--pageInputErrorBorderColor, var(--color_22)), var(--alpha-pageInputErrorBorderColor, 1))","pageInputErrorBorderWidth":"var(--pageInputErrorBorderWidth, 3)","paginationFlexGrow":"var(--pagination-flex-grow, 0)"},"keyframes":{}},
      "",
      -1,
      module.id
  );
  