import * as React from 'react';
import classNames from 'classnames';
import { ILoginSocialButtonProps } from '../LoginSocialButton.types';
import Link from '../../Link/viewer/Link';
import Icon from '../../Icon/viewer/Icon';
import { TestIds } from '../constants';
import styles from './style/LoginSocialButton.scss';

const getAriaLabel = (label: string, displayCount?: number) => {
  if (!label) {
    return;
  }
  return typeof displayCount === 'number' ? `${displayCount} ${label}` : label;
};

/**
 * TODO - Move to server
 */
const getBadgeStyle = (
  iconSize: number,
  direction: ILoginSocialButtonProps['direction'],
): React.CSSProperties => {
  const badgeSize = iconSize / 2;
  return direction === 'ltr'
    ? { right: badgeSize, left: 'auto' }
    : { left: badgeSize, right: 'auto' };
};

const getBadgeLabel = (count: number) =>
  count >= 1000 ? `${Math.floor(count / 1000)}k` : count;

/**
 * Reusable component based on wysiwyg.viewer.components.LoginSocialButton
 */
const LoginSocialButton: React.FC<ILoginSocialButtonProps> = ({
  label,
  displayCount,
  link,
  icon,
  className,
  direction = 'rtl',
  dataTestId,
}) => (
  <div
    data-testid={dataTestId || TestIds.root}
    className={classNames(styles.root, className)}
    aria-label={getAriaLabel(label, displayCount)}
  >
    <Link dataTestId={TestIds.link} {...link}>
      <Icon
        {...icon}
        dataTestId={TestIds.icon}
        className={classNames(styles.icon, icon?.className)}
      />
      <div
        className={styles.badge}
        data-testid={TestIds.badge}
        style={getBadgeStyle(icon?.size, direction)}
      >
        {typeof displayCount === 'number' && (
          <span>{getBadgeLabel(displayCount)}</span>
        )}
      </div>
    </Link>
  </div>
);

export default LoginSocialButton;
