import * as React from 'react';
import classNames from 'classnames';
import Image from '../../Image/viewer/Image';
import VectorImage from '../../VectorImage/viewer/VectorImage';
import { IIconProps } from '../Icon.types';
import { TestIds } from '../constants';
import styles from './style/Icon.scss';

/**
 *
 * Reusable component based on wysiwyg.viewer.components.Icon
 */
const Icon: React.FC<IIconProps> = ({
  id,
  renderImage = false,
  imageUri,
  size,
  svgContent = '',
  className,
  svgClassName,
  imageContainerId,
  dataTestId = TestIds.root,
}) => (
  <div
    id={id}
    data-testid={dataTestId}
    className={classNames(styles.root, className)}
  >
    {renderImage && imageUri ? (
      <Image
        className={styles.image}
        id={`image-${id}`}
        containerId={imageContainerId!}
        width={size}
        height={size}
        displayMode="fill"
        uri={imageUri}
        name=""
        alt=""
      />
    ) : (
      <VectorImage
        id={`svg-${id}`}
        className={svgClassName}
        svgContent={svgContent}
        shouldScaleStroke={false}
        containerClass={styles.svg}
        withShadow={false}
      />
    )}
  </div>
);

export default Icon;
