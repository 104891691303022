import * as React from 'react';
import { useState, useMemo, useCallback } from 'react';
import classNames from 'classnames';
import { IVKShareButtonProps } from '../VKShareButton.types';
import { useIFrame } from '../../../providers/useIFrame/useIFrame';

import style from './style/VKShareButton.scss';
import baseIframeUrl from '!!url-loader?limit=1!./assets/VKShare.html';

const VKShareButton: React.FC<IVKShareButtonProps> = props => {
  const {
    id,
    socialUrl,
    iframeParams,
    currentUrl,
    onMouseEnter,
    onMouseLeave,
  } = props;
  const a11yTitle = 'VK Share';
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const _iframeParams = { ...iframeParams, id };

  const [width, setWidth] = useState(undefined);
  const [render, setRender] = useState(false);

  const iframeSrc = useMemo(() => {
    return `${baseIframeUrl}?${new URLSearchParams(
      _iframeParams,
    )}&url=${encodeURIComponent(socialUrl)}`;
  }, [_iframeParams, socialUrl]);

  const onPostMessage = useCallback(
    event => {
      if (event.type === 'message') {
        const payload = event.payload;

        if (typeof payload !== 'object') {
          let json;
          try {
            json = JSON.parse(payload);
          } catch (e) {
            return;
          }

          if (json.id === id) {
            setWidth(json.width);
          }
        }
      }
    },
    [id],
  );

  const [ref] = useIFrame({ reducer: onPostMessage });

  React.useEffect(() => {
    setRender(true);
  }, []);

  const styles = width
    ? ({
        '--width': `${width}px`,
        width: `${width}px`,
      } as React.CSSProperties)
    : {};

  // original comment from santa code:
  // https://github.com/wix-private/santa/blob/eceb540ad0df4593961d9459913aa8686dab9ce2/packages/vKShareButton/src/main/component/vKShareButton.js#L47
  // we need to create React.DOM.iframe so that we can pass it a key, to solve CLNT-4186
  // additional info about this:
  // https://stackoverflow.com/questions/29859048/updating-an-iframe-history-and-url-then-making-it-work-with-back-button
  const key = `VKShareButton-${currentUrl.href}`;

  return (
    <div
      id={id}
      aria-label={a11yTitle}
      style={styles}
      tabIndex={0}
      title={a11yTitle}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {render && (
        <wix-iframe data-src={iframeSrc}>
          <iframe
            aria-label={a11yTitle}
            className={classNames(style.iframe)}
            data-src={iframeSrc}
            key={key}
            ref={ref}
            title={a11yTitle}
          />
        </wix-iframe>
      )}
    </div>
  );
};

export default VKShareButton;
