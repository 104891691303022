import {
  withCompInfo,
  withStateRefs,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';

const props = withCompInfo<any, any>()(
  ['compData', 'compMeshProps'],
  ({ compData, compMeshProps }) => {
    return {
      ...{ title: 'Your card title' },
      ...compData,

      meshProps: compMeshProps,
    };
  },
);

export const stateRefs = withStateRefs<any>()([], ({ refApi }) => ({
  editorType: refApi.env.editorType(),
}));

export default createComponentMapperModel({
  props,
  stateRefs,
});
