import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { getLanguage } from '../../WTwitter/common/utils';
import {
  WTwitterTweetDefinition,
  WTwitterTweetProps,
} from '../WTwitterTweet.types';

export const props = withCompInfo<
  WTwitterTweetProps,
  WTwitterTweetDefinition
>()(
  ['compData', 'compProps', 'compLayout', 'language', 'isMobileView'],
  ({ compData, compProps, compLayout, language, isMobileView }) => {
    return {
      defaultText: compData.defaultText,
      accountToFollow: compData.accountToFollow,
      isMobileView,
      lang: getLanguage(compProps.dataLang, language),
      width: compLayout.width || 0,
      height: compLayout.height || 0,
    };
  },
);

export default createComponentMapperModel({ props });
