import React from 'react';
import Page from '../../Page';
import { SkinPageProps } from '../SkinPage';
import skinsStyles from './styles/ShinyIPageSkin.scss';
import BasePageSkin, { BasePageSkinProps } from './BasePageSkin';

const ShinyIPageSkin: React.FC<Omit<BasePageSkinProps, 'skinsStyle'>> =
  props => <BasePageSkin {...props} skinsStyle={skinsStyles} />;

const ShinyIPage: React.FC<SkinPageProps> = props => (
  <Page {...props} skin={ShinyIPageSkin} />
);

export default ShinyIPage;
