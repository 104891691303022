import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import {
  FreemiumBannerDesktopDefinition,
  FreemiumBannerDesktopPlatformProps,
  FreemiumBannerDesktopCarmiData,
} from '../FreemiumBannerDesktop.types';
import { props as viewerPropsMapper } from './FreemiumBannerDesktop.mapper';
import previewTranslations from './constants.preview';

const getPreviewUrl = (metaSiteId?: string) =>
  `//premium.wix.com/wix/api/premiumStart?siteGuid=${metaSiteId}&referralAdditionalInfo=edhtml_PREVIEW_FREE_SITE_BANNER_CLICK`;

export const props = composeMapper<
  FreemiumBannerDesktopPlatformProps,
  FreemiumBannerDesktopDefinition,
  FreemiumBannerDesktopCarmiData
>()(viewerPropsMapper, { add: ['metaSiteId'] }, (resolver, deps, carmiData) => {
  const viewerProps = resolver(deps, carmiData);
  return {
    ...viewerProps,
    ...{
      translations: previewTranslations,
      href: getPreviewUrl(deps.metaSiteId),
    },
  };
});

export default createComponentPreviewMapperModel({
  props,
});
