import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import { useResetComponent } from '@wix/editor-elements-preview-utils';
import {
  IComponentPreviewWrapper,
  PreviewWrapperProps,
} from '@wix/editor-elements-types';
import React from 'react';
import {
  NoReviewsMode,
  DEFAULT_SHAPE_SIZE,
  DEFAULT_SHAPE_SPACING,
} from '../constants';
import {
  IRatingsDisplayProps,
  RatingsDisplayPreviewWrapperProps,
} from '../RatingsDisplay.types';

export const PREVIEW_STATES = {
  NO_REVIEWS: 'noReviews',
};

function withComponentPreview(
  WrappedComponent: React.ComponentType<IRatingsDisplayProps>,
): IComponentPreviewWrapper<
  IRatingsDisplayProps,
  RatingsDisplayPreviewWrapperProps
> {
  return ({
    previewWrapperProps = {},
    id,
    ...viewerProps
  }: PreviewWrapperProps<
    IRatingsDisplayProps,
    RatingsDisplayPreviewWrapperProps
  >) => {
    const {
      shouldResetComponent,
      componentViewMode,
      compPreviewState,
      shapeSize = DEFAULT_SHAPE_SIZE,
      shapeSpacing = DEFAULT_SHAPE_SPACING,
    } = previewWrapperProps;
    const { noReviewsMode } = viewerProps;

    const key = useResetComponent({
      shouldResetComponent,
      id,
    });

    const rating =
      compPreviewState === PREVIEW_STATES.NO_REVIEWS
        ? null
        : viewerProps.rating;

    const isEmptyState = () => !rating;
    const editorMode = componentViewMode === 'editor';

    const showRatingInfo = () => {
      return (
        !isEmptyState() ||
        (noReviewsMode === NoReviewsMode.NOTHING ? editorMode : false)
      );
    };
    const showIcons = () => {
      return (
        !isEmptyState() ||
        (noReviewsMode === NoReviewsMode.NOTHING
          ? editorMode
          : noReviewsMode !== NoReviewsMode.PLACEHOLDER_TEXT)
      );
    };

    const NUM_OF_SHAPES = 5;
    const NUM_OF_SPACES_BETWEEN_SHAPES = 6;
    const calcIconsLayout = React.useCallback(() => {
      const iconsWidth =
        NUM_OF_SPACES_BETWEEN_SHAPES * shapeSpacing + NUM_OF_SHAPES * shapeSize;
      const iconsHeight = shapeSize;

      return { iconsWidth, iconsHeight };
    }, [shapeSize, shapeSpacing]);

    const reCalculateIconsSizeDeps = React.useMemo(
      () => [shapeSize, shapeSpacing],
      [shapeSize, shapeSpacing],
    );
    return (
      <WrappedComponent
        {...viewerProps}
        id={id}
        key={key}
        showRatingInfo={showRatingInfo()}
        showIcons={showIcons()}
        reCalculateIconsSizeDeps={reCalculateIconsSizeDeps}
        overrideIconsStyle={calcIconsLayout}
        rating={rating}
        isTransparent={
          noReviewsMode === NoReviewsMode.NOTHING &&
          editorMode &&
          isEmptyState()
        }
      />
    );
  };
}

export default (ViewerComponent: React.ComponentType<IRatingsDisplayProps>) =>
  createComponentPreviewEntry(withComponentPreview(ViewerComponent));
