// @ts-ignore - no declaration file exist for this 3rd party
import createStaticToolbarPlugin from 'draft-js-static-toolbar-plugin';
import createInlineToolbarPlugin from 'draft-js-inline-toolbar-plugin';

import { ToolbarType } from '../editor/Editor';
import { IRichTextBoxProps } from '../../RichTextBox.types';
import bottomToolbarWrapperTheme from './bottomToolbarWrapperTheme.scss';
import topToolbarWrapperTheme from './topToolbarWrapperTheme.scss';
import inlineToolbarWrapperTheme from './inlineToolbarWrapperTheme.scss';

export default (
  toolbarPosition: IRichTextBoxProps['toolbarPosition'],
): ToolbarType => {
  const bottomToolbar = createStaticToolbarPlugin({
    theme: {
      toolbarStyles: bottomToolbarWrapperTheme,
    },
  });

  const topToolbar = createStaticToolbarPlugin({
    theme: {
      toolbarStyles: topToolbarWrapperTheme,
    },
  });

  const inlineToolbar = createInlineToolbarPlugin({
    theme: { toolbarStyles: inlineToolbarWrapperTheme },
  });

  if (toolbarPosition === 'bottom' || toolbarPosition === 'top') {
    const staticToolbarPlugin =
      toolbarPosition === 'bottom' ? bottomToolbar : topToolbar;
    const { Toolbar: StaticToolbar } = staticToolbarPlugin;

    return {
      instance: StaticToolbar,
      toolbarPosition,
      plugin: staticToolbarPlugin,
    };
  } else {
    const inlineToolbarPlugin = inlineToolbar;

    const { InlineToolbar } = inlineToolbarPlugin;

    return {
      instance: InlineToolbar,
      toolbarPosition,
      plugin: inlineToolbarPlugin,
    };
  }
};
