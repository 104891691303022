import * as React from 'react';
import SignaturePad from 'signature_pad';
import classNames from 'classnames';
import {
  ISignatureInputImperativeActions,
  ISignatureInputProps,
} from '../SignatureInput.types';
import { useResizeObserver } from '../../../providers/useResizeObserver/useResizeObserver';
import { debounce } from '../../../core/commons/utils';
import { st, classes } from './style/SignatureInput.st.css';
import { calculatePenColor, transformPenSizeToWidths } from './utils';

const noop = () => {};
const RESIZE_OBSERVER_DELAY = 300;

const SignatureInput: React.ForwardRefRenderFunction<
  ISignatureInputImperativeActions,
  ISignatureInputProps
> = (props, ref) => {
  const {
    id,
    value = '',
    label,
    clearButtonText,
    required,
    isDisabled,
    isValid,
    signatureSize,
    signatureColor,
    direction,
    shouldShowValidityIndication,
    onValueChange = noop,
    onChange = noop,
    onFocus = noop,
    onBlur = noop,
    onMouseEnter = noop,
    onMouseLeave = noop,
    validateValueAndShowIndication = noop,
    hideValidityIndication = noop,
  } = props;

  const [isDrawing, setIsDrawing] = React.useState(false);
  const [isA11yInputFocused, setA11yInputFocused] = React.useState(false);
  const [a11yValue, setA11yValue] = React.useState<string>(value);
  const signaturePadRef = React.useRef<SignaturePad | null>(null);
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const padContainerRef = React.useRef<HTMLDivElement>(null);

  const updateCanvasDimensionsAndScale = () => {
    if (canvasRef.current) {
      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      canvasRef.current.width = canvasRef.current.clientWidth * ratio;
      canvasRef.current.height = canvasRef.current.clientHeight * ratio;

      canvasRef.current.getContext('2d')?.scale(ratio, ratio);
    }
  };
  const resizeObserverCallback = React.useCallback(() => {
    debounce(updateCanvasDimensionsAndScale, RESIZE_OBSERVER_DELAY)();
  }, []);

  useResizeObserver({
    ref: padContainerRef,
    callback: resizeObserverCallback,
  });

  React.useImperativeHandle(ref, () => {
    return {
      focus: () => {
        inputRef.current?.focus();
      },
      blur: () => {
        inputRef.current?.blur();
      },
      clear: () => {
        handleClear({ shouldTriggerChangeEvent: false });
      },
      setCustomValidity: message => {
        if (message.type === 'message') {
          inputRef.current?.setCustomValidity(message.message);
        }
      },
    };
  });

  const onDrawStart = React.useCallback(() => {
    return () => setIsDrawing(true);
  }, []);

  const onDrawEnd = React.useCallback(() => {
    setIsDrawing(false);
    const newValue = signaturePadRef.current?.toDataURL() || '';
    if (value !== newValue) {
      onValueChange(newValue);
      validateValueAndShowIndication();
      onChange({
        type: 'change',
        compId: id,
        target: {
          value: newValue,
        },
      });
    }
  }, [onChange, validateValueAndShowIndication, value, onValueChange, id]);

  React.useEffect(() => {
    if (canvasRef.current) {
      if (!signaturePadRef.current) {
        signaturePadRef.current = new SignaturePad(canvasRef.current, {
          onBegin: onDrawStart,
          onEnd: onDrawEnd,
        });
        updateCanvasDimensionsAndScale();
      } else {
        signaturePadRef.current.onBegin = onDrawStart;
        signaturePadRef.current.onEnd = onDrawEnd;
      }
    }
  }, [onDrawEnd, onDrawStart]);

  React.useEffect(() => {
    return () => {
      signaturePadRef.current?.off();
    };
  }, []);

  React.useEffect(() => {
    const { minWidth, maxWidth } = transformPenSizeToWidths(signatureSize);
    if (signaturePadRef.current) {
      signaturePadRef.current.minWidth = minWidth;
      signaturePadRef.current.maxWidth = maxWidth;
    }
  }, [signatureSize]);

  React.useEffect(() => {
    if (signaturePadRef.current) {
      signaturePadRef.current.penColor = calculatePenColor(signatureColor);
    }
  }, [signatureColor]);

  React.useEffect(() => {
    if (isDisabled) {
      signaturePadRef.current?.off();
    } else {
      signaturePadRef.current?.on();
    }
  }, [isDisabled]);

  const onInputChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    // clears drawns curves and typed characters and restore drawn curves
    if (!signaturePadRef.current?.isEmpty() || a11yValue) {
      const drawnData = signaturePadRef.current?.toData();
      signaturePadRef.current?.clear();
      if (drawnData) {
        signaturePadRef.current?.fromData(drawnData);
      }
    }

    setA11yValue(e.target.value);
    const ctx = canvasRef.current?.getContext('2d');
    if (ctx) {
      ctx.font = '25px Sacramento';
      ctx.fillText(
        e.target.value,
        direction === 'rtl' ? (canvasRef.current?.offsetWidth || 40) - 20 : 20,
        (canvasRef.current?.offsetHeight || 0) / 2,
      );
    }
    onDrawEnd();
  };

  const handleClear = ({
    shouldTriggerChangeEvent,
  }: {
    shouldTriggerChangeEvent: boolean;
  }) => {
    if (value) {
      signaturePadRef.current?.clear();
      setA11yValue('');
      onValueChange('');
      validateValueAndShowIndication();
      if (shouldTriggerChangeEvent) {
        onChange({
          type: 'change',
          compId: id,
          target: {
            value: '',
          },
        });
      }
    }
    hideValidityIndication();
  };

  const _onFocus: React.FocusEventHandler = event => {
    setA11yInputFocused(true);
    onFocus(event);
  };

  const _onBlur: React.FocusEventHandler = event => {
    setA11yInputFocused(false);
    onBlur(event);
  };

  const inputId = `input_${id}`;
  const labelId = `label_${id}`;

  const rootClassName = st(classes.root, {
    required,
    disabled: isDisabled,
    error: !isValid && shouldShowValidityIndication,
    focus: !isDisabled && (isA11yInputFocused || isDrawing),
  });

  return (
    <div
      id={id}
      className={rootClassName}
      ref={padContainerRef}
      {...(!isDisabled && { onMouseEnter, onMouseLeave })}
    >
      {label && (
        <label id={labelId} className={classes.title} htmlFor={inputId}>
          {label}
        </label>
      )}
      <div className={classes.padContainer}>
        <input
          id={inputId}
          className={classes.visuallyHidden}
          ref={inputRef}
          value={a11yValue}
          onChange={onInputChange}
          disabled={!!isDisabled}
          aria-required={!!required}
          aria-invalid={!isValid}
          onFocus={_onFocus}
          onBlur={_onBlur}
        />
        <canvas ref={canvasRef} className={classes.pad} aria-hidden />
      </div>
      <button
        className={classNames(classes.clearButton, 'has-custom-focus')}
        disabled={!!isDisabled}
        aria-controls={inputId}
        aria-label={`${clearButtonText} ${label}`}
        onClick={() => handleClear({ shouldTriggerChangeEvent: true })}
      >
        {clearButtonText}
      </button>
    </div>
  );
};

export default React.forwardRef(SignatureInput);
