import * as React from 'react';
import { ITabsScrollBtnProps } from '../../Tabs.types';

const ScrollBtn: React.FC<ITabsScrollBtnProps> = props => {
  const { className, children, dataHook, onClick } = props;

  return (
    <div className={className} onClick={onClick} data-hook={dataHook}>
      {children}
    </div>
  );
};

export default ScrollBtn;
