import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { ImageProps } from '@wix/thunderbolt-components-native';

import {
  FlashComponentDefinition,
  FlashComponentProps,
  FlashComponentPropsCarmiData,
} from '../FlashComponent.types';

const props = withCompInfo<
  FlashComponentProps,
  FlashComponentDefinition,
  FlashComponentPropsCarmiData
>()(
  ['compData', 'compProps', 'topology'],
  ({ compData, compProps, topology }, carmiProps) => {
    const { uri, placeHolderImage } = compData;
    const { link } = carmiProps;
    const placeholderImage: Partial<ImageProps> = { ...placeHolderImage };
    placeholderImage.displayMode =
      compProps.displayMode === 'stretch' ? 'fill' : 'fit';
    return {
      uri: uri ? `${topology.mediaRootUrl}media/${uri}` : undefined,
      placeholderImage,
      link,
    };
  },
);

export default createComponentMapperModel({ props });
