import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import { PreviewWrapperProps } from '@wix/editor-elements-types';
import {
  MediaContainerProps,
  HoverBoxSelectorCSSVars,
} from '@wix/thunderbolt-components-native';
import { HoverBoxDefinition } from '@wix/thunderbolt-components';
import {
  selectorToCss as viewerSelectorToCssMapper,
  props as viewerPropsMapper,
} from './HoverBox.mapper';

const props = composeMapper<
  PreviewWrapperProps<MediaContainerProps>,
  HoverBoxDefinition,
  MediaContainerProps
>()(
  viewerPropsMapper,
  { add: ['isPlayingAllowed'] },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);

    let { fillLayers } = viewerProps;
    if (fillLayers.video) {
      fillLayers = JSON.parse(JSON.stringify(fillLayers));
      fillLayers.video!.videoInfo.autoPlay =
        fillLayers.video!.videoInfo.autoPlay && deps.isPlayingAllowed;
    }
    return {
      ...viewerProps,
      fillLayers,
    };
  },
);

const selectorToCss = composeMapper<
  HoverBoxSelectorCSSVars,
  HoverBoxDefinition,
  HoverBoxSelectorCSSVars
>()(
  viewerSelectorToCssMapper,
  { add: ['componentViewMode'] },
  (resolver, dependencies, carmiData) => {
    const viewerCss = resolver(dependencies, carmiData) || {};
    const styles: HoverBoxSelectorCSSVars = {};
    Object.keys(viewerCss).forEach((key: string) => {
      styles[key] = { ...viewerCss[key] };
      if (dependencies.componentViewMode === 'editor') {
        styles[key]['--hover-box-transform'] = 'none';
      }
    });
    return styles;
  },
);

export default createComponentPreviewMapperModel({ props, selectorToCss });
