import * as React from 'react';
import { RatingIconProps } from '../../RatingsInput.types';

export const RatingIcon: React.FC<RatingIconProps> = props => {
  const { svg, className, onClick } = props;
  return (
    <span className={className} onClick={onClick} data-testid="ratingsIcon">
      {svg}
    </span>
  );
};
