import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import {
  NativeExtension,
  PreviewWrapperProps,
} from '@wix/editor-elements-types';
import {
  props as mediaContainerPropsMapper,
  css as mediaContainerCssMapper,
} from '../../MediaContainers/MediaContainer/viewer/MediaContainer.previewMapper';
import {
  ColumnCarmiData,
  ColumnDefinition,
  ColumnProps,
  ColumnWrapperProps,
} from '../Column.types';

type AnyNativeExtension = NativeExtension<any, any, any, any>;

const props = composeMapper<
  PreviewWrapperProps<ColumnProps, ColumnWrapperProps>,
  ColumnDefinition,
  ColumnCarmiData
>()(
  mediaContainerPropsMapper as AnyNativeExtension,
  { add: ['isMobileView'] },
  (resolver, data, carmiData) => {
    const originalProps = resolver(data, carmiData);
    return {
      ...originalProps,
      previewWrapperProps: {
        ...originalProps.previewWrapperProps,
        isMobileView: data.isMobileView,
      },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
  css: mediaContainerCssMapper,
});
