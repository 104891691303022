import * as React from 'react';
import { CoBrandingBannerProps } from '../../../CoBrandingBannerDesktop.types';
import styles from '../../styles/ResponsiveCoBrandingBannerDesktop.scss';
import CoBrandingBannerDesktop from '../../CoBrandingBannerDesktop';

const ResponsiveCoBrandingBannerDesktop: React.FC<CoBrandingBannerProps> =
  props => {
    return <CoBrandingBannerDesktop {...props} styles={styles} />;
  };

export default ResponsiveCoBrandingBannerDesktop;
