import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import { PreviewWrapperProps } from '@wix/editor-elements-types';
import {
  PaginationDefinition,
  PaginationProps,
  PaginationPropsCarmiData,
} from '../Pagination.types';
import { props as viewerPropsMapper } from './Pagination.mapper';

const props = composeMapper<
  PreviewWrapperProps<PaginationProps>,
  PaginationDefinition,
  PaginationPropsCarmiData
>()(
  viewerPropsMapper,
  { add: ['compPreviewState'] },
  (resolver, dependencies, carmiData) => {
    const viewerProps = resolver(dependencies, carmiData);
    const { compPreviewState } = dependencies;

    return {
      ...viewerProps,
      previewWrapperProps: {
        compPreviewState,
      },
    };
  },
);

export default createComponentPreviewMapperModel({ props });
