import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import { PreviewWrapperProps } from '@wix/editor-elements-types';
import { props as viewerPropsMapper } from './PopoverMenu.mapper';

const props = composeMapper<PreviewWrapperProps<any>, any>()(
  viewerPropsMapper,
  { add: ['compPreviewState'] },
  (resolver, dependencies, carmiData) => {
    const viewerProps = resolver(dependencies, carmiData);
    // TODO: should be reworked (compPreviewState should be string)
    const compPreviewState = (dependencies.compPreviewState as any)?.state;

    return {
      ...viewerProps,

      previewWrapperProps: {
        compPreviewState,
      },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
} as any);
