import {
  createComponentPreviewMapperModel,
  withCompInfo,
} from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import { PreviewWrapperProps } from '@wix/editor-elements-types';
import {
  StylableHorizontalMenuProps,
  StylableHorizontalMenuDefinition,
  StylableHorizontalMenuPropsCarmiData,
  StylableHorizontalMenuCSSVars,
  MenuCSSVars,
} from '../StylableHorizontalMenu.types';
import {
  props as viewerPropsMapper,
  css as viewerCssMapper,
} from './StylableHorizontalMenu.mapper';

function getMenuMode({
  menuMode,
  metaSiteId,
}: {
  menuMode: string;
  metaSiteId: string;
}) {
  const isSHMInAddPanel = !metaSiteId;
  return isSHMInAddPanel ? 'scroll' : menuMode;
}

const props = composeMapper<
  PreviewWrapperProps<StylableHorizontalMenuProps>,
  StylableHorizontalMenuDefinition,
  StylableHorizontalMenuPropsCarmiData
>()(
  viewerPropsMapper,
  { add: ['compPreviewState', 'compLayout', 'metaSiteId'] },
  (resolver, dependencies, carmiData) => {
    const viewerProps = resolver(dependencies, carmiData);
    const { compPreviewState, compLayout } = dependencies;

    return {
      ...viewerProps,
      menuMode: getMenuMode({
        menuMode: viewerProps.menuMode,
        metaSiteId: dependencies.metaSiteId,
      }),
      previewWrapperProps: {
        compPreviewState,
        compLayout,
      },
    };
  },
);

export const css = composeMapper<
  StylableHorizontalMenuCSSVars,
  StylableHorizontalMenuDefinition
>()(
  viewerCssMapper,
  { add: ['compProps', 'metaSiteId'] },
  (resolver, dependencies, carmiData): StylableHorizontalMenuCSSVars => {
    const {
      compProps: { menuMode: originalMenuMode },
      metaSiteId,
    } = dependencies;
    const viewerCss = resolver(dependencies, carmiData);

    const isMiniSite = !metaSiteId;
    // wrap looks bad in a mini site (add panel / copy design)
    const shouldModifyMenuMode = isMiniSite && originalMenuMode === 'wrap';

    if (!shouldModifyMenuMode) {
      return viewerCss;
    }

    // If we got here we're in a minisite and the menu in wrap, which we want to modify to scroll, hide scroll controls, and crop - same as DropDownMenu does

    const menuCSSVars: MenuCSSVars = {
      '--menu-flex-wrap': 'nowrap',
    };

    return {
      ...viewerCss,
      ...menuCSSVars,
      '--scroll-controls-display': 'none', // hide scroll controls for mini site
    };
  },
);

const uiType = withCompInfo()([], (() => 'Default') as any);

export default createComponentPreviewMapperModel({
  props,
  css,
  uiType,
} as any);
