import { optionsMap } from '@wix/pro-gallery-wix-wrapper';
import { useState } from 'react';
import { GalleryItemProps } from '../ProGallery.types';
import { ProGalleryStyleableElements } from './styleableElements';

export const isEditingHover = (forceState: any, options: any) => {
  const { selectionSelector } = forceState;

  const styleableOnItem =
    selectionSelector === ProGalleryStyleableElements.Item;
  const hoverIsActive =
    options?.behaviourParams_item_overlay_hoveringBehaviour === 'APPEARS';
  return styleableOnItem && hoverIsActive;
};

export const formatItemUri = (item: GalleryItemProps) => {
  const prefixUri = (uri: string): string => {
    if (uri.substr(0, 4) !== 'http') {
      return 'https://static.wixstatic.com/media/' + uri;
    } else {
      return uri;
    }
  };

  return {
    ...item,
    image: {
      ...item.image,
      uri: prefixUri(item.image.uri),
    },
  };
};

export function stringifyValue(value: any): string {
  try {
    return JSON.stringify(value);
  } catch (err) {
    return '';
  }
}

export function parseValue(value?: string): any {
  try {
    return value && JSON.parse(value);
  } catch (err) {
    return undefined;
  }
}
export function flattenGetSetToProxy<T extends object>(
  get: (key: string) => any,
  set: (key: string, value: any) => any,
) {
  const proxy: T = new Proxy<T>({} as T, {
    get(target, key) {
      if (typeof key !== 'string') {
        return undefined;
      }
      const paths = key.split('.');
      if (paths.length === 1) {
        return parseValue(get(key));
      }
      const path = paths[0];
      return flattenGetSetToProxy(
        sub => {
          return get(`${path}.${sub}`);
        },
        (sub, subValue) => {
          set(`${path}.${sub}`, stringifyValue(subValue));
        },
      );
    },
  });

  return proxy;
}

export function useUpdate(): () => void {
  const [, setState] = useState(0);
  return () => setState(state => state + 1);
}

export function getAllParamNames(optionMapToFaltten: any = optionsMap) {
  const names: Array<string> = [];
  for (const key in optionMapToFaltten) {
    const value = optionMapToFaltten[key];
    if (typeof value === 'object') {
      names.push(...getAllParamNames(value));
    } else {
      names.push(value);
    }
  }
  return names;
}
