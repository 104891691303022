import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import { PreviewWrapperProps } from '@wix/editor-elements-types';
import {
  CheckboxProps as CheckboxPlatformProps,
  CheckboxDefinition,
} from '@wix/thunderbolt-components';
import { props as viewerPropsMapper } from './Checkbox.mapper';

const props = composeMapper<
  PreviewWrapperProps<CheckboxPlatformProps>,
  CheckboxDefinition,
  CheckboxPlatformProps
>()(
  viewerPropsMapper,
  { add: ['compPreviewState', 'shouldResetComponent', 'componentViewMode'] },
  (resolver, dependencies, carmiData) => {
    const viewerProps = resolver(dependencies, carmiData);

    const { shouldResetComponent, compPreviewState, componentViewMode } =
      dependencies;

    return {
      ...viewerProps,
      previewWrapperProps: {
        componentViewMode,
        shouldResetComponent,
        compPreviewState,
      },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
