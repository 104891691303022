import { PositionUpdaters } from './types';

export const depth0PositionUpdaters__wrapColumn: PositionUpdaters = ({
  label,
  positionBox,
}) => ({
  onEnter: () => {
    const marginBottom = window.getComputedStyle(label).marginBottom;
    positionBox.style.marginTop = `-${marginBottom}`;
  },
  onLeave: () => {
    positionBox.removeAttribute('style');
  },
});
