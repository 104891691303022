import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefs,
} from '@wix/editor-elements-integrations';

// TODO add types
export const props = withCompInfo<any, any>()(
  ['compData', 'compProps'],
  ({ compData, compProps }) => {
    return {
      ...compData,
      ...compProps,
    };
  },
);

export const stateRefs = withStateRefs<{ reducedMotion: boolean }>()(
  [],
  ({ refApi }) => ({
    reducedMotion: refApi.env.reducedMotion(),
  }),
);

export default createComponentMapperModel({
  props,
  stateRefs,
});
