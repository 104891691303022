
  var rt = require("@stylable/runtime/cjs/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "RatingsDisplaycomponent3707036051",
      {"classes":{"root":"RatingsDisplaycomponent3707036051__root","ratingValue":"RatingsDisplaycomponent3707036051__ratingValue","reviewsCount":"RatingsDisplaycomponent3707036051__reviewsCount","noReviewsPlaceholder":"RatingsDisplaycomponent3707036051__noReviewsPlaceholder"},"vars":{"display":"--display"},"stVars":{"labelFontColor":"var(--labelFontColorStorybook, rgba(var(--labelFontColor, var(--color_15)), var(--alpha-labelFontColor, 1)))","labelFont":"var(--labelFont, var(--font_8))","displayJustifyContent":"var(--displayJustifyContent, normal)","ratingMarginLeft":"var(--ratingMarginLeft, 0)","ratingMarginRight":"var(--ratingMarginRight, 0)","numReviewsMarginLeft":"var(--numReviewsMarginLeft, 0)","numReviewsMarginRight":"var(--numReviewsMarginRight, 0)","direction":"var(--direction, ltr)"},"keyframes":{}},
      "",
      -1,
      module.id
  );
  