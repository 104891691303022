
  var rt = require("@stylable/runtime/cjs/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "TimePickerDropdown1277584979",
      {"classes":{"root":"TimePickerDropdown1277584979__root","controller":"TimePickerDropdown1277584979__controller","value":"TimePickerDropdown1277584979__value","placeholder":"TimePickerDropdown1277584979__placeholder","dummyOption":"TimePickerDropdown1277584979__dummyOption"},"vars":{},"stVars":{"iconColor":"rgba(var(--iconColor, 0,0,0), var(--alpha-iconColor, 1))","textFont":"var(--textFont, var(--font_1))","placeholderTextColor":"rgba(var(--placeholderTextColor), var(--alpha-placeholderTextColor, 1))","textAlign":"var(--textAlign, left)","arrowMargin":"var(--arrowMargin)","inputPadding":"var(--inputPadding, 0)"},"keyframes":{}},
      "",
      -1,
      module.id
  );
  