import * as React from 'react';
import classNames from 'classnames';
import {
  IToggleSwitchImperativeActions,
  IToggleSwitchProps,
} from '../ToggleSwitch.types';
import {
  TEST_ID_CHECKED_ICON,
  TEST_ID_OUTER_LABEL,
  TEST_ID_UNCHECKED_ICON,
} from '../constants';
import { ReactComponent as CheckedIcon } from './assets/checkmark-checked.svg';
import { ReactComponent as UncheckedIcon } from './assets/checkmark-unchecked.svg';
import style from './style/ToggleSwitch.scss';

/**
 * Switches are used for a single binary choice.
 */
const ToggleSwitch: React.ForwardRefRenderFunction<
  IToggleSwitchImperativeActions,
  IToggleSwitchProps
> = (props, ref) => {
  const {
    id,
    isDisabled,
    checked,
    onBlur = () => {},
    onFocus = () => {},
    onChange = () => {},
    onClick = () => {},
    onDblClick = () => {},
    onMouseEnter = () => {},
    onMouseLeave = () => {},
  } = props;

  const inputRef = React.useRef<HTMLInputElement>(null);
  const [focused, setFocused] = React.useState(false);

  React.useImperativeHandle(ref, () => {
    return {
      focus: () => {
        inputRef.current?.focus();
      },
      blur: () => {
        inputRef.current?.blur();
      },
    };
  });

  const rootClassName: string = classNames(style.root, {
    [style.disabled]: isDisabled,
    [style.checked]: checked,
    [style.focused]: focused,
  });

  const _onChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    onChange(event);
  };

  const _onClick: React.MouseEventHandler<HTMLLabelElement> = event => {
    if (!isDisabled) {
      onClick(event);
      inputRef.current?.focus();
    }
  };

  const _onDblClick: React.MouseEventHandler<HTMLLabelElement> = event => {
    if (!isDisabled) {
      onDblClick(event);
    }
  };

  const _onFocus: React.FocusEventHandler<HTMLInputElement> = event => {
    onFocus(event);
    setFocused(true);
  };

  const _onBlur: React.FocusEventHandler<HTMLInputElement> = event => {
    onBlur(event);
    setFocused(false);
  };

  const _onMouseEnter: React.MouseEventHandler<HTMLLabelElement> = event => {
    if (!isDisabled) {
      onMouseEnter(event);
    }
  };

  const _onMouseLeave: React.MouseEventHandler<HTMLLabelElement> = event => {
    if (!isDisabled) {
      onMouseLeave(event);
    }
  };

  return (
    <label
      id={id}
      className={rootClassName}
      onClick={_onClick}
      onDoubleClick={_onDblClick}
      onMouseEnter={_onMouseEnter}
      onMouseLeave={_onMouseLeave}
    >
      <input
        ref={inputRef}
        type="checkbox"
        className={style.input}
        checked={checked}
        disabled={isDisabled}
        onChange={_onChange}
        onFocus={_onFocus}
        onBlur={_onBlur}
      />
      <div
        data-testid={TEST_ID_OUTER_LABEL}
        className={style.outerLabel}
        aria-label="Toggle"
      />
      <div className={style.innerLabel}>
        {checked ? (
          <CheckedIcon
            data-testid={TEST_ID_CHECKED_ICON}
            className={style.toggleIcon}
          />
        ) : (
          <UncheckedIcon
            data-testid={TEST_ID_UNCHECKED_ICON}
            className={style.toggleIcon}
          />
        )}
      </div>
    </label>
  );
};

export default React.forwardRef(ToggleSwitch);
