import * as React from 'react';
import { SkinButtonProps } from '../SkinButton.types';
import {
  ISiteButtonImperativeActions,
  ISiteButtonProps,
} from '../../../SiteButton.types';
import SiteButton from '../../SiteButton';
import skinsStyle from './styles/skins.scss';

const ViewerButtonBlueSkinSkin: React.FC<SkinButtonProps> = ({
  wrapperProps,
}) => {
  return (
    <div {...wrapperProps} className={skinsStyle.ViewerButtonBlueSkin}>
      <div className={skinsStyle.caption} />
    </div>
  );
};

const ViewerButtonBlueSkin: React.ForwardRefRenderFunction<
  ISiteButtonImperativeActions,
  Omit<ISiteButtonProps, 'skin'>
> = (props, ref) => (
  <SiteButton {...props} skin={ViewerButtonBlueSkinSkin} ref={ref} />
);

export default React.forwardRef(ViewerButtonBlueSkin);
