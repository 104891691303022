import * as React from 'react';
import SiteButtonContent from '../../SiteButtonContent';
import { SkinButtonProps } from '../SkinButton.types';
import SiteButton from '../../SiteButton';
import {
  ISiteButtonImperativeActions,
  ISiteButtonProps,
} from '../../../SiteButton.types';
import skinsStyle from './styles/skins.scss';

const GamingButtonSkin = React.forwardRef<
  ISiteButtonImperativeActions,
  SkinButtonProps
>(
  (
    { wrapperProps, linkProps, a11yProps, elementType, label, onFocus, onBlur },
    ref,
  ) => {
    return (
      <div {...wrapperProps} className={skinsStyle.GamingButton}>
        <SiteButtonContent
          linkProps={linkProps}
          a11yProps={a11yProps}
          elementType={elementType}
          className={skinsStyle.link}
          onFocus={onFocus}
          onBlur={onBlur}
          ref={ref}
        >
          <span className={skinsStyle.left} />
          <span className={skinsStyle.right} />
          <span className={skinsStyle.label}>{label}</span>
          <span className={skinsStyle.gradient} />
        </SiteButtonContent>
      </div>
    );
  },
);

const GamingButton: React.ForwardRefRenderFunction<
  ISiteButtonImperativeActions,
  Omit<ISiteButtonProps, 'skin'>
> = (props, ref) => <SiteButton {...props} skin={GamingButtonSkin} ref={ref} />;

export default React.forwardRef(GamingButton);
