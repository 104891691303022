import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  ToggleSwitchDefinition,
  ToggleSwitchProps,
  ToggleSwitchCSSVars,
  ToggleSwitchAlignment,
} from '../ToggleSwitch.types';

function concatPercent(value: number): string {
  return value !== 0 ? `${value}%` : value.toString();
}

function isOuterKnob(knobSize: number, trackHeight: number): boolean {
  return knobSize > trackHeight;
}

function innerKnobWidth(
  knobSize: number,
  trackHeight: number,
  initialKnobWidth?: number,
): number {
  if (typeof initialKnobWidth !== 'undefined') {
    return 100 - initialKnobWidth - (trackHeight - knobSize) / 4;
  }
  return (trackHeight - knobSize) / 4;
}

function outerKnobWidth(initialKnobWidth: number): number {
  return 100 - initialKnobWidth;
}

function calcUncheckedLeftValue(
  knobSize: number,
  trackHeight: number,
  knobWidth: number,
  alignment: ToggleSwitchAlignment,
): number {
  let result: number;
  if (alignment === 'right') {
    result = isOuterKnob(knobSize, trackHeight)
      ? outerKnobWidth(knobWidth)
      : innerKnobWidth(knobSize, trackHeight, knobWidth);
  } else {
    result = isOuterKnob(knobSize, trackHeight)
      ? 0
      : innerKnobWidth(knobSize, trackHeight);
  }
  return result;
}

function calcCheckedLeftValue(
  knobSize: number,
  trackHeight: number,
  knobWidth: number,
  alignment: ToggleSwitchAlignment,
): number {
  let result: number;
  if (alignment === 'right') {
    result = isOuterKnob(knobSize, trackHeight)
      ? 0
      : innerKnobWidth(knobSize, trackHeight);
  } else {
    result = isOuterKnob(knobSize, trackHeight)
      ? outerKnobWidth(knobWidth)
      : innerKnobWidth(knobSize, trackHeight, knobWidth);
  }
  return result;
}

export const props = withCompInfo<ToggleSwitchProps, ToggleSwitchDefinition>()(
  ['compData', 'compProps'],
  ({ compData, compProps }) => {
    const { checked, value } = compData;
    const { isDisabled } = compProps;
    return {
      isDisabled,
      checked,
      value,
    };
  },
);

export const css = withCompInfo<ToggleSwitchCSSVars, ToggleSwitchDefinition>()(
  ['compProps'],
  ({ compProps }) => {
    const { displayKnobIcons, trackHeight, knobSize, alignment } = compProps;
    const knobWidth = knobSize / 2;
    return {
      '--toggleIconDisplay': displayKnobIcons ? 'block' : 'none',
      '--innerLabelHeight': concatPercent(knobSize),
      '--innerLabelWidth': concatPercent(knobWidth),
      '--outerLabelHeight': concatPercent(trackHeight),
      '--labelMovementRangeChecked': concatPercent(
        calcCheckedLeftValue(knobSize, trackHeight, knobWidth, alignment),
      ),
      '--labelMovementRangeUnchecked': concatPercent(
        calcUncheckedLeftValue(knobSize, trackHeight, knobWidth, alignment),
      ),
    };
  },
);

export default createComponentMapperModel({ css, props });
