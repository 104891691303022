import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  FlickrBadgeWidgetDefinition,
  FlickrBadgeWidgetProps,
} from '../FlickrBadgeWidget.types';

const props = withCompInfo<
  FlickrBadgeWidgetProps,
  FlickrBadgeWidgetDefinition
>()(['compData'], ({ compData }) => {
  return {
    userId: compData.userId,
    tag: compData.tag,
    imageCount: `${compData.imageCount}`,
    whichImages: compData.whichImages,
    imageSize: compData.imageSize,
    layoutOrientation: compData.layoutOrientation,
  };
});

export default createComponentMapperModel({ props });
