import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import { PreviewWrapperProps } from '@wix/editor-elements-types';
import {
  SelectionTagsListDefinition,
  SelectionTagsListProps,
} from '../SelectionTagsList.types';
import { props as viewerPropsMapper } from './SelectionTagsList.mapper';

const props = composeMapper<
  PreviewWrapperProps<SelectionTagsListProps>,
  SelectionTagsListDefinition
>()(
  viewerPropsMapper,
  { add: ['compPreviewState', 'shouldResetComponent'] },
  (resolver, dependencies, carmiData) => {
    const { compPreviewState, shouldResetComponent } = dependencies;
    const viewerProps = resolver(dependencies, carmiData);
    return {
      ...viewerProps,
      previewWrapperProps: {
        compPreviewState,
        shouldResetComponent,
      },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
