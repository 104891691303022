import * as React from 'react';
import { VerticalLineProps } from '../VerticalLine';
import { IVerticalLineProps } from '../../../VerticalLine.types';
import VerticalLine from '../../VerticalLine';
import skinsStyle from './styles/ClassicVerticalDashedLine.scss';
import { BaseVerticalLine } from './BaseVerticalLine';

const VerticalDashedLineSkin: React.FC<VerticalLineProps> = props => (
  <BaseVerticalLine {...props} skinsStyle={skinsStyle} />
);

const VerticalDashedLine: React.FC<Omit<IVerticalLineProps, 'skin'>> =
  props => {
    return <VerticalLine {...props} skin={VerticalDashedLineSkin} />;
  };

export default VerticalDashedLine;
