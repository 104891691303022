import * as React from 'react';
import ResponsiveBox from '../../Container/viewer/skinComps/ResponsiveBox/ResponsiveBox.skin';
import { IMultiStateBoxProps } from '../MultiStateBox.types';
import { getAriaAttributes } from '../../../core/commons/a11y';
import { useDidUpdate } from '../../../providers/useDidUpdate';
import { st, classes } from './style/MultiStateBox.st.css';

export const REPEATER_DELIMITER = '__';

// cut repeater delimiter
const getBaseStateId = (stateId: string | undefined) => {
  const [baseId] = stateId?.split(REPEATER_DELIMITER) ?? [];
  return baseId;
};

const getDefaultContainerProps = (id: string) => ({
  // TODO - wire correctly
  containerLayoutClassName: `container-${id}`,
  // TODO - wire correctly
  overlowWrapperClassName: `overflow-wrapper-${id}`,
  // TODO - wire correctly
  hasOverflow: true,
});

const HiddenState: React.FC = ({ children }) => (
  <span style={{ display: 'none' }}>{children}</span>
);

const MultiStateBox: React.FC<IMultiStateBoxProps> = ({
  id,
  children,
  renderContainer,
  selectedStateId,
  a11y = {},
  ariaAttributes,
  containerProps,
  role,
  containerRootClassName,
  onChange,
  onFocus,
  ...rest
}) => {
  const a11yProps = getAriaAttributes({
    ...a11y,
    ...ariaAttributes,
  });
  /**
   * We keep state memory to preserve DOM state (like inputs)
   */
  const [pastStateIds, setPastStateIds] = React.useState<Array<string>>([]);

  useDidUpdate(() => {
    const stateId = getBaseStateId(selectedStateId);
    if (stateId) {
      onChange?.({ type: 'change' } as React.ChangeEvent);

      if (!pastStateIds.includes(stateId)) {
        setPastStateIds([...pastStateIds, stateId]);
      }
    }
  }, [selectedStateId]);

  /**
   * TB Containers pass children as a function
   */
  const stateElements = React.Children.toArray(
    typeof children === 'function' ? (children as Function)() : children,
  );

  const getStatesToRender = () => {
    const stateId = getBaseStateId(selectedStateId);

    const currentOrPastStates = stateElements.filter(item => {
      if (!React.isValidElement(item)) {
        return false;
      }
      const itemId = getBaseStateId(item.props.id);
      return stateId === itemId || pastStateIds.includes(itemId);
    }) as Array<React.ReactElement>;

    if (currentOrPastStates.length) {
      return currentOrPastStates.map(item =>
        stateId === getBaseStateId(item.props.id) ? (
          item
        ) : (
          // If we rendered this state in the past - render it hidden, to maintain DOM state
          <HiddenState>{item}</HiddenState>
        ),
      );
    }

    return stateElements.slice(0, 1);
  };

  return renderContainer ? (
    renderContainer({ state: getStatesToRender() })
  ) : (
    <ResponsiveBox
      role={role}
      containerRootClassName={st(classes.root, containerRootClassName)}
      hasPlatformClickHandler={false}
      containerProps={containerProps || getDefaultContainerProps(id)}
      id={id}
      onFocus={onFocus}
      a11y={a11yProps}
      {...rest}
    >
      {getStatesToRender}
    </ResponsiveBox>
  );
};

export default MultiStateBox;
