import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import {
  SlideShowGalleryCarmiData,
  SlideShowGalleryDefinition,
  SlideShowGalleryProps,
} from '../SlideShowGallery.types';
import { props as viewerPropsMapper } from './SlideShowGallery.mapper';

/*
 * This is an example for creating a different props mapper for editor using withCompPreviewInfo
 * */

export const props = composeMapper<
  SlideShowGalleryProps,
  SlideShowGalleryDefinition,
  SlideShowGalleryCarmiData
>()(
  viewerPropsMapper,
  {
    add: [
      'isSlideShowGalleryClickAllowed',
      'isPlayingAllowed',
      'shouldResetGalleryToOriginalState',
    ],
  },
  (resolver, deps, carmiData) => {
    const {
      isSlideShowGalleryClickAllowed,
      isPlayingAllowed,
      shouldResetGalleryToOriginalState,
    } = deps;
    const viewerProps = resolver(deps, carmiData);
    return {
      ...viewerProps,
      previewWrapperProps: {
        showSlideShowIcon: !isSlideShowGalleryClickAllowed,
        showButtons: isSlideShowGalleryClickAllowed,
        shouldResetGalleryToOriginalState,
      },
      autoPlay: isPlayingAllowed ? viewerProps.autoPlay : false,
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
