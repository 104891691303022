import * as React from 'react';

import { ISkypeCallButtonProps } from '../SkypeCallButton.types';
import style from './style/SkypeCallButton.scss';

const SkypeCallButton: React.FC<ISkypeCallButtonProps> = props => {
  const { id, href, ariaLabel, onMouseEnter, onMouseLeave } = props;

  const linkProps = href
    ? {
        href,
        'aria-label': ariaLabel,
        title: ariaLabel,
      }
    : {};

  return (
    <div
      id={id}
      className={style.container}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <a className={style.link} {...linkProps}>
        <div className={style.buttonImage} />
      </a>
    </div>
  );
};

export default SkypeCallButton;
