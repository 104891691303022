import * as React from 'react';
import { IDisqusCommentsProps } from '../DisqusComments.types';
import { useIFrame } from '../../../providers/useIFrame/useIFrame';
import style from './style/DisqusComments.scss';
import baseIframeUrl from '!!url-loader?limit=1!./assets/DisqusComments.html';

const DisqusComments: React.FC<IDisqusCommentsProps> = props => {
  const {
    id: compId,
    disqusId,
    currentUrl: { href: pageUrl },
    onMouseEnter,
    onMouseLeave,
  } = props;
  const [height, setHeight] = React.useState('200px');
  const [render, setRender] = React.useState(false);

  React.useEffect(() => setRender(true), []);

  const iframeSrc = React.useMemo(
    () =>
      `${baseIframeUrl}?${new URLSearchParams({
        disqusId,
        pageUrl,
        compId,
        /** TL;DR;
         * 'pageTitle' & 'disqusInstanceId' are set as "undefined" here in order
         * to preserve the current "broken" behaviour we got for sites that rendered
         * this component with bolt.
         * Longer version:
         * Santa api's that were in charge of populating these variables were not
         * implemented in bolt, this led to passing these variables as `undefined`
         * when the site was rendered with bolt.
         * These `undefined` values transformed to the string value of "undefined"
         * when passed to the iframe and was set as the `identifier` of the conversation.
         * This let to having all the instances of this component in a certain site
         * sharing the same comments.
         * While this is clearly a broken behaviour, it was decided to preserve it
         * for now to since this component is no longer offered to new customers.
         */
        pageTitle: 'undefined',
        disqusInstanceId: 'undefined',
      })}`,
    [compId, disqusId, pageUrl],
  );

  const handleMessage = React.useCallback(
    ({ type, payload }) => {
      if (type === 'message') {
        try {
          // error handling for valid JSON
          const json = JSON.parse(payload);
          if (json.id === compId) {
            setHeight(json.height);
          }
        } catch (ee) {
          return;
        }
      }
    },
    [compId],
  );

  const [ref] = useIFrame({ reducer: handleMessage });
  const key = `DisqusComments-${pageUrl}`;

  return (
    <div
      className={style.root}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{ height }}
      id={compId}
    >
      {render && (
        <wix-iframe data-src={iframeSrc}>
          {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
          <iframe
            style={{ height }}
            data-src={iframeSrc}
            className={style.disqusCommentsHolder}
            key={key}
            ref={ref}
          />
          {!disqusId && (
            <div
              className={style.noDisqusIdMessage}
            >{`There was an error loading Disqus comments`}</div>
          )}
        </wix-iframe>
      )}
    </div>
  );
};

export default DisqusComments;
