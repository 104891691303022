import React from 'react';

type states = {
  hovered: boolean;
  focused: boolean;
  error: boolean;
  disabled: boolean;
};
const StylesContext = React.createContext<states>({
  hovered: false,
  focused: false,
  error: false,
  disabled: false,
});

export default StylesContext;
