import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  StateBoxDefinition,
  StateBoxProps,
  StateBoxCSSVars,
} from '../StateBox.types';

export const props = withCompInfo<StateBoxProps, StateBoxDefinition>()(
  ['compProps', 'skin'],
  ({ compProps, skin }) => {
    return {
      skin,
      hasShadowLayer: true,
      currentSlideIndex: 0,
      transitionDuration: compProps.transDuration * 1000,
      transition: compProps.transition,
    };
  },
);

export const css = withCompInfo<StateBoxCSSVars, StateBoxDefinition>()(
  ['compProps'],
  ({ compProps }) => {
    const { transDuration } = compProps;
    return {
      height: 'auto',
      '--transition-duration': `${transDuration * 1000}ms`,
      '--transition-overflow': 'hidden',
      '--slides-overflow': 'visible',
      '--nav-dot-section-display': 'none',
      '--nav-button-display': 'none',
    };
  },
);

export default createComponentMapperModel({ props, css });
