import React from 'react';
import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import { PreviewWrapperProps } from '@wix/editor-elements-types';
import { usePreviewState } from '@wix/editor-elements-preview-utils';
import {
  IFooterContainerProps,
  FooterContainerPreviewWrapperProps,
} from '../FooterContainer.types';
import { isBrowser } from '../../../core/commons/utils';

export default (ViewerComponent: React.ComponentType<IFooterContainerProps>) =>
  createComponentPreviewEntry(function ({
    previewWrapperProps = {},
    ...viewerProps
  }: PreviewWrapperProps<
    IFooterContainerProps,
    FooterContainerPreviewWrapperProps
  >) {
    const { componentViewMode, compPreviewState, fixedPosition } =
      previewWrapperProps;
    React.useLayoutEffect(() => {
      if (!isBrowser()) {
        return;
      }
      // When footer becomes fixed in order for it to not overflow other components, footer placeholder need to be displayed
      const siteFooterPlaceholder = document.getElementById(
        'SITE_FOOTER-placeholder',
      );
      if (!siteFooterPlaceholder) {
        return;
      }
      if (componentViewMode === 'editor') {
        siteFooterPlaceholder.style.display = 'none';
      } else if (fixedPosition) {
        siteFooterPlaceholder.style.display = 'block';
      }
    }, [componentViewMode, fixedPosition]);

    usePreviewState(viewerProps.id, compPreviewState);
    return <ViewerComponent {...viewerProps} />;
  });
