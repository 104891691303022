
  var rt = require("@stylable/runtime/cjs/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "StylableHorizontalMenu3372578893",
      {"classes":{"root":"StylableHorizontalMenu3372578893__root","scrollButton":"StylableHorizontalMenu3372578893__scrollButton","menu":"StylableHorizontalMenu3372578893__menu","menuItem":"StylableHorizontalMenu3372578893__menuItem","columnsLayout":"StylableHorizontalMenu3372578893__columnsLayout","megaMenuContainer":"StylableHorizontalMenu3372578893__megaMenuContainer","positionBox":"StylableHorizontalMenu3372578893__positionBox","containerPositionBox":"StylableHorizontalMenu3372578893__containerPositionBox"},"vars":{"container-margin-top":"--StylableHorizontalMenu3372578893-container-margin-top"},"stVars":{"wrap":"var(--menu-flex-wrap, wrap)"},"keyframes":{}},
      "",
      -1,
      module.id
  );
  