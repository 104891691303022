import * as React from 'react';
import { MenuButtonProps } from '../../../MenuButton.types';
import OverlineMenuButton from './OverlineMenuButton';
import skinsStyle from './styles/OverlineMenuButtonHorizontalMenuAdaptationSkin.scss';

const OverlineMenuButtonHorizontalMenuAdaptationSkin: React.FC<MenuButtonProps> =
  props => {
    return (
      <OverlineMenuButton
        {...props}
        skinsStyle={skinsStyle}
        skin={'OverlineMenuButtonHorizontalMenuAdaptationSkin'}
      />
    );
  };

export default OverlineMenuButtonHorizontalMenuAdaptationSkin;
