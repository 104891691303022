import { IComponentController } from '@wix/editor-elements-types';
import {
  IAddressInputControllerActions,
  InternalAddress,
  Suggestion,
} from '../AddressInput.types';

const mapActionsToProps: IComponentController = ({
  updateProps,
  reportBi,
}): IAddressInputControllerActions => ({
  onValueChange: (value: InternalAddress) => updateProps({ value }),
  onBI: (event, extra = {}) => reportBi(event, extra),
  setValidityIndication: (shouldShowValidityIndication: boolean) => {
    updateProps({
      shouldShowValidityIndication,
    });
  },
  onSuggestionsUpdate: (updatedSuggestions: Array<Suggestion>) =>
    updateProps({ suggestions: updatedSuggestions }),
  onSuggestionsVisibilityUpdate: (updatedSuggestionsVisibility: boolean) =>
    updateProps({ suggestionsVisibility: updatedSuggestionsVisibility }),
});

export default mapActionsToProps;
