
  var rt = require("@stylable/runtime/cjs/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "SelectionTag1023856791",
      {"classes":{"root":"SelectionTag1023856791__root","tag":"SelectionTag1023856791__tag","tagInput":"SelectionTag1023856791__tagInput"},"vars":{},"stVars":{},"keyframes":{}},
      "",
      -1,
      module.id
  );
  