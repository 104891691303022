import * as React from 'react';
import classNames from 'classnames';
import { IResponsivePopupContainerProps } from '../ResponsivePopupContainer.types';
import style from '../../ResponsivePopupPage/viewer/style/ResponsivePopupPage.scss';
import ResponsiveContainer from '../../../thunderbolt-core-components/ResponsiveContainer/viewer/ResponsiveContainer';
import { TestIds } from '../constants';

const ResponsivePopupContainer: React.FC<IResponsivePopupContainerProps> = ({
  id,
  containerProps,
  containerRootClassName,
  children,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => (
  <div
    id={id}
    className={containerRootClassName}
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    <div
      className={classNames(style.bg)}
      data-testid={TestIds.ResponsivePopupContainerBg}
    ></div>
    <ResponsiveContainer {...containerProps}>{children}</ResponsiveContainer>
  </div>
);

export default ResponsivePopupContainer;
