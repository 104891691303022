import * as React from 'react';
import { IComponentController } from '@wix/editor-elements-types';

const mapActionsToProps: IComponentController = ({ updateProps }) => ({
  onRatingChange: (event: React.ChangeEvent<HTMLInputElement>) => {
    updateProps({
      value: parseInt(event.target.value, 10) || null,
    });
  },
  setValidityIndication: (shouldShowValidityIndication: boolean) => {
    updateProps({
      shouldShowValidityIndication,
    });
  },
});

export default mapActionsToProps;
