import {
  IClickPropsSDKActions,
  IElementPropsSDKActions,
} from '@wix/editor-elements-corvid-utils';
import {
  LinkProps,
  CorvidTypes,
  BaseDataItem,
  DefaultCompPlatformProps,
  IComponentPanelWithStylable,
} from '@wix/editor-elements-types';

export type { CollapsibleTextDefinition } from './documentManagement/CollapsibleText.definition';
export type CollapsibleTextControllerActions = {
  setIsExpanded: (isExpanded: boolean) => void;
};

export enum TagValue {
  P = 'p',
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  BLOCKQUOTE = 'blockquote',
}

export interface ExpandControlData {
  readMoreText: string;
  readLessText: string;
  link?: LinkProps;
  linkText: string;
  tag: keyof JSX.IntrinsicElements;
}

export type ButtonAlignment = 'left' | 'center' | 'right';

export type ExpandMode = 'button' | 'link';
export interface ExpandControlProps {
  showExpandControl: boolean;
  expandControlSpacing?: number;
  expandMode: ExpandMode;
  isExpanded: boolean;
  align: 'left' | 'center' | 'right';
  alignButton: ButtonAlignment;
}
export interface ICollapsibleTextData extends BaseDataItem, ExpandControlData {
  text: string;
}

export interface CollapsibleTextDSProps
  extends DefaultCompPlatformProps,
    ExpandControlProps {
  ellipsis: boolean;
  direction: 'ltr' | 'rtl';
  wrappingStyle: 'single-line' | 'multi-line';
  maxLines: number;
  lastUserDefinedWidth: number;
}

export interface ICollapsibleTextMapperProps extends CollapsibleTextDSProps {
  className?: string;
}

export type ICollapsibleTextCompProps = ICollapsibleTextMapperProps &
  ICollapsibleTextData &
  CollapsibleTextControllerActions &
  IClickPropsSDKActions &
  IElementPropsSDKActions;

export type CollapsibleTextPanelProps = IComponentPanelWithStylable<
  ICollapsibleTextCompProps,
  ICollapsibleTextData
>;

export type ICollapsibleTextSDK = {
  text?: string;
  ellipsisEnabled: boolean;
  maxLines: number;
  readMoreActionType: ReadMoreActionType;
  readMoreActionData: LinkToContent | ExpandOnCurrentPage;
  expandText: () => void;
  collapseText: () => void;
  removeReadMoreButton: () => void;
  addReadMoreButton: (buttonText?: string) => void;
  disableEllipsis: () => void;
  enableEllipsis: () => void;
};

export type ICollapsibleTextSDKFactory = CorvidTypes.CorvidSDKFactory<
  ICollapsibleTextCompProps,
  ICollapsibleTextSDK
>;

export type LinkToContent = {
  link: string;
  target?: '_blank' | '_self';
  readMoreButtonVisible?: boolean;
  readMoreButtonText?: string;
};

export type ExpandOnCurrentPage = {
  readMoreButtonText?: string;
  readLessButtonText?: string;
  collapsed?: boolean;
};

export enum ReadMoreActionType {
  LinkToContent = 'LinkToContent',
  ExpandOnCurrentPage = 'ExpandOnCurrentPage',
}
