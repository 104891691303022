import React from 'react';
import Page from '../../Page';
import { SkinPageProps } from '../SkinPage';
import skinsStyles from './styles/BorderPageSkin.scss';
import BasePageSkin, { BasePageSkinProps } from './BasePageSkin';

const BorderPageSkin: React.FC<Omit<BasePageSkinProps, 'skinsStyle'>> =
  props => <BasePageSkin {...props} skinsStyle={skinsStyles} />;

const BorderPage: React.FC<SkinPageProps> = props => (
  <Page {...props} skin={BorderPageSkin} />
);

export default BorderPage;
