import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  FacebookShareDefinition,
  FacebookSharePlatformProps,
} from '../FacebookShare.types';

export const props = withCompInfo<
  FacebookSharePlatformProps,
  FacebookShareDefinition
>()(['compData'], ({ compData }) => {
  return { label: compData.label, socialUrl: '' };
});

export default createComponentMapperModel({ props });
