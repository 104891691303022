import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import {
  IComponentPreviewWrapper,
  PreviewWrapperProps,
} from '@wix/editor-elements-types';
import React, { useEffect, useState } from 'react';
import { PaginationType } from '../constants';
import {
  IGridProps,
  IGridImperativeActions,
  GridPreviewWrapperProps,
} from '../Grid.types';

export function withComponentPreview(
  WrappedComponent: React.ComponentType<IGridProps>,
): IComponentPreviewWrapper<IGridProps, GridPreviewWrapperProps> {
  return React.forwardRef<IGridImperativeActions, IGridProps>(
    (
      {
        previewWrapperProps = {},
        ...viewerProps
      }: PreviewWrapperProps<IGridProps, GridPreviewWrapperProps>,
      ref,
    ) => {
      const { componentViewMode, initialRows = [] } = previewWrapperProps;
      const {
        userSelectionType,
        onChangeSelectedRow,
        onChangeSelectedCell,
        pagination,
        onChangePage,
        rows,
      } = viewerProps;
      const [previousViewMode, setPreviousViewMode] = useState<
        string | undefined
      >(componentViewMode);

      useEffect(() => {
        if (previousViewMode !== 'editor' && componentViewMode === 'editor') {
          // Reset selection
          if (userSelectionType === 'row') {
            onChangeSelectedRow(undefined);
          } else if (userSelectionType === 'cell') {
            onChangeSelectedCell(undefined);
          }

          // Reset pagination
          const hasPagination = pagination.type === PaginationType.Pages;
          if (hasPagination) {
            onChangePage(1);
          }
        }
        setPreviousViewMode(componentViewMode);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [componentViewMode]);

      return (
        <WrappedComponent
          {...viewerProps}
          ref={ref}
          rows={rows || initialRows}
        />
      );
    },
  );
}

export default (ViewerComponent: React.ComponentType<IGridProps>) =>
  createComponentPreviewEntry(withComponentPreview(ViewerComponent));
