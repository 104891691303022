import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefs,
} from '@wix/editor-elements-integrations';
import { getCompStyles } from '../../../tpaGallery';
import { TPAGallerySdkData } from '../../../tpaGallery/types';
import {
  StripSlideshowDefinition,
  StripSlideshowPlatformProps,
  StripSlideshowCarmiData,
  StripSlideshowCSSVars,
} from '../StripSlideshow.types';
import {
  propsDependencies,
  propsResolver,
} from '../../TPAGallery/viewer/TPAGalleryMapperUtil';

const compStyleKeys = {
  titleColor: 'color1',
  descriptionColor: 'color2',
  backgroundColor: 'color3',
};

export const props = withCompInfo<
  StripSlideshowPlatformProps,
  StripSlideshowDefinition,
  StripSlideshowCarmiData
>()(
  [...propsDependencies, 'styleProperties', 'siteColors'],
  (data, carmiProps) => ({
    ...propsResolver<StripSlideshowDefinition>(
      data,
      carmiProps,
      'StripSlideshow/StripSlideshow.html',
    ),
    compStyles: getCompStyles(
      compStyleKeys,
      data.styleProperties,
      data.siteColors,
    ),
    currentIndex: 0,
    isScreenWidth: true,
  }),
);

const sdkData = withCompInfo<TPAGallerySdkData, StripSlideshowDefinition>()(
  ['compData'],
  ({ compData }) => {
    return {
      dataId: compData.id,
    };
  },
);

const css = withCompInfo<StripSlideshowCSSVars, StripSlideshowDefinition>()(
  ['compLayout'],
  ({ compLayout }) => ({
    '--height': `${compLayout.height}px`,
  }),
);

export const stateRefs = withStateRefs<{ reducedMotion: boolean }>()(
  [],
  ({ refApi }) => ({
    reducedMotion: refApi.env.reducedMotion(),
  }),
);

export default createComponentMapperModel({ css, props, sdkData, stateRefs });
