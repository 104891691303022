import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import { PreviewWrapperProps } from '@wix/editor-elements-types';
import { DataSource } from '../constants';
import {
  setRowValue,
  getRowValue,
  getRichTextHtmlPath,
  removeProtocol,
} from '../utils';
import {
  GridColumn,
  GridData,
  GridDefinition,
  GridProperties,
  GridPlatformProps,
} from '../Grid.types';
import defaultImage from '../../../legacy-assets/Grid/image-placeholder.png';
import { props as viewerPropsMapper } from './Grid.mapper';

const generateDummyData = (column: GridColumn) => {
  let dummyData;
  switch (column.type) {
    case 'string':
      dummyData = `[${column.label} Data]`;
      break;
    case 'number':
      dummyData = 123456;
      break;
    case 'date':
      dummyData = new Date();
      break;
    case 'image':
      dummyData = defaultImage;
      break;
    case 'bool':
      dummyData = Math.random() > 0.5;
      break;
    case 'richText':
      dummyData = `<div>[${column.label} Data]</div>`;
      break;
    default:
      return;
  }
  return dummyData;
};

// Fills in missing cells with mock data
const fillInMockData = (
  compData: GridData,
  { columns }: GridProperties,
): GridData => {
  const rows = compData.rows?.map(partialRow => {
    const row = { ...partialRow };
    columns?.forEach(column => {
      const { dataPath, type } = column;
      if (dataPath && !getRowValue(row, column.dataPath)) {
        const valueDataPath =
          type === 'richText' ? getRichTextHtmlPath(dataPath) : dataPath;
        setRowValue(row, valueDataPath, generateDummyData(column));
      }
    });
    return row;
  });

  return {
    ...compData,
    rows,
    dataSource: {
      ...compData.dataSource,
      type: DataSource.Static,
    },
  };
};

const props = composeMapper<
  PreviewWrapperProps<Omit<GridPlatformProps, 'rows'>>,
  GridDefinition
>()(
  viewerPropsMapper,
  { add: ['componentViewMode'] },
  (resolver, dependencies, carmiData) => {
    const { componentViewMode, compData, compProps, topology } = dependencies;
    const { rows: initialRows, ...viewerProps } = resolver(
      {
        ...dependencies,
        topology: {
          ...topology,
          staticMediaUrl: removeProtocol(topology.staticMediaUrl),
        },
        compData:
          componentViewMode === 'editor'
            ? fillInMockData(compData, compProps)
            : compData,
      },
      carmiData,
    );

    return {
      ...viewerProps,
      previewWrapperProps: {
        componentViewMode,
        initialRows,
      },
    };
  },
);

export { defaultImage };

export default createComponentPreviewMapperModel({
  props,
});
