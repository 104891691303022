import * as React from 'react';
import {
  IRatingsInputProps,
  IRatingsInputImperativeActions,
} from '../RatingsInput.types';
import RatingsInputItem from './RatingsInputItem/RatingsInputItem';
import { classes, st } from './RatingsInput.component.st.css';

const fiveItems = [1, 2, 3, 4, 5];

const getStyle = (elem: HTMLElement | null) =>
  elem ? window.getComputedStyle(elem) : null;

const getLabelsWidthWithMargins = (labelsRefs: Array<HTMLElement> | null) => {
  if (!labelsRefs) {
    return 0;
  }

  return Math.max(
    ...labelsRefs.map(el => {
      const elemStyle = getStyle(el);

      // set element width to auto in order to take only text width, measure width, and reset width to original value
      const widthStyle = el!.style.width;
      el!.style.width = 'auto';
      const width = elemStyle
        ? parseInt(elemStyle.marginLeft, 10) +
          el!.offsetWidth +
          parseInt(elemStyle.marginRight, 10)
        : 0;
      el!.style.width = widthStyle;

      return width;
    }),
  );
};

const Title = React.memo<{ title: string }>(({ title }) => (
  <span className={classes.title} data-hook="rating-input-title">
    {title}
  </span>
));

const HiddenLabels = React.memo<{
  labels: { [key: number]: string };
  refs: React.MutableRefObject<Array<HTMLElement>>;
}>(({ labels, refs }) => (
  <>
    {fiveItems.map((value, index) => {
      return (
        <span
          key={value}
          className={classes.label}
          ref={(el: HTMLSpanElement) => (refs.current[index] = el)}
        >
          {labels[value]}
        </span>
      );
    })}
  </>
));

const HiddenTitle = React.memo<{ title: string }>(({ title }) => (
  <span className={classes.label}>{title}</span>
));

const RatingsInput: React.ForwardRefRenderFunction<
  IRatingsInputImperativeActions,
  IRatingsInputProps
> = (props, ref) => {
  const {
    id,
    onChange = () => {},
    onRatingChange = () => {},
    onClick = () => {},
    onDblClick = () => {},
    onMouseEnter = () => {},
    onMouseLeave = () => {},
    iconMouseIn = () => {},
    onFocus = () => {},
    onBlur = () => {},
    labels,
    showLabels,
    showTitle,
    title = '',
    svgString,
    labelPosition,
    required,
    value,
    isDisabled,
    shouldShowValidityIndication,
    isValid,
    reCalculateIconsSizeDeps = [],
    overrideIconsStyle,
    validateValueAndShowIndication = () => {},
  } = props;
  const [hoveredItemIndex, setHoveredItemIndex] = React.useState<
    number | undefined
  >(undefined);
  const [style, setStyle] = React.useState<{
    minWidth?: number;
    marginLeft?: string;
    marginRight?: string;
  }>({});

  React.useLayoutEffect(
    () => {
      let iconsStyle = {};

      if (labelPosition === 'side') {
        const labelWidth = getLabelsWidthWithMargins(labelsRefs.current);
        iconsStyle = overrideIconsStyle?.(labelWidth) || {};
      }

      setStyle(iconsStyle);
    },
    /*
      Every time icons attribured are modified in editor (layout panel),
      we need to recalculate the icons styles based on that values.
      We don't want that to happen every render in live sites, so we used reCalculateIconsSizeDeps
    */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    reCalculateIconsSizeDeps,
  );
  const labelsRefs = React.useRef<Array<HTMLElement>>([]);

  const itemRefs = React.useRef<Array<IRatingsInputImperativeActions | null>>(
    [],
  );

  React.useImperativeHandle(ref, () => {
    const index = (value || 1) - 1;
    return {
      focus() {
        itemRefs.current[index]?.focus();
      },
      blur() {
        itemRefs.current[index]?.blur();
      },
      setCustomValidity: message => {
        itemRefs.current[index]?.setCustomValidity(message);
      },
    };
  });

  const onIconBlur = () => {
    if (isDisabled) {
      return;
    }
    setHoveredItemIndex(undefined);
  };

  const onIconHover = (
    event: React.MouseEvent,
    icon: { value: number; label: string },
  ) => {
    if (isDisabled) {
      return;
    }

    setHoveredItemIndex(icon.value);
    iconMouseIn({ ...icon, type: 'iconMouseIn' });
  };

  const getCurrentText = React.useCallback(() => {
    if (hoveredItemIndex && showLabels) {
      return labels[hoveredItemIndex];
    } else if (value && showLabels) {
      return labels[value];
    }

    return (showTitle && title) || '';
  }, [hoveredItemIndex, labels, value, showLabels, showTitle, title]);

  const shouldDisplayError =
    shouldShowValidityIndication && !hoveredItemIndex && !isValid;

  const styleStates = {
    labelPosition,
    disabled: isDisabled,
    error: shouldDisplayError,
  };
  const _onChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    const intValue = parseInt(event.target.value, 10);
    const isValueUnchanged = value === intValue;
    if (isDisabled || isValueUnchanged) {
      return;
    }
    onRatingChange(event);
    validateValueAndShowIndication();
    onChange?.(event);
  };

  return (
    <div
      className={st(classes.root, styleStates)}
      role="radiogroup"
      id={id}
      onClick={onClick}
      onDoubleClick={onDblClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onFocus={onFocus}
      onBlur={onBlur}
      style={style}
    >
      <span className={classes.labelsContainer}>
        {showTitle || showLabels ? <Title title={getCurrentText()} /> : null}
        {showLabels ? <HiddenLabels refs={labelsRefs} labels={labels} /> : null}
        {showTitle ? <HiddenTitle title={title} /> : null}
      </span>
      <span
        className={classes.icons}
        aria-label={value ? `${value} out of 5` : undefined}
      >
        {fiveItems.map(itemValue => (
          <RatingsInputItem
            key={itemValue}
            className={classes.item}
            name={id}
            value={itemValue}
            ratingInputValue={value}
            hovered={hoveredItemIndex}
            onChange={_onChange}
            required={required}
            ariaLabel={!value ? labels[itemValue] : undefined}
            onMouseEnter={event =>
              onIconHover(event, { value: itemValue, label: labels[itemValue] })
            }
            onMouseLeave={onIconBlur}
            svgString={svgString}
            ref={el => {
              if (itemRefs.current) {
                itemRefs.current[itemValue - 1] = el;
              }
            }}
            error={shouldDisplayError}
          />
        ))}
      </span>
    </div>
  );
};

export default React.forwardRef(RatingsInput);
