import * as React from 'react';
import { MenuButtonProps } from '../../../MenuButton.types';
import BaseButton from './BaseButton';
import skinsStyle from './styles/SloppyBorderMenuButtonNSkin.scss';

const SloppyBorderMenuButtonNSkin: React.FC<MenuButtonProps> = props => {
  return (
    <BaseButton
      {...props}
      skinsStyle={skinsStyle}
      skin={'SloppyBorderMenuButtonNSkin'}
    />
  );
};

export default SloppyBorderMenuButtonNSkin;
