import * as React from 'react';
import { IBoltComponentProps } from '../BoltComponent.types';

import { st, classes } from './style/BoltComponent.st.css';

const BoltComponent: React.FC<IBoltComponentProps> = props => {
  const { id, myProp, className } = props;

  return (
    // the viewer platform passes the id to the component
    // and uses it to inject css variables scoped for the component
    <div id={id} className={st(classes.root, className)}>
      <div>
        <h1>Hello!</h1>
        <h2>
          I'm "Foo" Component and I have a prop named "myProp" with value:{' '}
          <u>{myProp}</u>
        </h2>
      </div>
    </div>
  );
};

export default BoltComponent;
