import {
  createComponentPreviewMapperModel,
  withCompPreviewInfo,
} from '@wix/editor-elements-integrations';
import {
  CompPreviewInfoDependency,
  MediaCSSVars,
} from '@wix/editor-elements-types';

import {
  css as mediaContainerCssMapper,
  props as mediaContainerPropsMapper,
} from '../../MediaContainers/MediaContainer/viewer/MediaContainer.previewMapper';
import { PageBackgroundDefinition } from '../PageBackground.types';

const toCssVar = (varName: string) => `var(--${varName})`;

const mediaContainerCssDeps = Object.keys(
  mediaContainerCssMapper.deps,
) as Array<CompPreviewInfoDependency>;

const css = withCompPreviewInfo<
  MediaCSSVars,
  PageBackgroundDefinition,
  MediaCSSVars
>()(['isMobileView', ...mediaContainerCssDeps], (deps, carmiData) => {
  const mediaContainerCss = mediaContainerCssMapper.resolver(deps, carmiData);
  const dimensions: { width?: string } = {};
  if (deps.isMobileView) {
    dimensions.width = toCssVar('site-width');
  }

  return { ...carmiData, ...mediaContainerCss, ...dimensions };
});

export default createComponentPreviewMapperModel({
  props: mediaContainerPropsMapper,
  css,
});
