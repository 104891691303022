import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';

// TODO add types
const props = withCompInfo<any, any>()(
  ['compData', 'compProps', 'compLayout', 'compStylableClass'],
  ({ compData, compProps, compLayout, compStylableClass }) => {
    return {
      ...compData,
      ...compProps,
      className: compStylableClass,
      width: compLayout.width,
    };
  },
);

export default createComponentMapperModel({
  props,
});
