import * as React from 'react';
import { ISiteButtonImperativeActions } from '../../../SiteButton.types';
import SiteButtonContent from '../../SiteButtonContent';
import { SkinButtonProps } from '../SkinButton.types';

type ButtonForkedSkinProps = SkinButtonProps & {
  skinsStyle: Record<string, string>;
};

const ButtonForkedSkin: React.ForwardRefRenderFunction<
  ISiteButtonImperativeActions,
  ButtonForkedSkinProps
> = (
  {
    wrapperProps,
    linkProps,
    a11yProps,
    elementType,
    skinsStyle,
    label,
    onFocus,
    onBlur,
  },
  ref,
) => {
  return (
    <div {...wrapperProps} className={skinsStyle.root}>
      <div className={skinsStyle.wrapper}>
        <div className={`${skinsStyle.container} ${skinsStyle.top}`}>
          <div className={skinsStyle.ribbon} />
        </div>
        <div className={`${skinsStyle.container} ${skinsStyle.bottom}`}>
          <div className={skinsStyle.ribbon} />
        </div>
      </div>
      <SiteButtonContent
        linkProps={linkProps}
        a11yProps={a11yProps}
        elementType={elementType}
        className={skinsStyle.link}
        onFocus={onFocus}
        onBlur={onBlur}
        ref={ref}
      >
        <span className={skinsStyle.label}>{label}</span>
      </SiteButtonContent>
    </div>
  );
};

export default React.forwardRef(ButtonForkedSkin);
