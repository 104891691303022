export const TestIds = {
  noReviewsPlaceholder: 'no-reviews-placeholder-test-id',
  ratingValue: 'rating-value-test-id',
  ratingIcons: 'rating-icons-test-id',
  splitIcon: 'split-icon-test-id',
  reviewsCounter: 'reviews-counter-test-id',
} as const;

export const NoReviewsMode = {
  NOTHING: 'nothing',
  EMPTY_ICONS: 'emptyIcons',
  PLACEHOLDER_TEXT: 'placeholderText',
};

export const DEFAULT_SHAPE_SIZE = 25;
export const DEFAULT_SHAPE_SPACING = 8;
